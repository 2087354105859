import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  selectOffer as selectOfferFn,
  setOfferDuration as setOfferDurationFn,
} from '@app/store/reducers/saleProcess';
import { Typography } from '@stadline/react-ui-components';
import { OfferCard } from '@app/components';
import { getOfferMonthlyDuration } from '@app/store/selectors/saleProcess';
import { makeGetOffer, makeGetProduct } from '@app/store/selectors/entities';
import { monetize } from '../../helpers/i18n';
import { getOfferInfos, getFirst, getDuration } from '../../helpers';
import withSaleProcess from '../../providers/withSaleProcess';

const objectToString = duration => {
  const result = [];

  if (duration.years) result.push(`${duration.year} ans`);
  if (duration.months) result.push(`${duration.months} mois`);
  // if (duration.days) result.push(`${duration.days} jours`);

  return result.join(', ');
};

export const PromoText = ({ hasPromo, promoPrice, promoDuration, price, variant }) =>
  hasPromo ? (
    <React.Fragment>
      <Typography variant={variant}>
        {monetize(promoPrice)} pendant {objectToString(promoDuration)}{' '}
      </Typography>
      <Typography variant={variant}>{monetize(price)} les mois suivants </Typography>
    </React.Fragment>
  ) : (
    <Typography variant={variant}>{monetize(price)} par mois</Typography>
  );

PromoText.propTypes = {
  hasPromo: PropTypes.bool.isRequired,
  promoPrice: PropTypes.number.isRequired,
  promoDuration: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  variant: PropTypes.string,
};

PromoText.defaultProps = {
  variant: 'body2',
};

const Offer = ({
  selectOffer,
  offer,
  offers,
  offerMonthlyDuration,
  setOfferDuration,
  product,
  selected,
}) => {
  const handleOfferSelect = offerId => event => {
    event.preventDefault();
    selectOffer(offerId);
  };

  const handleSwitchOfferDuration = duration => event => {
    event.stopPropagation();
    event.preventDefault();
    setOfferDuration(duration);
  };

  const offerInfos = getOfferInfos(offer);

  return offer ? (
    <OfferCard
      key={offer['@id']}
      selected={selected}
      onOfferSelect={handleOfferSelect}
      onToggleClick={handleSwitchOfferDuration}
      offers={offers}
      offer={offer}
      product={product}
      monthly={PromoText}
      offerMonthlyDuration={offerMonthlyDuration}
      offerInfos={offerInfos}
    />
  ) : null;
};

Offer.propTypes = {
  selectOffer: PropTypes.func.isRequired,
  offers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  offer: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  offerMonthlyDuration: PropTypes.number,
  setOfferDuration: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

Offer.defaultProps = {
  offerMonthlyDuration: null,
};

const mapStateToProps = (state, { catalogOffer, saleProcess }) => {
  const isGrouped = Array.isArray(catalogOffer.ids);
  const offers = isGrouped
    ? catalogOffer.ids.map(id => makeGetOffer(id)(state)).filter(o => o)
    : makeGetOffer(catalogOffer.id)(state);

  const offerMonthlyDuration = getOfferMonthlyDuration(state);
  const offer = isGrouped
    ? offers.find(o => getDuration(o) === offerMonthlyDuration) || getFirst(offers)
    : offers;

  const product = makeGetProduct(offer.product)(state);
  const selected = saleProcess.selectedOffer === offer['@id'];

  return {
    offerMonthlyDuration,
    offers,
    offer,
    product,
    selected,
  };
};

const mapActionCreators = {
  selectOffer: selectOfferFn,
  setOfferDuration: setOfferDurationFn,
};

export default withSaleProcess(
  connect(
    mapStateToProps,
    mapActionCreators
  )(Offer)
);
