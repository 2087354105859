import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import IBAN from 'iban';
import BIC from 'bic';
import {
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Typography,
} from '@stadline/react-ui-components';
import { connect } from 'react-redux';
import { getSaleUserInfos, getMandateInfos, getContactId } from '@app/store/selectors/saleProcess';
import { CustomCheckbox } from '@app/components';
import {
  setMandateInfos as setMandateInfosFn,
  setMandateFormValid as setMandateFormValidFn,
  saveMandate as saveMandateFn,
} from '@app/store/reducers/saleProcessUser';

import { withFormik } from 'formik';
import { withState, withHandlers } from 'recompose'; // eslint-disable-line import/no-extraneous-dependencies
import UserFormPart from '../../components/UserForm/formsParts/UserFormPart';
import { GRID_SPACING } from '../../consts';
import { compose } from '../../helpers';
import SaleLayout from '../../components/SaleLayout';
import FormErrorsModal from '../../components/FormErrorsModal';
import { withErrors } from '../../components/UserForm/helpers';

class BankDetails extends React.Component {
  state = {
    showErrors: false,
    isErrorModalOpen: false,
  };

  tryToSubmitForm = async () => {
    const { validateForm, values, saveMandate, setMandateInfos, setMandateFormValid } = this.props;
    const validate = await validateForm();

    if (Object.keys(validate).length > 0) {
      setMandateFormValid(false);
      this.setState({
        showErrors: true,
        isErrorModalOpen: true,
      });
      return Promise.reject();
    }

    setMandateFormValid(true);
    setMandateInfos(values);
    return saveMandate(values);
  };

  saveMandateInfos = async () => {
    const { setMandateInfos, values } = this.props;
    setMandateInfos(values);
  };

  render() {
    const {
      isHolder,
      onCheckChange,
      values,
      handleBlur,
      handleChange,
      errors,
      touched,
    } = this.props;
    const { showErrors, isErrorModalOpen } = this.state;

    return (
      <SaleLayout
        withScroll
        withShoppingBag
        title="Tes informations personnelles"
        onNext={this.tryToSubmitForm}
        onPrev={this.saveMandateInfos}
      >
        <div
          style={{ display: 'inline-block', paddingLeft: 'calc(100vw/12)', whiteSpace: 'nowrap' }}
        >
          <UserFormPart>
            <Grid container spacing={GRID_SPACING}>
              <Grid item xs={12}>
                <FormGroup style={{ marginBottom: '8px', paddingLeft: '12px' }}>
                  <FormControlLabel
                    control={<CustomCheckbox />}
                    checked={isHolder}
                    onChange={onCheckChange}
                    label={
                      <Typography variant="h4" style={{ marginTop: '14px' }}>
                        Le titulaire du compte bancaire est le titulaire de l&apos;abonnement.
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Nom du titulaire du compte"
                  value={values.holder}
                  disabled={isHolder}
                  onBlur={handleBlur('holder')}
                  onChange={handleChange('holder')}
                  {...withErrors(touched, errors, 'holder', showErrors)}
                />
              </Grid>
            </Grid>
          </UserFormPart>
          <UserFormPart>
            <Grid container spacing={GRID_SPACING}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="IBAN"
                  value={values.iban}
                  onBlur={handleBlur('iban')}
                  onChange={handleChange('iban')}
                  {...withErrors(touched, errors, 'iban', showErrors)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="BIC"
                  value={values.bic}
                  onBlur={handleBlur('bic')}
                  onChange={handleChange('bic')}
                  {...withErrors(touched, errors, 'bic', showErrors)}
                />
              </Grid>
            </Grid>
          </UserFormPart>
        </div>
        <FormErrorsModal
          isOpen={isErrorModalOpen}
          onClose={() => this.setState({ isErrorModalOpen: false })}
        />
      </SaleLayout>
    );
  }
}

BankDetails.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setMandateInfos: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userInfos: getSaleUserInfos(state),
  mandateInfos: getMandateInfos(state),
  contactId: getContactId(state),
});

const mapActionCreators = {
  setMandateInfos: setMandateInfosFn,
  setMandateFormValid: setMandateFormValidFn,
  saveMandate: saveMandateFn,
};

const validationSchema = Yup.object().shape({
  holder: Yup.string().required('Le titulaire du compte est requis'),
  iban: Yup.string()
    .test('valid-iban', "L'IBAN est invalide", iban => IBAN.isValid(iban))
    .required("L'iban est requis"),
  bic: Yup.string()
    .test('valid-bic', 'Le BIC est invalide', bic => BIC.isValid(bic))
    .required('Le bic est requis'),
});

const onCheckChange = ({ setIsHolder, setFieldValue, userInfos }) => event => {
  if (event.target.checked) {
    setFieldValue('holder', `${userInfos.givenName} ${userInfos.familyName}`);
  }
  setIsHolder(event.target.checked);
};

export default compose(
  connect(
    mapStateToProps,
    mapActionCreators
  ),
  withState('isHolder', 'setIsHolder', ({ userInfos, mandateInfos }) => {
    if (mandateInfos && mandateInfos.holder) {
      return mandateInfos.holder === `${userInfos.givenName} ${userInfos.familyName}`;
    }
    return true;
  }),
  withFormik({
    mapPropsToValues: ({ userInfos, mandateInfos, isHolder }) => ({
      ...userInfos,
      ...mandateInfos,
      holder:
        mandateInfos.holder || (isHolder ? `${userInfos.givenName} ${userInfos.familyName}` : ''),
    }),
    isInitialValid: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema,

    // TODO : passer par cette méthode quand formik permettra le "submitForm" asynchrone
    // handleSubmit: async (values, { setSubmitting, props }) => {
    //   setSubmitting(true);
    //   await props.saveMandate(values);
    //   setSubmitting(false);
    // },

    displayName: 'BankDetailsForm',
  }),
  withHandlers({ onCheckChange })
)(BankDetails);
