import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CountdownWrapper = styled('div')`
  border-color: ${props => props.theme.palette.primary.main};
  border-width: 23px;
  border-style: solid;
  width: 70%;
  height: 70%;
  border-radius: 50%;
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 180px;
  color: ${props => props.theme.palette.primary.main};
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Countdown = ({ children }) => (
  <CountdownWrapper>
    <span>{children}</span>
  </CountdownWrapper>
);

Countdown.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Countdown;
