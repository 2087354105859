import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Icon, Typography, Button } from '@stadline/react-ui-components';

const GridStyled = styled(Grid)`
  border: 1px solid ${props => props.theme.palette.secondary.main};
  border-radius: 4px;
  padding-left: 16px !important;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const IconButton = styled(Button)`
  border-radius: 4px;
`;

const ShoppingBagLine = ({ title, items }) => (
  <Grid container item style={{ marginBottom: '16px' }}>
    <Grid item xs={12} align="left">
      <Typography gutterBottom>{title ? `·${title}` : ''}</Typography>
    </Grid>
    {items.map(item => (
      <GridStyled container xs={12} key={item.title} alignItems="center">
        <Grid item xs={5}>
          {item.title}
        </Grid>
        <Grid item xs={5} align="right">
          {item.infos}
        </Grid>
        <Grid item xs={2} align="right">
          <IconButton variant="fab" color="primary" onClick={item.onDelete}>
            <Icon name="close" />
          </IconButton>
        </Grid>
      </GridStyled>
    ))}
  </Grid>
);

ShoppingBagLine.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.string,
    })
  ),
};

ShoppingBagLine.defaultProps = {
  title: '',
  items: [],
};

export default ShoppingBagLine;
