import React from 'react';
import PropTypes from 'prop-types';
import PersonalInfosForm from './formsParts/PersonalInfosForm';
import ContactForm from './formsParts/ContactForm';
import AdressForm from './formsParts/AdressForm';
import ProfileForm from './formsParts/ProfileForm';

const UserForm = ({
  errors,
  values,
  handleChange,
  handleBlur,
  touched,
  showErrors,
  setMail,
  checkEmail,
}) => (
  <div style={{ display: 'inline-block', paddingLeft: 'calc(100vw/12)', whiteSpace: 'nowrap' }}>
    <PersonalInfosForm
      errors={errors}
      touched={touched}
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      showErrors={showErrors}
    />
    <ContactForm
      errors={errors}
      touched={touched}
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      showErrors={showErrors}
      setMail={setMail}
      checkEmail={checkEmail}
    />
    <AdressForm
      errors={errors}
      touched={touched}
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      showErrors={showErrors}
    />
    <ProfileForm
      errors={errors}
      touched={touched}
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      showErrors={showErrors}
    />
  </div>
);

UserForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setMail: PropTypes.func.isRequired,
  checkEmail: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  showErrors: PropTypes.bool,
};

UserForm.defaultProps = {
  showErrors: false,
};

export default UserForm;
