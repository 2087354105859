import React from 'react';
import { Loader } from '@app/components';
import { Grid, Typography } from '@stadline/react-ui-components';

const DispenserCardLoading = () => (
  <Grid item container justify="center" alignItems="center" style={{ height: '400px' }}>
    <Grid item xs={12}>
      <Typography variant="h2" style={{ marginBottom: '40px' }}>
        Génération de la carte de club en cours
      </Typography>
      <Loader size={70} />
    </Grid>
  </Grid>
);

export default DispenserCardLoading;
