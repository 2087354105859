import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, Grid, Icon } from '@stadline/react-ui-components';
import { Trans } from '@lingui/react';

const LegalAgeModal = ({ isOpen, onReset }) => (
  <Modal maxWidth="lg" open={isOpen} onClose={onReset}>
    <div style={{ padding: '50px' }}>
      <Grid spacing={40} container>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Icon name="error" color="warning" style={{ fontSize: 70, marginRight: '16px' }} />
          <Typography variant="h2">
            <Trans id="legal_modal_age_title">
              Désolé, tu es trop jeune pour t&apos;abonner chez Fitness Park... :(
            </Trans>
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h3">
            <Trans id="legal_age_modal_text">
              L&apos;âge minimum requis pour s&apos;inscrire dans une salle Fitness Park est de 16
              ans. Si tu as entre 16 et 18 ans, la présence d&apos;un représentant légal majeur est
              obligatoire lors de l&apos;inscription qui devra se faire à l&apos;accueil de ton
              club.
            </Trans>
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h2">
            <Trans id="legal_modal_age_conclusion">A bientôt</Trans>
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Button variant="contained" color="primary" size="large" onClick={onReset}>
            <Trans id="legal_modal_age_button">Quitter la souscription</Trans>
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
);

LegalAgeModal.propTypes = {
  isOpen: PropTypes.bool,
  onReset: PropTypes.func,
};

LegalAgeModal.defaultProps = {
  isOpen: false,
  onReset: () => {},
};
export default LegalAgeModal;
