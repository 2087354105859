import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getSaleProcessNavSteps,
  getSaleProcessActiveNavStep,
  hasNextStep,
  hasPrevStep,
  hasCancelAction,
} from '@app/store/selectors/saleProcess';
import { gotoNextStep, gotoPrevStep } from '@app/store/reducers/saleProcess';
import { compose } from '../helpers';

const SaleProcessContext = React.createContext();

const withSaleProcessProvider = WrappedComponent => {
  const WithSaleProcessProvider = ({
    saleProcess,
    handleNextStep,
    handlePrevStep,
    navSteps,
    activeNavStep,
    ...props
  }) => (
    <SaleProcessContext.Provider
      value={{ saleProcess, handleNextStep, handlePrevStep, navSteps, activeNavStep }}
    >
      <WrappedComponent {...props} />
    </SaleProcessContext.Provider>
  );

  WithSaleProcessProvider.propTypes = {
    saleProcess: PropTypes.object.isRequired,
    handleNextStep: PropTypes.func,
    handlePrevStep: PropTypes.func,
  };

  WithSaleProcessProvider.defaultProps = {
    handleNextStep: null,
    handlePrevStep: null,
  };

  const mapStateToProps = state => ({
    // saleState,
    saleProcess: state.saleProcess,
    navSteps: getSaleProcessNavSteps(state),
    activeNavStep: getSaleProcessActiveNavStep(state),
    hasNextStep: hasNextStep(state),
    hasPrevStep: hasPrevStep(state),
    hasCancelAction: hasCancelAction(state),
  });

  const mapActionCreators = {
    handleNextStep: gotoNextStep,
    handlePrevStep: gotoPrevStep,
  };

  const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
    const { handleNextStep, handlePrevStep } = propsFromDispatch;
    return {
      ...propsFromState,
      handleNextStep: propsFromState.hasNextStep ? handleNextStep : null,
      handlePrevStep: propsFromState.hasPrevStep ? handlePrevStep : null,
      ...ownProps,
    };
  };

  const enhance = compose(
    connect(
      mapStateToProps,
      mapActionCreators,
      mergeProps,
      { pure: false }
    )
  );

  return enhance(WithSaleProcessProvider);
};

const SaleProcessContextConsumer = SaleProcessContext.Consumer;

export { SaleProcessContextConsumer };

export default withSaleProcessProvider;
