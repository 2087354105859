import { ThemeProvider } from '@stadline/react-ui-components';
import * as React from 'react';

export const PRIMARY = '#ffd600';
export const SECONDARY = '#3b444b';
export const WHITE = '#ffffff';

const withThemeProvider = WrappedComponent => {
  const WithThemeProvider = props => (
    <ThemeProvider
      theme={{
        palette: {
          primary: {
            main: PRIMARY,
          },
          secondary: {
            main: SECONDARY,
          },
          white: WHITE,
        },
        typography: {
          fontFamily: "'Open Sans Condensed', 'Roboto Condensed', sans-serif",
          fontSize: 18,
          fontWeightRegular: 700,
          fontWeightLight: 700,
          fontWeightSemiBold: 700,
          fontWeightMedium: 700,
          fontWeightBold: 700,
          h1: {
            fontSize: '48px',
            fontWeight: 700,
            color: SECONDARY,
          },
          h2: {
            fontSize: '32px',
            fontWeight: 700,
            color: SECONDARY,
          },
          h3: {
            fontSize: '24px',
            fontWeight: 700,
            color: SECONDARY,
          },
          h4: {
            fontSize: '20px',
            fontWeight: 700,
            color: SECONDARY,
          },
          body2: {
            fontSize: '18px',
            fontWeight: 700,
            color: SECONDARY,
          },
          button: {
            fontSize: '24px',
            fontWeight: 700,
          },
        },
        overrides: {
          MuiStepper: {
            root: {
              background: 'none',
              fontWeight: 700,
              padding: '0',
            },
          },
          MuiStepIcon: {
            root: {
              fontSize: '32px',
            },
          },
          MuiStepLabel: {
            // Name of the component ⚛️ / style sheet
            label: {
              // Name of the rule
              fontSize: '24px',
              fontWeight: '700!important',
            },
          },
          MuiCheckbox: {
            root: {
              height: 'auto',
              padding: '5px 0',
            },
          },
          MuiFormControlLabel: {
            label: {
              marginTop: '0!important',
            },
          },
          MuiButton: {
            label: {
              fontSize: '24px',
            },
            outlinedSecondary: {
              borderWidth: '2px',
              borderColor: SECONDARY,
            },
            root: {
              minHeight: '60px',
            },
            sizeLarge: {
              minHeight: '72px',
              minWidth: '80px',
            },
          },
          MuiFormControl: {
            root: {
              width: '100%',
            },
          },
          MuiInput: {
            root: {
              lineHeight: '26px',
            },
            formControl: {
              width: '100%',
              padding: '4px',
            },
          },
          MuiInputLabel: {
            formControl: {
              marginLeft: 10,
            },
          },
          MuiToggleButton: {
            root: {
              minHeight: '62px!important',
              paddingLeft: '20px!important',
              paddingRight: '20px!important',
            },
            label: {
              fontSize: '16px',
            },
          },
          MuiMenuItem: {
            root: {
              fontSize: '20px',
              transform: 'translateZ(0)',
            },
          },
          MuiCardMedia: {
            root: {
              minHeight: '290px',
            },
          },
          MuiDialog: {
            paperWidthLg: {
              maxWidth: '980px',
            },
          },
          MuiList: {
            root: {
              maxHeight: '250px', // TODO: trouver un moyen plus propre de réduire la hauteur des select
            },
          },
        },
      }}
    >
      <WrappedComponent {...props} />
    </ThemeProvider>
  );

  return WithThemeProvider;
};

export default withThemeProvider;
