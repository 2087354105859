import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MUNICIPALITY_LIST } from '@app/endpoints/municipalityApi';
import { fetchEntityList as fetchEntityListFn } from '@app/store/reducers/entities';
import { SelectField } from '@stadline/react-ui-components';

class MunicipalitySelector extends React.Component {
  state = {
    municipalities: [],
  };

  async componentDidMount() {
    const { postalCode, fetchEntityList } = this.props;
    if (postalCode) {
      const data = await fetchEntityList(MUNICIPALITY_LIST, {
        params: {
          postalCode,
        },
      });
      const municipalities = data.result || [];
      this.onMunicipalitiesFetched(municipalities);
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { postalCode, fetchEntityList } = this.props;
    if (postalCode !== nextProps.postalCode) {
      const data = await fetchEntityList(MUNICIPALITY_LIST, {
        params: {
          postalCode: nextProps.postalCode,
        },
      });

      const municipalities = data.result || [];
      this.onMunicipalitiesFetched(municipalities);
    }
  }

  async onMunicipalitiesFetched(municipalities) {
    const { onChange, name } = this.props;

    await this.setState(prevState => ({ ...prevState, municipalities }));

    if (municipalities.length === 0) {
      onChange({ name, value: null });
    }
    if (municipalities.length === 1) {
      onChange({ name, value: municipalities[0].localityName });
    }
  }

  render() {
    const { value, onBlur, onChange, required, name } = this.props;
    const { municipalities } = this.state;

    return (
      <SelectField
        name={name}
        disabled={!municipalities.length}
        required={required}
        label="Ville"
        options={municipalities.map(municipality => ({
          id: municipality.localityName,
          label: municipality.localityName,
        }))}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
    );
  }
}

MunicipalitySelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  postalCode: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
};

MunicipalitySelector.defaultProps = {
  value: null,
  postalCode: null,
  required: false,
  name: '',
};

const mapActionCreators = {
  fetchEntityList: fetchEntityListFn,
};

const enhance = connect(
  null,
  mapActionCreators
);

export default enhance(MunicipalitySelector);
