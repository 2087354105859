import * as hasErrorActions from '../actions/hasError';

const initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case hasErrorActions.REDUX_ERROR_OCCURED:
      return true;

    case hasErrorActions.RESET_ERROR:
      return false;

    default:
      return state;
  }
};
