import React, { Component } from 'react';
import { Provider } from 'react-redux';
import createStore from '@app/store';

const withRedux = WrappedComponent => {
  class WithRedux extends Component {
    constructor(props, context) {
      super(props, context);

      this.store = createStore();
    }

    render() {
      return (
        <Provider store={this.store}>
          <WrappedComponent {...this.props} />
        </Provider>
      );
    }
  }

  return WithRedux;
};

export default withRedux;
