import * as hasErrorActions from '../actions/hasError';

export default store => next => async action => {
  const prevState = store.getState();

  try {
    const result = await next(action);
    return result;
  } catch (error) {
    console.groupCollapsed('Redux error occured');
    console.error(error);
    console.groupEnd();

    store.dispatch({ type: hasErrorActions.REDUX_ERROR_OCCURED, payload: { error, prevState } });
    // throw err;
    return prevState;
  }
};
