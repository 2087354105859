import { schema } from 'normalizr';

export const ARTICLE_LIST = '/:clientToken/articles';
export const ARTICLE_WITH_BEHAVIOR = '/:clientToken/articles_with_behaviors';
export const SALE_LIST = '/:clientToken/sales';

const SALE_SCHEMA = new schema.Entity('sales', {}, { idAttribute: '@id' });
const ARTICLE_SCHEMA = new schema.Entity('articles', {}, { idAttribute: '@id' });

export default {
  Article: ARTICLE_SCHEMA,
  Sale: SALE_SCHEMA,
};
