import { createReducer } from 'redux-starter-kit';

const initialState = {
  signature: null,
  needMandate: false,
  acceptMandate: false,
  acceptTerms: false,
};

// ------------------------------------
// Constants
// ------------------------------------

const SET_ACCEPT_TERMS = 'saleProcess/SET_ACCEPT_TERMS';
const SET_ACCEPT_MANDATE = 'saleProcess/SET_ACCEPT_MANDATE';
const SET_SIGNATURE = 'saleProcess/SET_SIGNATURE';

const RESET = 'saleProcess/RESET';

// ------------------------------------
// Action creators
// ------------------------------------

export const setAcceptTerms = acceptTerms => ({ type: SET_ACCEPT_TERMS, acceptTerms });

export const setAcceptMandate = acceptMandate => ({ type: SET_ACCEPT_MANDATE, acceptMandate });

export const setSignature = signature => ({ type: SET_SIGNATURE, signature });

// ------------------------------------
// Handlers
// ------------------------------------

const handleSetAcceptMandate = (state, { acceptMandate }) => ({
  ...state,
  acceptMandate,
});

const handleSetAcceptTerms = (state, { acceptTerms }) => ({
  ...state,
  acceptTerms,
});

const handleSetSignature = (state, { signature }) => ({
  ...state,
  signature,
});

const handleReset = () => initialState;

export default createReducer(initialState, {
  [SET_ACCEPT_MANDATE]: handleSetAcceptMandate,
  [SET_ACCEPT_TERMS]: handleSetAcceptTerms,
  [SET_SIGNATURE]: handleSetSignature,
  [RESET]: handleReset,
});
