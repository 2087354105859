import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MachineRouter } from '@app/state-machine';

const withStateMachineRouter = WrappedComponent => {
  const WithStateMachineRouter = ({ saleProcess, ...props }) => (
    <MachineRouter store={saleProcess}>
      <WrappedComponent {...props} />
    </MachineRouter>
  );

  WithStateMachineRouter.propTypes = {
    saleProcess: PropTypes.shape({
      currentStep: PropTypes.string.isRequired,
    }).isRequired,
  };

  const mapStateToProps = state => ({
    saleProcess: state.saleProcess,
  });

  return connect(
    mapStateToProps,
    null,
    null,
    { pure: false }
  )(WithStateMachineRouter);
};

export default withStateMachineRouter;
