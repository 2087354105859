import { withLocalizedDate } from '@stadline/react-ui-components';
import { compose } from '../helpers';
import withI18n from './withI18n';
import withRedux from './withRedux';
import withSocket from './withSocket';
import withRouter from './withRouter';
import withStateMachineRouter from './withStateMachineRouter';
import withSaleProcessProvider from './withSaleProcessProvider';
import withThemeProvider from './withThemeProvider';

const withRoot = compose(
  // providers
  withRedux,
  withSocket,
  withThemeProvider,
  withLocalizedDate,
  withI18n,
  withRouter,
  withStateMachineRouter,
  withSaleProcessProvider
);

export default withRoot;
