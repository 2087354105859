import { createReducer } from 'redux-starter-kit';
import parseLocation from '../utils/parseLocation';
import { getGrootParams, getClientToken } from '../selectors/config';

const callApi = async url => {
  const response = await fetch(url);

  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }

  throw new Error(response.statusText);
};

const { baseUrl, basename, clientToken, clubId } = parseLocation(
  document.location.href,
  process.env.REACT_APP_BASEURL_PATTERN
);

const initialState = {
  clubId: `/${clientToken}/clubs/${clubId}`,
  clientToken,
  router: {
    baseUrl,
    baseUrlPattern: process.env.REACT_APP_BASEURL_PATTERN,
    basename,
  },
  api: {
    baseUrl: process.env.REACT_APP_API_BASEURL,
  },
  oauth: {
    baseUrl: process.env.REACT_APP_API_BASEURL,
    clientId: process.env.REACT_APP_OAUTH_CLIENTID,
    clientSecret: process.env.REACT_APP_OAUTH_CLIENTSECRET,
  },
  socket: {
    baseUrl: process.env.REACT_APP_SOCKET_BASEURL,
    room: clientToken,
  },
  groot: {
    baseUrl: process.env.REACT_APP_GROOT_BASEURL,
    config: {
      subscriptions: {
        monthly: [],
        spot: [],
      },
      subscriptionOptions: [],
      shop: [],
    },
    version: 1,
  },
  idleTimeout:
    (process.env.REACT_APP_IDLE_TIMEOUT && parseInt(process.env.REACT_APP_IDLE_TIMEOUT, 10)) ||
    60000,
};

// ------------------------------------
// Action creators
// ------------------------------------

export const triggerFetchGrootConfig = () => async (dispatch, getState) => {
  dispatch({ type: 'config/FETCH_GROOT' });

  const config = await getGrootParams(getState());
  const token = await getClientToken(getState());
  const url = `${config.baseUrl}/api/${token}/fitnesspark-member-terminal/config/default/${
    config.version
  }`;

  try {
    const profileConfig = await callApi(url);
    dispatch({
      type: 'config/FETCH_GROOT_SUCCESS',
      payload: {
        profileConfig,
      },
    });
  } catch (error) {
    // failure, try again
    console.error('Error Groot configuration:', error); // eslint-disable-line no-console
  }
};

// ------------------------------------
// Handlers
// ------------------------------------

export default createReducer(initialState, {
  'config/FETCH_GROOT_SUCCESS': (state, { payload }) => ({
    ...state,
    groot: {
      ...state.groot,
      config: {
        ...state.groot.config,
        ...payload.profileConfig,
      },
    },
  }),
});
