import { schema } from 'normalizr';
import clubSchemas from './clubApi';
import communicationSchema from './communicationApi';
import contactSchemas from './contactApi';
import fileSchemas from './fileApi';
import productSchemas from './productApi';
import referentialSchemas from './referentialApi';
import saleSchemas from './saleApi';

const ENTITY_SCHEMA = new schema.Union(
  {
    ...clubSchemas,
    ...communicationSchema,
    ...contactSchemas,
    ...fileSchemas,
    ...productSchemas,
    ...referentialSchemas,
    ...saleSchemas,
  },
  '@type'
);

export default ENTITY_SCHEMA;

export { default as makeApiClient } from './clients/makeApiClient';
export { default as makeOauthClient } from './clients/makeOauthClient';

export const TRANSACTIONS = '/:clientToken/proceedings';
