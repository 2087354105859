import React from 'react';
import { Icon, Checkbox } from '@stadline/react-ui-components';
import styled from 'styled-components';

const CheckboxWrapper = styled('div')`
  background-color: ${props => (props.checked ? props.theme.palette.primary.main : '#fff')};
  border: 2px solid
    ${props => (props.checked ? props.theme.palette.secondary.main : props.theme.palette.grey[300])};
  border-radius: 5px;
  width: 100px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const CheckboxIcon = styled(Icon)`
  font-size: 40px !important;
`;

const CustomCheckbox = props => (
  <Checkbox
    style={{ width: '100px' }}
    icon={<CheckboxWrapper />}
    checkedIcon={
      <CheckboxWrapper checked>
        <CheckboxIcon style={{ color: '#fff' }} name="check" />
      </CheckboxWrapper>
    }
    indeterminateIcon={
      <CheckboxWrapper checked>
        <CheckboxIcon style={{ color: '#fff' }} name="check" />
      </CheckboxWrapper>
    }
    {...props}
  />
);

export default CustomCheckbox;
