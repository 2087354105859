import * as React from 'react';
import * as PropTypes from 'prop-types';

const wrapperRef = React.createRef();

const makeHandleEvent = domElement => () => {
  domElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
};

class ScrollTarget extends React.Component {
  componentDidMount() {
    if (wrapperRef.current) {
      this.handleEvent = makeHandleEvent(wrapperRef.current);
      wrapperRef.current.addEventListener('click', this.handleEvent);
      wrapperRef.current.addEventListener('focus', this.handleEvent, true);
    }
  }

  componentWillUnmount() {
    if (wrapperRef.current && this.handleEvent) {
      wrapperRef.current.removeEventListener('click', this.handleEvent);
      wrapperRef.current.removeEventListener('focus', this.handleEvent, true);
    }
  }

  render() {
    const { children, ...props } = this.props;

    return (
      <div {...props} ref={wrapperRef}>
        {children}
      </div>
    );
  }
}

ScrollTarget.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollTarget;
