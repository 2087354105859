import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import rootReducer from './reducers';
import { crashReporter } from './middlewares';

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};

const createStore = () => {
  const preloadedState = loadState();
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: [crashReporter, ...getDefaultMiddleware()],
  });

  store.subscribe(() => {
    saveState({
      entities: store.getState().entities,
      authentication: store.getState().authentication,
      saleProcess: store.getState().saleProcess,
      saleProcessContract: store.getState().saleProcessContract,
      saleProcessUser: store.getState().saleProcessUser,
      // saleState: store.getState().saleState,
      // messages: store.getState().messages,
    });
  });

  return store;
};

export default createStore;
