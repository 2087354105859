import * as React from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import * as PropTypes from 'prop-types';
import { Grid, Button, Typography } from '@stadline/react-ui-components';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import Logo from './Logo';

moment.locale('fr');

const AppBarWrapper = styled('div')`
  background-color: ${props => props.theme.palette.secondary.main};
  height: 112px;
  color: ${props => props.theme.palette.text.white};
  display: flex;
  flex-shrink: 0;
`;

class AppBar extends React.Component {
  state = {
    date: new Date(),
    clicks: 0,
  };

  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }

  componentDidUpdate() {
    const { history } = this.props;
    const { clicks } = this.state;

    if (clicks === 10) {
      this.setState({ clicks: 0 }); // eslint-disable-line react/no-did-update-set-state
      sessionStorage.clear();
      document.location.href = history.createHref({ pathname: '/' });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleClick = () => {
    this.setState(state => ({ clicks: state.clicks + 1 }));
  };

  tick() {
    this.setState({
      date: new Date(),
    });
  }

  render() {
    const { club, cancelAction } = this.props;
    const { date } = this.state;
    return (
      <AppBarWrapper>
        <Grid
          style={{ padding: '0 20px' }}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={5}>
            {cancelAction && (
              <Button
                onClick={cancelAction}
                variant="contained"
                color="primary"
                size="large"
                style={{ width: '142px', height: '60px', minHeight: 0, padding: '8px 16px' }}
              >
                Annuler
              </Button>
            )}
          </Grid>

          <Grid item xs={2} onClick={this.handleClick}>
            <Logo />
          </Grid>
          <Grid container item xs={5} justify="flex-end" alignItems="center">
            <Typography variant="h3" color="inherit" style={{ padding: '0 20px' }}>
              {club.name}
            </Typography>
            <Typography variant="h3" color="inherit" style={{ padding: '0 20px' }}>
              {moment(date).format('dddd DD MMMM')}
            </Typography>
            <Typography variant="h3" color="inherit" style={{ padding: '0 20px' }}>
              {moment(date).format('HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      </AppBarWrapper>
    );
  }
}

AppBar.propTypes = {
  club: PropTypes.shape({
    name: PropTypes.string,
  }),
  cancelAction: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

AppBar.defaultProps = {
  club: {
    name: 'Club sans nom',
  },
  cancelAction: null,
};

export default withRouter(AppBar);
