/* eslint-disable no-console, global-require */

import axios from 'axios';

const getOptions = (options = {}) => ({
  url: `${String(process.env.REACT_APP_DEVICE_URL)}/ws/${String(options.endpoint)}`,
  method: options.method || 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  data: options.data,
  ...options,
});

// eslint-disable-next-line import/prefer-default-export
export const execute = (command, userOptions = {}) => axios(getOptions(command, userOptions));
export const distributeCard = () =>
  axios(getOptions({ endpoint: 'dispenser', data: { Cmd: 'Distribute' } }));
export const getDeviceInfos = () => axios(getOptions({ endpoint: 'heartbeat', method: 'GET' }));
export const requestPayment = data => axios(getOptions({ endpoint: 'payment', data }));
export const authenticate = token =>
  axios(getOptions({ endpoint: 'authenticate', data: { Authorization: `Bearer ${token}` } }));
