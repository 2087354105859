import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Button, Icon } from '@stadline/react-ui-components';
import styled from 'styled-components';
import { SizeMe } from 'react-sizeme';
import { getSalePicture, getSalePictureList } from '@app/store/selectors/saleProcess';
import {
  setPicture as setPictureFn,
  addPicture as addPictureFn,
  saveContact as saveContactFn,
} from '@app/store/reducers/saleProcessUser';
import { saveFile as saveFileFn } from '@app/store/reducers/entities';
import { dataURItoBlob } from '../../helpers/base64';
import Countdown from '../../components/Countdown';
import PictureGallery from '../../components/PictureGallery';
import SaleLayout from '../../components/SaleLayout';
import WebcamPictureField from '../../components/WebcamPictureField'; // todo: use react-packages component and remove react-webcam
import backgroundUrl from './webcam.png';

const InstructionsWrapper = styled('div')`
  background-image: url(${backgroundUrl});
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  color: #fff;
`;

class Picture extends React.Component {
  constructor(props) {
    super(props);
    this.webcam = React.createRef();
  }

  componentDidMount() {
    const screenWidth =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const SPACING = 40;
    const COLUMN_SIZE = (screenWidth - 12 * SPACING) / 12;
    this.videoWidth = 4 * COLUMN_SIZE + 3 * SPACING; // todo: make something proper to know the webcam video size
  }

  takePicture = () => {
    const { setPicture, picture } = this.props;

    if (picture) {
      setPicture(null);
      this.webcam.current.takePicture();
    } else this.webcam.current.takePicture();
  };

  clearPicture = () => {
    const { setPicture } = this.props;

    setPicture(null);
  };

  onChange = value => {
    const { addPicture, pictureList, setPicture } = this.props;
    addPicture(value);
    // if last picture : we select by default this picture
    if (pictureList.length === 2) {
      setPicture(value);
    }
  };

  onSelectPicture = value => {
    const { setPicture } = this.props;

    setPicture(value);
  };

  submitPicture = async () => {
    const { picture, saveFile, saveContact } = this.props;
    const savedPicture = await saveFile('contact_images', { data: dataURItoBlob(picture) });
    await saveContact({ pictureId: savedPicture.result['@id'] });
  };

  render() {
    const { picture, pictureList } = this.props;
    const extraActions =
      pictureList && pictureList.length >= 3
        ? []
        : [
            <Button size="large" variant="outlined" color="secondary" onClick={this.takePicture}>
              Prendre une photo
              <Icon style={{ marginLeft: '30px' }} name="add_a_photo" />
            </Button>,
          ];
    return (
      <SaleLayout
        title="Tes informations personnelles"
        extraActions={extraActions}
        onNext={this.submitPicture}
        withNextButton={picture !== null}
      >
        <Grid container spacing={40}>
          <Grid item xs={4}>
            <InstructionsWrapper />
          </Grid>
          <SizeMe noPlaceholder>
            {({ size }) => (
              <Grid item container xs={4} style={{ overflow: 'hidden', height: size.width }}>
                {size.width && (
                  <WebcamPictureField
                    ref={this.webcam}
                    delay={3000}
                    onChange={this.onChange}
                    value={picture}
                    width={size.width - 40}
                    height={size.width - 40}
                    CountdownComponent={Countdown}
                  />
                )}
              </Grid>
            )}
          </SizeMe>
          <Grid item xs={4}>
            <PictureGallery
              pictures={pictureList}
              selected={picture}
              onSelectPicture={this.onSelectPicture}
            />
          </Grid>
        </Grid>
      </SaleLayout>
    );
  }
}

Picture.propTypes = {
  pictureList: PropTypes.arrayOf(PropTypes.string),
  picture: PropTypes.string,
  setPicture: PropTypes.func.isRequired,
  addPicture: PropTypes.func.isRequired,
};

Picture.defaultProps = {
  pictureList: [],
  picture: null,
};

const mapStateToProps = state => ({
  picture: getSalePicture(state),
  pictureList: getSalePictureList(state),
});

const mapActionCreators = {
  setPicture: setPictureFn,
  addPicture: addPictureFn,
  saveFile: saveFileFn,
  saveContact: saveContactFn,
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(Picture);
