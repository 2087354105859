import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, Icon } from '@stadline/react-ui-components';
import { monetize } from '../helpers/i18n';
import { PromoText } from '../pages/Offers/Offer';

const DispenserCardOk = ({
  offer,
  subscriptionInfos,
  isRecurrent,
  isProcessFinished,
  error,
  onClick,
}) => (
  <>
    <Grid item container justify="center" alignItems="center">
      <Icon name="check_circle" style={{ color: '#66dcaa', fontSize: 70, marginRight: '16px' }} />
      <Typography variant="h1">Ta carte est prête !</Typography>
    </Grid>
    <Grid item>
      <Typography variant="h3">Ta formule {`"${offer.name}"`} est maintenant activée</Typography>
    </Grid>

    <Grid item>
      {isRecurrent ? (
        <PromoText {...subscriptionInfos} variant="h3" />
      ) : (
        <Typography variant="h2">{monetize(offer.priceTI)}</Typography>
      )}
    </Grid>

    <Grid item>
      <Typography variant="h3">
        N&apos;oublie pas de récupérer ta carte pour entrer dans le club.
      </Typography>
    </Grid>

    <Grid item>
      <Typography variant="h3">Bienvenue chez Fitness Park et bonne séance !</Typography>
    </Grid>

    <Grid item>
      <Button
        onClick={onClick}
        size="large"
        color="primary"
        style={{ padding: '26px 105px' }}
        variant="contained"
        disabled={error ? false : !isProcessFinished}
      >
        <Typography variant="h3">Terminer</Typography>
      </Button>
    </Grid>
  </>
);

DispenserCardOk.propTypes = {
  offer: PropTypes.object.isRequired,
  subscriptionInfos: PropTypes.object.isRequired,
  isRecurrent: PropTypes.bool.isRequired,
  isProcessFinished: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DispenserCardOk;
