import { isMatch } from 'micromatch';
import { makeOauthClient } from '@app/endpoints';
import * as towerApi from '@app/tower-api';
import { getAccessToken, getIsAccessTokenValid, getTargetId } from '../selectors/authentication';
import { getOAuthConfig, getClientToken } from '../selectors/config';
import sendHTTPRequest from './_sendHTTPRequest';

const initialState = {
  validated: false,
};

// ------------------------------------
// Actions
// ------------------------------------

export const validateToken = () => async (dispatch, getState) => {
  const targetId = getTargetId(getState());
  const response = await dispatch(sendHTTPRequest({ url: targetId }));

  if (response.data['@type'] !== 'ContactUser') {
    throw new Error('You can only log with a contact user.');
  }

  dispatch({ type: 'authentication/VALIDATE' });
};

export const authenticate = (email, password) => async (dispatch, getState) => {
  const oauthClient = makeOauthClient(getClientToken(getState()), getOAuthConfig(getState()));

  // retrieve access token, using provided code
  try {
    const token = await oauthClient.getTokenFromPassword(email, password);

    dispatch({ type: 'authentication/RECEIVE_TOKEN', payload: token });
    await dispatch(validateToken());
  } catch (error) {
    // failure, try again
    dispatch({ type: 'authentication/AUTH_FAILED' });
    console.error('Cannot login user:', error); // eslint-disable-line no-console
  }

  return getState().authentication;
};

export const logout = () => async dispatch => {
  dispatch({ type: 'authentication/REVOKE_TOKEN' });
};

export const refreshAccessToken = () => async (dispatch, getState) => {
  if (!getIsAccessTokenValid(getState())) {
    const oauthClient = makeOauthClient(getClientToken(getState()), getOAuthConfig(getState()));

    try {
      const token = await oauthClient.getTokenFromClientCredentials();
      // const token = await oauthClient.getTokenFromRefreshToken(getRefreshToken(getState()));

      towerApi.authenticate(token.access_token);
      dispatch({ type: 'authentication/RECEIVE_TOKEN', payload: token });
    } catch (error) {
      // do nothing
    }
  }

  return getAccessToken(getState());
};

// ------------------------------------
// Handlers
// ------------------------------------

const handleValidate = state => ({ ...state, validated: true });
const handleReceiveToken = (state, action) => ({ validated: state.validated, ...action.payload });
const handleRevokeToken = () => initialState;

const handleError = (prevState, { error }) => {
  if (error.response && error.response.status === 401) {
    return handleRevokeToken();
  }

  return prevState;
};

// ------------------------------------
// Reducer
// ------------------------------------

const patterns = {
  'authentication/VALIDATE': handleValidate,
  'authentication/RECEIVE_TOKEN': handleReceiveToken,
  'authentication/REVOKE_TOKEN': handleRevokeToken,
  'entities/HANDLE_*_ERROR': handleError,
};

/**
 * @param {Object} state
 * @param {String} action.type
 */
export default (state = initialState, action) =>
  Object.entries(patterns).reduce(
    (nextState, [pattern, handler]) =>
      isMatch(action.type, pattern) ? handler(nextState, action) : nextState,
    state
  );
