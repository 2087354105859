import * as React from 'react';
import PropTypes from 'prop-types';
import { MachineRoute, MachineSwitch } from '@app/state-machine';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset as resetFn, fetchCatalog as fetchCatalogFn } from '@app/store/reducers/saleProcess';
import { getIdleTimeout } from '@app/store/selectors/config';
import { getSaleProcessCurrentStep } from '@app/store/selectors/saleProcess';
import { IdleTimer } from '@app/components';
import Layout from '../../components/Layout';
import ResetSaleModal from '../../components/ResetSaleModal';
import { compose } from '../../helpers';
import ErrorBoundary from '../../components/ErrorBoundary';

import Offers from '../Offers';
import Optins from '../Optins';
import Options from '../Options';
import Products from '../Products';
import Confirm from '../Confirm';
import Summary from '../Summary';
import UserInfos from '../UserInfos';
import Picture from '../Picture';
import MandateChoice from '../MandateChoice';
import BankDetails from '../BankDetails';
import SignContract from '../SignContract';
import Payment from '../Payment';

class SaleProcess extends React.Component {
  constructor(props) {
    super(props);

    this.idleTimer = React.createRef();
  }

  state = {
    isResetModalOpen: false,
  };

  async componentDidMount() {
    const { fetchCatalog } = this.props;

    await fetchCatalog();
  }

  componentWillUnmount() {
    this.resetSale();
  }

  resetSale = async () => {
    if (this.timeout) clearTimeout(this.timeout);
    const { reset, history } = this.props;
    await reset();
    history.push('/');
  };

  onIdle = async () => {
    this.setState({ isResetModalOpen: true });
    // TODO : définir délai dans constante?
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.resetSale, 10000); // au bout de 10 secondes : on annule la vente sans confirmation
  };

  cancelModal = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState({ isResetModalOpen: false });
  };

  canCancel = () => {
    const { currentStep } = this.props;
    return !['payment', 'confirm'].includes(currentStep); // TODO : voir comment utiliser les valeurs de la state machine pour rendre l'affichage du bouton vraiment dynamique
  };

  render() {
    const { isResetModalOpen } = this.state;
    const { idleTimeout } = this.props;
    return (
      <ErrorBoundary>
        <Layout cancelAction={this.canCancel() && this.onIdle}>
          {this.canCancel() && (
            <IdleTimer
              ref={this.idleTimer}
              element={document}
              onIdle={this.onIdle}
              debounce={250}
              timeout={idleTimeout} // TODO : définir timing dans constante? ici 2 minutes
            />
          )}
          <ResetSaleModal
            isOpen={isResetModalOpen}
            onConfirm={this.resetSale}
            onCancel={this.cancelModal}
          />
          <MachineSwitch>
            <MachineRoute value="offers" component={Offers} />
            <MachineRoute value="optins" component={Optins} />
            <MachineRoute value="options" component={Options} />
            <MachineRoute value="products" component={Products} />
            <MachineRoute value="summary" component={Summary} />
            <MachineRoute value="payment" component={Payment} />
            <MachineRoute value="userInfos" component={UserInfos} />
            <MachineRoute value="picture" component={Picture} />
            <MachineRoute value="mandateChoice" component={MandateChoice} />
            <MachineRoute value="bankDetails" component={BankDetails} />
            <MachineRoute value="signContract" component={SignContract} />
            <MachineRoute value="confirm" component={Confirm} />
          </MachineSwitch>
        </Layout>
      </ErrorBoundary>
    );
  }
}

SaleProcess.propTypes = {
  currentStep: PropTypes.string.isRequired,
  idleTimeout: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  currentStep: getSaleProcessCurrentStep(state),
  idleTimeout: getIdleTimeout(state),
});

const mapActionCreators = {
  reset: resetFn,
  fetchCatalog: fetchCatalogFn,
};

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapActionCreators
  )
);

export default enhance(SaleProcess);
