import { combineReducers } from 'redux-starter-kit';
// import { reducers as saleState } from '@app/state-machine';
import app from './app';
import authentication from './authentication';
import entities from './entities';
import config from './config';
import saleProcess from './saleProcess';
import saleProcessContract from './saleProcessContract';
import saleProcessUser from './saleProcessUser';
import device from './device';
import hasError from './hasError';

const combinedReducers = combineReducers({
  app,
  authentication,
  config,
  entities,
  hasError,
  saleProcess,
  saleProcessContract, // TODO : use combine reducer to put contract on saleProcess
  saleProcessUser, // TODO : use combine reducer to put user on saleProcess
  // saleState,
  device,
});

export default combinedReducers;
