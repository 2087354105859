import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText as MuiFormHelperText } from '@material-ui/core';
import styled from 'styled-components';

const StyledFormHelperText = styled(MuiFormHelperText)`
  color: ${props => props.theme.palette.common.black};
  padding: ${({ theme, state }) => state && `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`};
  background: ${({ theme, state }) => state && theme.palette[state].main};
  ${props =>
    props.state === 'success' &&
    `
      padding: 0 !important;
      background-color: #66dcaa
      min-height: unset;
      height: 2px;
      margin-top: -1px;
      position: relative;
    `}
`;

const FieldError = ({ state, helperText }) => (
  <StyledFormHelperText state={state}>{helperText}</StyledFormHelperText>
);

FieldError.propTypes = {
  state: PropTypes.string,
  helperText: PropTypes.string,
};

FieldError.defaultProps = {
  state: '',
  helperText: '',
};

export default FieldError;
