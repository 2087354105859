import React from 'react';
import PropTypes from 'prop-types';
import { withState, withHandlers } from 'recompose'; // eslint-disable-line import/no-extraneous-dependencies
import * as Yup from 'yup';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSaleUserInfos, getContactId } from '@app/store/selectors/saleProcess';
import {
  setUserInfos as setUserInfosFn,
  setUserFormValid as setUserFormValidFn,
  saveContact as saveContactFn,
  fetchReferential as fetchReferentialFn,
  checkExistingContact as checkExistingContactFn,
} from '@app/store/reducers/saleProcessUser';
import { reset as resetFn } from '@app/store/reducers/saleProcess';

import { withFormik } from 'formik';
import { compose, debounce } from '../../helpers';
import isValidDomain from '../../helpers/forbiddenMailDomains';
import SaleLayout from '../../components/SaleLayout';
import LegalAgeModal from '../../components/LegalAgeModal';
import FormErrorsModal from '../../components/FormErrorsModal';
import UserForm from '../../components/UserForm';

class UserInfos extends React.Component {
  state = {
    showErrors: false,
    isLegalAgeOpen: false,
    isErrorModalOpen: false,
    error: null,
  };

  checkEmail = debounce(async email => {
    const { checkExistingContact, emailExist, setEmailExist, validateForm } = this.props;
    const { result } = await checkExistingContact({ email });
    if (result.length > 0) {
      setEmailExist(true);
      return this.setState({
        isErrorModalOpen: true,
        error: 'form_errors_modal_text_email_already_exist',
      });
    }
    if (emailExist) {
      setEmailExist(false);
      validateForm();
    }
    return true;
  }, 1000);

  async componentDidMount() {
    const { fetchReferential } = this.props;

    await fetchReferential();
  }

  resetSale = async () => {
    const { reset, history } = this.props;
    await reset();
    history.push('/');
  };

  tryToSubmitForm = async () => {
    const { validateForm, values, saveContact, setUserInfos, setUserFormValid } = this.props;

    const validate = await validateForm();
    if (Object.keys(validate).length > 0) {
      setUserFormValid(false);
      this.setState({
        showErrors: true,
        isErrorModalOpen: true,
      });
      return Promise.reject();
    }

    // legal age managment
    if (values.birthDate) {
      const maxDate = moment().subtract(18, 'years');
      const testDate = moment(values.birthDate);
      if (testDate.isAfter(maxDate)) {
        this.setState({
          isLegalAgeOpen: true,
        });
        return Promise.reject();
      }
    }

    setUserFormValid(true);
    setUserInfos(values);
    return saveContact(values);
  };

  saveUserInfos = async () => {
    const { setUserInfos, values } = this.props;
    setUserInfos(values);
  };

  setMail = email => {
    const { setFieldValue } = this.props;
    setFieldValue('email', email);
  };

  closeModal = () => {
    const { validateForm } = this.props;
    validateForm();
    this.setState({ isErrorModalOpen: false, error: undefined });
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleBlur,
      handleSubmit,
      isSubmitting,
      onChange,
    } = this.props;
    const { showErrors, isLegalAgeOpen, isErrorModalOpen, error } = this.state;
    return (
      <SaleLayout
        withScroll
        withShoppingBag
        title="Tes informations personnelles"
        onNext={this.tryToSubmitForm}
        onPrev={this.saveUserInfos}
      >
        <UserForm
          values={values}
          errors={errors}
          showErrors={showErrors}
          touched={touched}
          handleChange={onChange}
          handleBlur={handleBlur}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setMail={this.setMail}
          checkEmail={this.checkEmail}
        />
        <LegalAgeModal isOpen={isLegalAgeOpen} onReset={this.resetSale} />
        <FormErrorsModal isOpen={isErrorModalOpen} onClose={this.closeModal} errorId={error} />
      </SaleLayout>
    );
  }
}

UserInfos.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  checkExistingContact: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userInfos: getSaleUserInfos(state),
  contactId: getContactId(state),
});

const mapActionCreators = {
  setUserInfos: setUserInfosFn,
  setUserFormValid: setUserFormValidFn,
  saveContact: saveContactFn,
  fetchReferential: fetchReferentialFn,
  reset: resetFn,
  checkExistingContact: checkExistingContactFn,
};

const validationSchema = props =>
  Yup.object().shape({
    gender: Yup.string().required('La civilité est requise'),
    givenName: Yup.string().required('Le prénom est requis'),
    familyName: Yup.string()
      .min(2, 'Le nom doit comporter au moins 2 caractères.')
      .required('Le nom est requis'),
    birthDate: Yup.date().required('La date de naissance est requise'),
    email: Yup.string()
      .email("L'email n'est pas valide !")
      .required("L'adresse mail est requise")
      .test('email-exist', 'Cette adresse email est déjà utilisée.', () => !props.emailExist)
      .test(
        'forbidden-domain',
        "Désolé, tu n'es pas autorisé à utiliser cette adresse mail",
        email => {
          if (!email) return this;
          const [, domain] = email.match(/@(.+)/) || [];
          return domain && isValidDomain(domain);
        }
      ),
    address: Yup.object().shape({
      postalCode: Yup.string().required('Le code postal est requis'),
      streetAddress: Yup.string().required("L'adresse est requise"),
      addressLocality: Yup.string()
        .nullable()
        .required('La ville est requise'),
    }),
  });

const getValue = (field, e) =>
  ['gender', 'birthDate'].includes(field)
    ? [e, value => value]
    : [
        (e && e.value) || (e && e.target && e.target.value),
        value => value.charAt(0).toUpperCase() + value.slice(1),
      ];

const onChange = ({ handleChange }) => field => e => {
  const [value, getReturn] = getValue(field, e);

  if (value) {
    handleChange(field)(getReturn(value));
  }
};

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapActionCreators
  ),
  withState('emailExist', 'setEmailExist', false),
  withFormik({
    mapPropsToValues: ({ userInfos }) => ({ ...userInfos }),
    isInitialValid: true,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema,

    // TODO : passer par cette méthode quand formik permettra le "submitForm" asynchrone
    /* handleSubmit: async (values, { setSubmitting, props }) => {
      setSubmitting(true);
      await props.saveContact(values);
      setSubmitting(false);
    }, */

    displayName: 'UserInfosForm',
  }),
  withHandlers({ onChange })
);

export default enhance(UserInfos);
