import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, ToggleButtonGroup, ToggleButton } from '@stadline/react-ui-components';
import { monetize } from 'app/helpers/i18n'; // eslint-disable-line import/no-extraneous-dependencies
import { getDuration, getIsRecurrent } from 'app/helpers'; // eslint-disable-line import/no-extraneous-dependencies
import Card from './Card';

const SelectableCard = styled(Card)`
  border: ${props =>
    props.selected
      ? `3px solid ${props.theme.palette.primary.main}`
      : `1px solid ${props.theme.palette.secondary.main}`};
  border-radius: 0 !important;
`;

const OfferCardHeader = styled('div')`
  padding: 40px;
  background-color: ${props => props.theme.palette.secondary.main};

  h4,
  h2 {
    color: ${props => props.theme.palette.white};
  }
`;

const OfferCardContent = styled('div')`
  padding: 16px 40px;
`;

const OfferCardSection = styled('div')`
  margin-bottom: 16px;
`;

const OfferCardFooter = styled('div')`
  border-top: 2px solid ${props => props.theme.palette.secondary.main};
  margin-top: 30px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 16px;
  }
`;

const OfferCard = ({
  offers,
  offer,
  offerInfos,
  selected,
  monthly,
  onOfferSelect,
  onToggleClick,
  offerMonthlyDuration,
}) => {
  const MonthlyText = monthly;
  const { price, registrationFeeTI, hasPromo, promoDuration, promoPrice } = offerInfos;

  return (
    <SelectableCard selected={selected} onClick={onOfferSelect(offer['@id'])}>
      <Card.cardContent style={{ padding: 0 }}>
        <OfferCardHeader>
          <Typography variant="h4">{offer.name}</Typography>
          <Typography variant="h2">{monetize(price)}</Typography>
        </OfferCardHeader>
        <OfferCardContent>
          {getIsRecurrent(offer) ? (
            <OfferCardSection>
              <Typography>+MENSUALITÉ</Typography>
              <MonthlyText {...{ hasPromo, promoPrice, promoDuration, price }} />
            </OfferCardSection>
          ) : null}

          <OfferCardSection>
            <Typography>+FRAIS D&apos;INSCRIPTION</Typography>
            <Typography>{monetize(registrationFeeTI)}</Typography>
          </OfferCardSection>

          {offerInfos.duration > 0 ? (
            <OfferCardSection>
              <Typography>+ENGAGEMENT</Typography>
              <Typography>{offerInfos.duration} mois</Typography>
            </OfferCardSection>
          ) : null}

          {Array.isArray(offers) ? (
            <OfferCardFooter>
              <Typography>Durée d&apos;engagement</Typography>
              <ToggleButtonGroup
                exclusive
                selected
                value={offerMonthlyDuration || getDuration(offers[0])}
                size="xs"
              >
                {offers.map(offerItem => {
                  const offerDuration = getDuration(offerItem);

                  return (
                    <ToggleButton
                      key={offerItem['@id']}
                      value={offerDuration}
                      onClick={onToggleClick(offerDuration)}
                    >
                      {`${offerDuration} mois`}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </OfferCardFooter>
          ) : null}
        </OfferCardContent>
      </Card.cardContent>
    </SelectableCard>
  );
};

OfferCard.propTypes = {
  offers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  offer: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  offerInfos: PropTypes.object.isRequired,
  offerMonthlyDuration: PropTypes.number,
  selected: PropTypes.bool.isRequired,
  monthly: PropTypes.func.isRequired,
  onOfferSelect: PropTypes.func.isRequired,
  onToggleClick: PropTypes.func.isRequired,
};

OfferCard.defaultProps = {
  offerMonthlyDuration: null,
};

export default OfferCard;
