import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import AppBar from '../AppBar';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: ${props => props.theme.typography.fontWeightRegular};
`;

const ContainerWrapper = styled('div')`
  background-color: ${props => props.theme.palette.grey[100]};
  flex: 1;
  display: flex;
`;

const DefaultLayout = ({ children, club, cancelAction }) => (
  <LayoutWrapper>
    <AppBar club={club} cancelAction={cancelAction} />
    <ContainerWrapper>{children}</ContainerWrapper>
  </LayoutWrapper>
);

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  club: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  cancelAction: PropTypes.func,
};

DefaultLayout.defaultProps = {
  cancelAction: null,
};

export default DefaultLayout;
