import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose, withState, withHandlers } from 'recompose'; // eslint-disable-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import { Typography, Grid } from '@stadline/react-ui-components';
import { Loader } from '@app/components';
import {
  makeGetArticles,
  getSaleArticleIds,
  getTotalOnregistration,
  getIsLoadingArticles,
} from '@app/store/selectors/saleProcess';
import {
  deleteArticle as deleteArticleFn,
  reset as resetFn,
} from '@app/store/reducers/saleProcess';
import { PromoText } from '../pages/Offers/Offer';
import { getOfferInfos, getIsRecurrent, getDuration } from '../helpers';
import ShoppingBagLine from './ShoppingBagLine';
import ShoppingBagLineEditable from './ShoppingBagLineEditable';
import { monetize } from '../helpers/i18n';
import ConfirmDeleteOfferModal from './ConfirmDeleteOfferModal';

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
`;

const ShoppingBagWrapper = styled('div')`
  position: relative;
  ${props => !props.withoutBorder && `border: 1px solid ${props.theme.palette.secondary.main}`};
  background-color: #fff;
  margin-bottom: 20px;

  ${props =>
    props.shouldBlur
      ? `
      overflow: hidden;

      > div:first-child {
        filter: blur(3px);
        pointer-events: none;
        overflow: hidden;
      }
      `
      : null};
`;

const ShoppingBagTotal = styled(Grid)`
  background-color: ${props => props.theme.palette.secondary.main};
  color: #fff;
`;

const ShoppingBag = ({
  subscription,
  subscriptionOptions,
  regularProducts,
  padding,
  totalOnRegistration,
  edit,
  isModalOpened,
  onDeleteOffer,
  onConfirmDeleteOffer,
  onCancelDeleteOffer,
  deleteArticle,
  withoutBorder,
  isLoadingArticles,
}) => {
  if (!subscription) return null;

  const subscriptionInfos = getOfferInfos(subscription);
  const isRecurrent = getIsRecurrent(subscription);
  const duration = getDuration(subscription);

  const monthlyPrice =
    (isRecurrent ? subscriptionInfos.price : 0) +
    subscriptionOptions.reduce((acc, option) => acc + option.totalTI, 0);

  const offerLineProps = {
    title: edit ? null : 'FORMULE',
    items: [
      {
        title: subscription.offerName,
        infos: isRecurrent ? <PromoText {...subscriptionInfos} /> : monetize(subscription.priceTI),
        ...(edit ? { onDelete: onDeleteOffer } : {}),
      },
    ],
  };
  const OfferLineComponent = edit ? ShoppingBagLineEditable : ShoppingBagLine;

  const getOptionText = () => (isRecurrent ? ` / mois` : '');

  const optionLineProps = {
    title: 'OPTIONS',
    items: subscriptionOptions.map(option => ({
      title: option.productName,
      infos: `${monetize(getOfferInfos(option).price)}${getOptionText()}`,
      ...(edit ? { onDelete: () => deleteArticle(option['@id']) } : {}),
    })),
  };
  const OptionLineComponent = edit ? ShoppingBagLineEditable : ShoppingBagLine;

  const regularProductLineProps = {
    title: 'ACCESSOIRES',
    items: regularProducts.map(regularProduct => ({
      title: regularProduct.productName,
      infos: `${monetize(regularProduct.priceTI)}`,
      ...(edit ? { onDelete: () => deleteArticle(regularProduct['@id']) } : {}),
    })),
  };
  const RegularProductLineComponent = edit ? ShoppingBagLineEditable : ShoppingBagLine;

  return (
    <>
      <ShoppingBagWrapper withoutBorder={withoutBorder} shouldBlur={isLoadingArticles}>
        <Grid container direction="column">
          <Grid container direction="row" style={{ padding: `20px ${padding || 0}px` }}>
            <Grid item style={{ borderBottom: '2px solid #ccc' }} xs={12}>
              <Typography variant="h3" gutterBottom>
                TA FORMULE
              </Typography>
            </Grid>
            <Grid item container spacing={8} alignItems="center" style={{ padding: '10px 0' }}>
              <OfferLineComponent {...offerLineProps} />
              {duration ? (
                <ShoppingBagLine
                  title="ENGAGEMENT"
                  items={[
                    {
                      title: `${isRecurrent ? 'Prélèvement -' : ''} ${duration} mois`,
                    },
                  ]}
                />
              ) : null}

              {subscriptionOptions && subscriptionOptions.length > 0 ? (
                <OptionLineComponent {...optionLineProps} />
              ) : null}
            </Grid>
          </Grid>

          {isRecurrent ? (
            <ShoppingBagTotal
              item
              container
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: `17px ${padding || 0}px`,
              }}
            >
              <Grid item xs={8}>
                <Typography color="inherit">MONTANT MENSUEL</Typography>
              </Grid>
              <Grid item xs={4} container justify="flex-end">
                <Typography align="right" color="inherit">
                  {monetize(monthlyPrice)}
                </Typography>
              </Grid>
            </ShoppingBagTotal>
          ) : null}

          <Grid container direction="column" style={{ padding: `20px ${padding || 0}px` }}>
            <Grid item container spacing={8} alignItems="center">
              {regularProducts && regularProducts.length > 0 ? (
                <RegularProductLineComponent {...regularProductLineProps} />
              ) : null}
              <ShoppingBagLine
                title="FRAIS DE DOSSIER"
                price={monetize(subscription.registrationFeeTI)}
              />
            </Grid>
          </Grid>
          <ShoppingBagTotal
            item
            container
            xs={12}
            alignItems="center"
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: `17px ${padding || 0}px`,
            }}
          >
            <Grid item xs={8}>
              <Typography color="inherit">MONTANT A L&apos;INSCRIPTION</Typography>
            </Grid>
            <Grid item xs={4} container justify="flex-end">
              <Typography align="right" color="inherit">
                {monetize(totalOnRegistration)}
              </Typography>
            </Grid>
          </ShoppingBagTotal>
        </Grid>
        <ConfirmDeleteOfferModal
          isOpen={isModalOpened}
          onConfirm={onConfirmDeleteOffer}
          onCancel={onCancelDeleteOffer}
        />
        {isLoadingArticles ? (
          <LoaderContainer>
            <Loader color="secondary" />
          </LoaderContainer>
        ) : null}
      </ShoppingBagWrapper>
    </>
  );
};

ShoppingBag.propTypes = {
  padding: PropTypes.number,
  totalOnRegistration: PropTypes.number,
  edit: PropTypes.bool,
  isModalOpened: PropTypes.bool.isRequired,
  setIsModalOpened: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  withoutBorder: PropTypes.bool,
};

ShoppingBag.defaultProps = {
  padding: 20,
  totalOnRegistration: null,
  edit: false,
  withoutBorder: false,
};

const mapStateToProps = state => {
  const articleIds = getSaleArticleIds(state);
  const articles = makeGetArticles(articleIds)(state);
  const isLoadingArticles = getIsLoadingArticles(state);

  const subscription = articles.find(
    article => article.productType === 'subscription' && !article.parent
  );

  const subscriptionOptions = articles.filter(
    article =>
      article.productType === 'subscription_option' ||
      (article.productType === 'subscription' && article.parent)
  );

  const regularProducts = articles.filter(article => article.productType === 'regular');

  return {
    subscription,
    subscriptionOptions,
    regularProducts,
    totalOnRegistration: getTotalOnregistration(state),
    isLoadingArticles,
  };
};

const mapActionCreator = {
  deleteArticle: deleteArticleFn,
  reset: resetFn,
};

const onDeleteOffer = ({ setIsModalOpened }) => () => setIsModalOpened(true);

const onConfirmDeleteOffer = ({ subscription, deleteArticle, reset, history }) => async () => {
  await deleteArticle(subscription['@id']);
  await reset();
  history.push('/sale-process');
};

const onCancelDeleteOffer = ({ setIsModalOpened }) => () => setIsModalOpened(false);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapActionCreator
  ),
  withState('isModalOpened', 'setIsModalOpened', false),
  withHandlers({ onDeleteOffer, onConfirmDeleteOffer, onCancelDeleteOffer })
)(ShoppingBag);
