import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid } from '@stadline/react-ui-components';
import { ZipCodeField, FieldError } from '@app/components';
import { MunicipalitySelector } from '../fields';
import { GRID_SPACING } from '../../../consts';
import { withNestedErrors } from '../helpers';
import { uuidv4 } from '../../../helpers';
import UserFormPart from './UserFormPart';

const ContactForm = ({ values, handleChange, handleBlur, touched, errors, showErrors }) => (
  <UserFormPart>
    <Grid container spacing={GRID_SPACING}>
      <Grid item xs={12}>
        <TextField
          required
          label="Addresse"
          value={values.address && values.address.streetAddress}
          onBlur={handleBlur('address.streetAddress')}
          onChange={handleChange('address.streetAddress')}
          inputProps={{
            autoComplete: uuidv4(),
            maxlength: 254,
          }}
          {...withNestedErrors(touched, errors, 'address', 'streetAddress', showErrors)}
        />
      </Grid>
      <Grid item xs={6}>
        <ZipCodeField
          required
          name="address.postalCode"
          label="Code postal"
          value={values.address && values.address.postalCode}
          onBlur={handleBlur('address.postalCode')}
          onChange={handleChange('address.postalCode')}
          inputProps={{
            autoComplete: uuidv4(),
          }}
          {...withNestedErrors(touched, errors, 'address', 'postalCode', showErrors)}
        />
      </Grid>
      <Grid item xs={6}>
        <MunicipalitySelector
          name="address.addressLocality"
          required
          postalCode={values.address && values.address.postalCode}
          value={(values.address && values.address.addressLocality) || ''}
          onBlur={handleBlur('address.addressLocality')}
          onChange={handleChange('address.addressLocality')}
          {...withNestedErrors(touched, errors, 'address', 'addressLocality', showErrors)}
        />
        <FieldError
          {...withNestedErrors(touched, errors, 'address', 'addressLocality', showErrors)}
        />
      </Grid>
    </Grid>
  </UserFormPart>
);

ContactForm.propTypes = {
  values: PropTypes.shape({
    address: PropTypes.shape({
      addressLocality: PropTypes.string,
      postalCode: PropTypes.string,
      streetAddress: PropTypes.string,
    }),
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  showErrors: PropTypes.bool.isRequired,
};

export default ContactForm;
