import { createSelector } from 'redux-starter-kit';
import { flattenOffers } from './helpers';
/**
 * Returns config state
 *
 * @param {object} state
 * @returns {object}
 */
export const getConfig = createSelector(['config']);

/**
 * Returns Socket.IO config
 *
 * @param {object} state
 * @returns {object}
 */
export const getSocketConfig = createSelector(
  [getConfig],
  config => config.socket
);

export const getClubId = createSelector(
  [getConfig],
  config => config.clubId
);

export const getIdleTimeout = createSelector(
  [getConfig],
  config => config.idleTimeout
);

/**
 * Returns groot params
 *
 * @param {object} state
 * @returns {object}
 */
export const getGrootParams = createSelector(
  ['config.groot'],
  grootParams => grootParams
);

export const getCatalog = createSelector(
  ['config.groot.config'],
  catalog => catalog
);

export const getCatalogOffers = createSelector(
  [getCatalog],
  catalog => catalog.subscriptions
);

export const getCatalogOffersArray = createSelector(
  [getCatalog],
  catalog => flattenOffers(catalog.subscriptions)
);

export const getCatalogOptions = createSelector(
  [getCatalog],
  catalog => catalog.subscriptionOptions
);

export const getCatalogProducts = createSelector(
  [getCatalog],
  catalog => catalog.shop
);

export const getCatalogOfferIds = createSelector(
  [getCatalogOffersArray],
  offers => offers.map(offer => offer.id)
);

export const getCatalogOptionIds = createSelector(
  [getCatalogOptions],
  options => options.map(option => option.id)
);

export const getCatalogProductIds = createSelector(
  [getCatalogProducts],
  products => products.map(product => product.id)
);

/**
 * Returns OAuth config
 *
 * @param {object} state
 * @returns {object}
 */
export const getOAuthConfig = createSelector(
  [getConfig],
  config => config.oauth
);

/**
 * Returns Api config
 *
 * @param {object} state
 * @returns {object}
 */
export const getApiConfig = createSelector(
  [getConfig],
  config => config.api
);

/**
 * Returns router config
 *
 * @param {object} state
 * @returns {object}
 */
export const getRouterConfig = createSelector(
  [getConfig],
  config => config.router
);

/**
 * Returns root URL
 *
 * @param {object} state
 * @returns {string}
 */
export const getRouterBaseUrl = createSelector(
  [getRouterConfig],
  routerConfig => routerConfig.baseUrl
);

/**
 * Returns root URL pattern
 *
 * @param {object} state
 * @returns {string}
 */
export const getRouterBaseUrlPattern = createSelector(
  [getRouterConfig],
  routerConfig => routerConfig.baseUrlPattern
);

/**
 * Returns router basename
 *
 * @param {object} state
 * @returns {string}
 */
export const getRouterBasename = createSelector(
  [getRouterConfig],
  routerConfig => routerConfig.basename
);

/**
 * Returns clientToken
 *
 * @param {object} state
 * @returns {string}
 */
export const getClientToken = createSelector(
  [getConfig],
  config => config.clientToken
);

/**
 * Returns a path with clientToken injected into
 *
 * @param {object} state
 * @param {{uri: string? }} props
 * @returns {string}
 */
export const getResolvedUri = (state, { uri = '' }) =>
  uri.replace(':clientToken', getClientToken(state));
