import React from 'react';
import { I18nProvider } from '@lingui/react';
import catalogFr from '../../locales/fr/messages';

const catalogs = { fr: catalogFr };

const withI18n = WrappedComponent => {
  const WithI18n = props => (
    <I18nProvider language="fr" catalogs={catalogs}>
      <WrappedComponent {...props} />
    </I18nProvider>
  );

  return WithI18n;
};

export default withI18n;
