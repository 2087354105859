import * as React from 'react';
import { SaleProcessContextConsumer } from './withSaleProcessProvider';

const withSaleProcess = WrappedComponent => {
  const WithSaleProcess = props => (
    <SaleProcessContextConsumer>
      {value => <WrappedComponent {...props} {...value} />}
    </SaleProcessContextConsumer>
  );

  return WithSaleProcess;
};

export default withSaleProcess;
