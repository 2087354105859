import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Grid, Modal, Typography, Icon } from '@stadline/react-ui-components';
import Layout from '../components/Layout';

function NotFound(props) {
  const { history } = props;
  const handleQuit = () => {
    history.push('/');
  };

  return (
    <Layout>
      <Modal maxWidth="md" open onClose={handleQuit}>
        <div style={{ padding: '50px' }}>
          <Grid spacing={40} container>
            <Grid item container xs={12} justify="center" alignItems="center">
              <Icon name="error" color="error" style={{ fontSize: '60px', marginRight: '10px' }} />
              <Typography variant="h1">Oups !</Typography>
            </Grid>
            <Grid item container xs={12} justify="center" alignItems="center">
              <Typography variant="h2">
                Impossible de reconnaitre l&apos;écran à afficher. Si ce problème persiste, nous te
                conseillons de te rendre à l’accueil du club.
              </Typography>
            </Grid>
            <Grid item container justify="center">
              <Button size="large" variant="contained" color="primary" onClick={handleQuit}>
                Quitter
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </Layout>
  );
}

NotFound.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(NotFound);
