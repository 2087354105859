import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ScrollTarget } from '@app/components';
import { FORM_SPACING, FORM_WIDTH } from '../../../consts';

// TODO: improve integration of the form

const UserFormPart = styled(ScrollTarget)`
  margin-right: ${FORM_SPACING}px;
  width: ${FORM_WIDTH}px;
  display: inline-block;
  vertical-align: top;
  box-sizing: content-box;
`;

UserFormPart.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserFormPart;
