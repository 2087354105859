/* eslint-disable import/prefer-default-export */

/**
 * @param {Number} amount
 * @returns {Number}
 */
export const monetize = amount =>
  (amount / 100)
    .toLocaleString('fr-FR', {
      currency: 'EUR',
      style: 'currency',
    })
    .replace(/,00/, '');
