import { createReducer } from 'redux-starter-kit';
import qs from 'qs'; // eslint-disable-line import/no-extraneous-dependencies
import moment from 'moment';
import { TEMP_CONTACT_LIST, CONTACT_LIST } from '@app/endpoints/contactApi';
import { GOAL_LIST, MOTIVATION_LIST } from '@app/endpoints/referentialApi';
import { OPTIN_LIST } from '@app/endpoints/communicationApi';
import { MANDATE_LIST } from '@app/endpoints/accountingApi';
import {
  getSaleId,
  getContactId,
  getGroupedOptins,
  getOptinStatus,
} from '../selectors/saleProcess';
import { getClubId } from '../selectors/config';
import { makeGetContact } from '../selectors/entities';
import { saveEntity, fetchEntityList, applyEntityTransition } from './entities';

export const initialState = {
  contactId: null,
  userInfos: {},
  isUserFormValid: false,
  picture: null,
  pictureList: [],
  isReferentialFetched: false,
  optinIds: [],
  skipMandate: false,
  mandateInfos: {},
  isMandateFormValid: false,
};

// ------------------------------------
// Constants
// ------------------------------------

const SET_CONTACT = 'saleProcess/SET_CONTACT';

const SET_PICTURE = 'saleProcess/SET_PICTURE';
const ADD_PICTURE = 'saleProcess/ADD_PICTURE';

const SET_USER_INFOS = 'saleProcess/SET_USER_INFOS';
const SET_USER_FORM_VALID = 'saleProcess/SET_USER_FORM_VALID';

const SET_MANDATE_INFOS = 'saleProcess/SET_MANDATE_INFOS';
const SET_MANDATE_FORM_VALID = 'saleProcess/SET_MANDATE_FORM_VALID';

const FETCH_REFERENTIAL_REQUEST = 'saleProcess/FETCH_REFERENTIAL_REQUEST';
const FETCH_REFERENTIAL_SUCCESS = 'saleProcess/FETCH_REFERENTIAL_SUCCESS';
const FETCH_REFERENTIAL_FAILURE = 'saleProcess/FETCH_CREFERENTIAL_FAILURE';

const SET_OPTINS_IDS = 'saleProcess/SET_OPTINS_ID';
const FETCH_OPTINS = 'saleProcess/FETCH_OPTINS';
const OPTINS_FETCHED = 'saleProcess/OPTINS_FETCHED';
const CHANGE_OPTIN = 'saleProcess/CHANGE_OPTIN';

const SET_SKIP_MANDATE = 'saleProcess/SET_SKIP_MANDATE';

const RESET = 'saleProcess/RESET';

export const COMMUNICATION_TYPES = ['news', 'partners'];

// ------------------------------------
// Action creators
// ------------------------------------

export const setContact = contactId => ({
  type: SET_CONTACT,
  payload: {
    contactId,
  },
});

export const setPicture = picture => ({
  type: SET_PICTURE,
  payload: {
    picture,
  },
});

export const addPicture = picture => ({
  type: ADD_PICTURE,
  payload: {
    picture,
  },
});

export const setUserInfos = userInfos => ({
  type: SET_USER_INFOS,
  payload: {
    userInfos,
  },
});

export const setUserFormValid = isUserFormValid => ({
  type: SET_USER_FORM_VALID,
  payload: {
    isUserFormValid,
  },
});

export const setMandateInfos = mandateInfos => ({
  type: SET_MANDATE_INFOS,
  payload: {
    mandateInfos,
  },
});

export const setMandateFormValid = isMandateFormValid => ({
  type: SET_MANDATE_FORM_VALID,
  payload: {
    isMandateFormValid,
  },
});

export const setSkipMandate = skipMandate => ({
  type: SET_SKIP_MANDATE,
  payload: {
    skipMandate,
  },
});

export const fetchReferential = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_REFERENTIAL_REQUEST });

  const { isReferentialFetched } = getState().saleProcess;

  try {
    if (!isReferentialFetched) {
      await dispatch(fetchEntityList(MOTIVATION_LIST));
      await dispatch(fetchEntityList(GOAL_LIST));
    }
    dispatch({ type: FETCH_REFERENTIAL_SUCCESS });
  } catch (error) {
    dispatch({ type: FETCH_REFERENTIAL_FAILURE, error });
  }
};

export const getTemporaryContact = () => async (dispatch, getState) => {
  const state = getState();

  const contactId = getContactId(state);

  if (contactId) return contactId;

  const temporaryContact = await dispatch(
    saveEntity(TEMP_CONTACT_LIST, { data: { clubId: getClubId(state) } })
  );

  await dispatch(setContact(temporaryContact.result.id));

  return temporaryContact.result.id;
};

export const checkExistingContact = data => async dispatch =>
  dispatch(fetchEntityList(`${CONTACT_LIST}?${qs.stringify(data)}`));

export const saveContact = data => async (dispatch, getState) => {
  const contactId = await dispatch(getTemporaryContact());
  const saleId = getSaleId(getState());

  try {
    await dispatch(saveEntity(contactId, { data }));
    let contactData = makeGetContact(contactId)(getState());
    if (contactData.state === 'temp') {
      await dispatch(applyEntityTransition(contactId, 'persist'));
    }
    contactData = makeGetContact(contactId)(getState());
    await dispatch(
      saveEntity(saleId, {
        data: {
          contactGivenName: contactData.givenName,
          contactFamilyName: contactData.familyName,
          contactNumber: contactData.number,
          address: contactData.address,
        },
      })
    );
  } catch (error) {
    throw error;
  }
};

export const saveMandate = ({ iban, bic, holder }) => async (dispatch, getState) => {
  const state = getState();
  const contactId = await dispatch(getTemporaryContact());
  const userInfos = makeGetContact(contactId)(state);

  try {
    await dispatch(
      saveEntity(MANDATE_LIST, {
        data: {
          contactId,
          contactGivenName: userInfos.givenName,
          contactFamilyName: userInfos.familyName,
          contactNumber: userInfos.number,
          contactAddress: { ...userInfos.address, addressCountry: 'France' },
          iban,
          bic,
          holder,
          clubId: getClubId(state),
          validFrom: moment().format('YYYY-MM-DD'),
          signedAt: moment().format('YYYY-MM-DD'),
        },
      })
    );
  } catch (error) {
    throw error;
  }
};

export const fetchOptins = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_OPTINS });

  const contactId = getContactId(getState());
  const { result } = await dispatch(
    fetchEntityList(OPTIN_LIST, {
      params: {
        contactId,
        type: COMMUNICATION_TYPES,
      },
    })
  );

  await dispatch({
    type: OPTINS_FETCHED,
    result,
  });
  return getOptinStatus(getState());
};

export const changeOptin = (optinType, optinValue) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_OPTIN });
  const groupedOptins = getGroupedOptins(getState());
  const optins = groupedOptins[optinType];

  try {
    await Promise.all(
      optins.map(optin =>
        dispatch(
          saveEntity(optin['@id'], {
            data: {
              isAuthorized: optinValue,
            },
          })
        )
      )
    );
    dispatch({ type: 'saleProcess/CHANGE_OPTIN_SUCCESS' });
  } catch (error) {
    dispatch({ type: 'saleProcess/CHANGE_OPTIN_ERROR', response: error.response });
  }
};

// ------------------------------------
// Handlers
// ------------------------------------

const handleSetContact = (state, { payload: { contactId } }) => ({ ...state, contactId });
const handleSetPicture = (state, { payload: { picture } }) => ({ ...state, picture });
const handleAddPicture = (state, { payload: { picture } }) => {
  if (state.pictureList.length >= 3) return state;
  return { ...state, pictureList: [...state.pictureList, picture] };
};
const handleSetUserInfos = (state, { payload: { userInfos } }) => ({ ...state, userInfos });
const handleSetUserFormValid = (state, { payload: { isUserFormValid } }) => ({
  ...state,
  isUserFormValid,
});

const handleSetMandateInfos = (state, { payload: { mandateInfos } }) => ({
  ...state,
  mandateInfos,
});
const handleSetMandateFormValid = (state, { payload: { isMandateFormValid } }) => ({
  ...state,
  isMandateFormValid,
});

const handleSetOptinIds = (prevState, { ids }) => ({
  ...prevState,
  optinIds: ids,
});

const handleOptinsFetched = (prevState, { result }) => ({
  ...prevState,
  optinIds: result,
});

const handleReset = () => initialState;

const handleSetSkipMandate = (state, { payload: { skipMandate } }) => ({
  ...state,
  skipMandate,
});

export default createReducer(initialState, {
  [SET_CONTACT]: handleSetContact,
  [SET_PICTURE]: handleSetPicture,
  [ADD_PICTURE]: handleAddPicture,
  [SET_USER_INFOS]: handleSetUserInfos,
  [SET_MANDATE_INFOS]: handleSetMandateInfos,
  [SET_USER_FORM_VALID]: handleSetUserFormValid,
  [SET_MANDATE_FORM_VALID]: handleSetMandateFormValid,
  [SET_OPTINS_IDS]: handleSetOptinIds,
  [OPTINS_FETCHED]: handleOptinsFetched,
  [RESET]: handleReset,
  [SET_SKIP_MANDATE]: handleSetSkipMandate,
});
