import * as React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { getClubId } from '@app/store/selectors/config';
import { getClub } from '@app/store/selectors/entities';
import { fetchEntity as fetchEntityFn } from '@app/store/reducers/entities';
import { fetchDeviceState as fetchDeviceStateFn } from '@app/store/reducers/device';
import { compose } from '../helpers';
import withSaleProcess from '../providers/withSaleProcess';
import Home from '../pages/Home';
import SaleProcess from '../pages/SaleProcess';
import NotFound from '../pages/NotFound';

class App extends React.Component {
  componentDidMount() {
    moment().locale('fr');

    const { clubId, fetchEntity, fetchDeviceState } = this.props;
    fetchEntity(clubId);
    fetchDeviceState();
    // dispatch fetch infos bornes ici
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/sale-process" component={SaleProcess} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  clubId: getClubId(state),
  club: getClub(state),
});

const mapActionCreator = {
  fetchEntity: fetchEntityFn,
  fetchDeviceState: fetchDeviceStateFn,
};

App.propTypes = {
  clubId: PropTypes.string,
  fetchEntity: PropTypes.func.isRequired,
  fetchDeviceState: PropTypes.func.isRequired,
};

App.defaultProps = {
  clubId: undefined,
};

const enhance = compose(
  withSaleProcess,
  connect(
    mapStateToProps,
    mapActionCreator
  )
);

export default enhance(App);
