import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, Grid, Icon } from '@stadline/react-ui-components';
import { Trans } from '@lingui/react';

const FormErrorsModal = ({ isOpen, onClose, errorId }) => (
  <Modal maxWidth="md" open={isOpen} onClose={onClose}>
    <div style={{ padding: '50px' }}>
      <Grid spacing={40} container>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Icon name="error" color="warning" style={{ fontSize: 70, marginRight: '16px' }} />
          <Typography variant="h2">
            <Trans id="form_errors_modal_title">Erreur lors de la complétion</Trans>
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h3">
            <Trans id={errorId} />
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Button variant="contained" color="primary" size="large" onClick={onClose}>
            <Trans id="form_errors_modal_button">Revenir au formulaire</Trans>
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
);

FormErrorsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  errorId: PropTypes.string,
};

FormErrorsModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  errorId: 'form_errors_modal_text',
};
export default FormErrorsModal;
