import { Machine } from 'xstate';
import saleProcessMachineStates from './saleProcessMachine';

const getSaleProcessStateMachine = (initial, context) => {
  const machine = Machine({
    initial,
    context,
    states: saleProcessMachineStates,
  });

  return machine;
};

export default getSaleProcessStateMachine;
