import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, Grid } from '@stadline/react-ui-components';

const ManateBypassModal = ({ isOpen, onSkipMandate }) => (
  <Modal maxWidth="md" open={isOpen}>
    <div style={{ padding: '50px' }}>
      <Grid spacing={40} container>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h1">Ton inscription chez Fitnesspark</Typography>
        </Grid>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h3">
            Afin de finaliser ton inscription, nous t&apos;invitons à présenter ton RIB auprès de
            l&apos;accueil dans un délai de 7 jours.
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Button variant="outlined" color="secondary" size="medium" onClick={onSkipMandate}>
            Continuer la souscription
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
);

ManateBypassModal.propTypes = {
  isOpen: PropTypes.bool,
  onSkipMandate: PropTypes.func.isRequired,
};

ManateBypassModal.defaultProps = {
  isOpen: false,
};
export default ManateBypassModal;
