import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SocketProvider } from 'socket.io-react';
import io from 'socket.io-client';
import { getSocketConfig } from '@app/store/selectors/config';

const withSocket = WrappedComponent => {
  class WithSocket extends Component {
    constructor(props, context) {
      super(props, context);

      // use redux state to retrieve socket configuration
      const { store } = this.context;
      const config = getSocketConfig(store.getState());

      const socket = io(config.baseUrl);
      socket.emit('joinRoom', config.room);
      this.socket = socket;
    }

    render() {
      return (
        <SocketProvider socket={this.socket}>
          <WrappedComponent {...this.props} />
        </SocketProvider>
      );
    }
  }

  WithSocket.contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func,
    }).isRequired,
  };

  return WithSocket;
};

export default withSocket;
