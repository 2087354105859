// eslint-disable-next-line import/prefer-default-export
export const withErrors = (touched, errors, fieldName, showError) => {
  if (errors[fieldName] && (showError || touched[fieldName])) {
    return { state: 'error', helperText: errors[fieldName] };
  }

  if (touched[fieldName] && !errors[fieldName]) {
    return { state: 'success', helperText: null };
  }

  return {};
};

export const withNestedErrors = (touched, errors, fieldGroup, fieldName, showError) => {
  const initialState = {
    state: null,
    helperText: null,
  };

  if (
    (!touched[fieldGroup] || !(touched[fieldGroup] && touched[fieldGroup][fieldName])) &&
    !showError
  )
    return initialState;
  if (
    (touched[fieldGroup] || (touched[fieldGroup] && touched[fieldGroup][fieldName])) &&
    (!errors[fieldGroup] || !errors[fieldGroup][fieldName])
  )
    return { state: 'success' };
  return {
    state: 'error',
    helperText: errors[fieldGroup] && errors[fieldGroup][fieldName],
  };
};
