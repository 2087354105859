import { schema } from 'normalizr';

export const MOTIVATION_LIST = '/:clientToken/referentials/motivations';
export const GOAL_LIST = '/:clientToken/referentials/goals';

const MOTIVATION_SCHEMA = new schema.Entity('motivations', {}, { idAttribute: '@id' });
const GOAL_SCHEMA = new schema.Entity('goals', {}, { idAttribute: '@id' });

export default {
  Motivation: MOTIVATION_SCHEMA,
  Goal: GOAL_SCHEMA,
};
