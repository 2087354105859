import React from 'react';
import PropTypes from 'prop-types';
import { SaleLayout as SaleLayoutBase, HorizontalScrollContainer } from '@app/components';
import { Grid } from '@stadline/react-ui-components';
import { compose } from '../helpers';
import withSaleProcess from '../providers/withSaleProcess';
import ShoppingBag from './ShoppingBag';

const SaleLayout = ({
  handleNextStep,
  handlePrevStep,
  navSteps,
  activeNavStep,
  title,
  children,
  withScroll,
  withShoppingBag,
  reset,
  ...props
}) => (
  <SaleLayoutBase
    title={title}
    prevAction={handlePrevStep}
    nextAction={handleNextStep}
    steps={navSteps}
    activeStep={activeNavStep}
    {...props}
  >
    <div style={{ overflow: 'hidden', flex: 1, display: 'flex' }} data-test="4">
      <Grid container spacing={40} style={{ overflow: 'auto' }}>
        <Grid item container xs={withShoppingBag ? 9 : 12} style={{ flex: 1 }} data-test="4">
          {withScroll ? (
            <HorizontalScrollContainer>{children}</HorizontalScrollContainer>
          ) : (
            children
          )}
        </Grid>
        {withShoppingBag && (
          <Grid item xs={3} style={{ position: 'relative' }}>
            <ShoppingBag />
          </Grid>
        )}
      </Grid>
    </div>
  </SaleLayoutBase>
);

SaleLayout.propTypes = {
  handleNextStep: PropTypes.func,
  handlePrevStep: PropTypes.func,
  navSteps: PropTypes.object,
  activeNavStep: PropTypes.number,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  withScroll: PropTypes.bool,
  withShoppingBag: PropTypes.bool,
};

SaleLayout.defaultProps = {
  handleNextStep: null,
  handlePrevStep: null,
  navSteps: {},
  activeNavStep: 0,
  title: '',
  withScroll: false,
  withShoppingBag: false,
};

const enhance = compose(withSaleProcess);

export default enhance(SaleLayout);
