import * as React from 'react';
import * as PropTypes from 'prop-types';

const StateMachineRouterContext = React.createContext();

const StateMachineRouterProvider = ({ store = {}, children }) => (
  <StateMachineRouterContext.Provider value={store.currentStep}>
    {children}
  </StateMachineRouterContext.Provider>
);

StateMachineRouterProvider.propTypes = {
  store: PropTypes.shape({
    currentStep: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export const StateMachineRouterConsumer = StateMachineRouterContext.Consumer;

export default StateMachineRouterProvider;
