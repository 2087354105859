import { schema } from 'normalizr';

export const OFFERS_LIST = '/:clientToken/offers';

const OFFER_SCHEMA = new schema.Entity('offers', {}, { idAttribute: '@id' });
const PRODUCT_SCHEMA = new schema.Entity('products', {}, { idAttribute: '@id' });

OFFER_SCHEMA.define({
  product: PRODUCT_SCHEMA,
});

PRODUCT_SCHEMA.define({
  offers: [OFFER_SCHEMA],
});

export default {
  Offer: OFFER_SCHEMA,
  'http://schema.org/Product': PRODUCT_SCHEMA,
};
