import React from 'react';
import PropTypes from 'prop-types';
import PDF from 'react-pdf-js-infinite';
import { Modal, Icon, IconButton, ScrollContainer } from '@stadline/react-ui-components';
import styled from 'styled-components';

const ContractScrollContainer = styled(ScrollContainer)`
  width: 100%;
  height: 400px;
  border: 2px solid #ccc;
`;

class ContractViewer extends React.Component {
  state = {
    fullScreen: false,
  };

  render() {
    const { file } = this.props;
    const { fullScreen } = this.state;
    return (
      <>
        <Modal maxWidth="lg" open={fullScreen} onClose={() => this.setState({ fullScreen: false })}>
          <IconButton
            onClick={() => this.setState({ fullScreen: false })}
            style={{ alignSelf: 'flex-end' }}
          >
            <Icon name="close" />
          </IconButton>
          <ScrollContainer style={{ width: '100%', height: '90vh' }}>
            {file && <PDF file={file} scale={1.6} />}
          </ScrollContainer>
        </Modal>

        {file && (
          <>
            <IconButton onClick={() => this.setState({ fullScreen: true })}>
              <Icon name="fullscreen" />
            </IconButton>
            <ContractScrollContainer>
              <PDF file={file} scale={1} />
            </ContractScrollContainer>
          </>
        )}
      </>
    );
  }
}

ContractViewer.propTypes = {
  file: PropTypes.string,
};

ContractViewer.defaultProps = {
  file: null,
};

export default ContractViewer;
