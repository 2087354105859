import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SelectField } from '@stadline/react-ui-components';

class YearSelector extends React.Component {
  constructor(props) {
    super(props);
    const { minDate, maxDate } = props;
    const years = [];
    for (let y = moment(minDate).year(); y <= moment(maxDate).year(); y += 1) {
      years.push({
        id: y,
        label: y,
      });
    }
    this.state = {
      years,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { minDate, maxDate } = this.props;
    if (minDate !== nextProps.minDate || maxDate !== nextProps.maxDate) {
      const years = [];
      for (
        let y = moment(nextProps.minDate).year();
        y <= moment(nextProps.maxDate).year();
        y += 1
      ) {
        years.push({
          id: y,
          label: y,
        });
      }
      this.setState({
        years,
      });
    }
  }

  render() {
    const { required, value, onChange } = this.props;
    const { years } = this.state;
    return (
      <SelectField
        required={required}
        name="year"
        label="Année"
        options={years.reverse()}
        onChange={onChange}
        value={value}
      />
    );
  }
}

YearSelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

YearSelector.defaultProps = {
  value: null,
  required: false,
};

export default YearSelector;
