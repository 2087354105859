import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, Grid, Icon } from '@stadline/react-ui-components';

const PaymentFailedModal = ({ title, message, isOpen, onRetry, onCancel }) => (
  <Modal maxWidth="md" open={isOpen} onClose={onCancel}>
    <div style={{ padding: '50px' }}>
      <Grid spacing={40} container>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Icon name="error" color="warning" style={{ fontSize: 70, marginRight: '16px' }} />
          <Typography variant="h1">{title}</Typography>
        </Grid>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h3">{message}</Typography>
        </Grid>
        <Grid item container xs={6} justify="center">
          <Button variant="outlined" color="secondary" size="medium" onClick={onCancel}>
            Annuler la transaction
          </Button>
        </Grid>
        <Grid item container xs={6} justify="flex-end">
          <Button variant="contained" color="primary" size="medium" onClick={onRetry}>
            Recommencer
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
);

PaymentFailedModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PaymentFailedModal.defaultProps = {
  title: '',
  message: '',
};

export default PaymentFailedModal;
