import * as React from 'react';
import * as PropTypes from 'prop-types';
import saleProcessMachine from '@app/store/reducers/saleProcess/saleProcessMachine';

import { StateMachineRouterConsumer } from './StateMachineRouterProvider';

const StateMachineRoute = ({ value: stateValue, component }) => {
  const RouteComponent = component;

  return (
    <StateMachineRouterConsumer>
      {value => (value === stateValue ? <RouteComponent /> : null)}
    </StateMachineRouterConsumer>
  );
};

StateMachineRoute.propTypes = {
  value: PropTypes.oneOf(Object.keys(saleProcessMachine)),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

StateMachineRoute.defaultProps = {
  value: undefined,
};

export default StateMachineRoute;
