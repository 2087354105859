import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@stadline/react-ui-components';
import { makeGetOffer } from '@app/store/selectors/entities';
import { AlertMessage } from '@app/components';
import { getSelectedOfferId } from '@app/store/selectors/saleProcess';
import SaleLayout from '../../components/SaleLayout';
import ShoppingBag from '../../components/ShoppingBag';
import { getIsRecurrent } from '../../helpers';

const Summary = ({ isRecurrent }) => (
  <SaleLayout title="Récapitulatif">
    <Grid container spacing={40}>
      <Grid item xs={3} />
      <Grid item xs={6}>
        {isRecurrent ? (
          <AlertMessage
            message="Tu as choisi un abonnement à prélèvement mensuel. Un relevé d'identité bancaire (RIB/IBAN) te sera demandé pour valider ton inscription.
            Tu ne les as pas sous la main ?
            Aucun souci, tu pourras les apporter sous 7 jours à l'accueil de ton club pour finaliser ton inscription."
          />
        ) : null}
        <ShoppingBag padding={100} edit />
      </Grid>
      <Grid item xs={3} />
    </Grid>
  </SaleLayout>
);

Summary.propTypes = {
  isRecurrent: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const selectedOfferId = getSelectedOfferId(state);
  const offer = makeGetOffer(selectedOfferId)(state);
  return {
    isRecurrent: getIsRecurrent(offer),
  };
};

export default connect(mapStateToProps)(Summary);
