import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';

ReactDOM.render(<App />, document.querySelector('#root'));

if (module.hot) {
  module.hot.accept('./app', () => {
    ReactDOM.render(<App />, document.querySelector('#root'));
  });
}
