import { createSelector } from 'redux-starter-kit';
import jwtDecode from 'jwt-decode';
import { getUnixTimestamp } from './time';

/**
 * Returns all authentication informations
 *
 * @param {Object} state
 * @returns {Object}
 */
export const getAuthentication = state => state.authentication;

/**
 * Returns the access token
 *
 * @param {Object} state
 * @returns {String}
 */
export const getAccessToken = createSelector(
  [getAuthentication],
  authentication => authentication.access_token
);

/**
 * Returns the refresh token
 *
 * @param {Object} state
 * @returns {String}
 */
export const getRefreshToken = createSelector(
  [getAuthentication],
  authentication => authentication.refresh_token
);

/**
 * Decodes the access token (which is a JWT)
 *
 * @param {Object} state
 * @returns {{ exp: Number, targetId: String }}
 */
export const getJwtContent = createSelector(
  [getAccessToken],
  accessToken => {
    try {
      return jwtDecode(accessToken);
    } catch (error) {
      return {};
    }
  }
);

/**
 * Decodes the access token (which is a JWT) and return targetId
 *
 * @param {Object} state
 * @returns {String}
 */
export const getTargetId = createSelector(
  [getJwtContent],
  jwtContent => jwtContent.targetId
);

/**
 * Checks if the access token expiration is not reached
 *
 * @param {Object} state
 * @returns {Boolean}
 */
export const getIsAccessTokenValid = createSelector(
  [getJwtContent, getUnixTimestamp],
  (jwtContent, now) => {
    const offset = 600; // 10 minutes
    return jwtContent.exp > now + offset;
  }
);

/**
 * Checks if the authentication data can be used has a valid user session
 *
 * @param {Object} state
 * @returns {Boolean}
 */
export const getIsAuthenticated = createSelector(
  [getAuthentication],
  authentication => Boolean(authentication.validated)
);
