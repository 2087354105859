import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SelectField } from '@stadline/react-ui-components';
import { getMotivationList } from '@app/store/selectors/entities';

const MotivationSelector = ({ motivations, onChange, value, onBlur, required, name }) => (
  <SelectField
    name={name}
    required={required}
    label="Motivation"
    options={motivations.map(motivation => ({
      id: motivation['@id'],
      label: motivation.name,
    }))}
    onBlur={onBlur}
    onChange={onChange}
    value={value || ''}
  />
);

const mapStateToProps = state => ({
  motivations: getMotivationList(state),
});

MotivationSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  motivations: PropTypes.array,
  required: PropTypes.bool,
  name: PropTypes.string,
};

MotivationSelector.defaultProps = {
  value: null,
  motivations: [],
  required: false,
  name: '',
};

const enhance = connect(mapStateToProps);

export default enhance(MotivationSelector);
