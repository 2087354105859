import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@stadline/react-ui-components';
import { GRID_SPACING } from '../consts';

const StyledImg = styled('img')`
  border-color: ${props => props.theme.palette.primary.main};
  border-width: 5px;
  border-style: ${({ selected, picture }) => (selected === picture ? 'solid' : 'none')};
`;

const PictureGallery = ({ pictures, onSelectPicture, selected }) => (
  <Grid container spacing={GRID_SPACING}>
    {pictures.map(picture => (
      <Grid item xs={6}>
        <StyledImg
          key={picture}
          onClick={() => onSelectPicture(picture)}
          src={picture}
          selected={selected}
          picture={picture}
          style={{ width: '100%', height: 'auto' }}
          alt="essai"
        />
      </Grid>
    ))}
  </Grid>
);

PictureGallery.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.string,
  onSelectPicture: PropTypes.func,
};

PictureGallery.defaultProps = {
  pictures: [],
  selected: null,
  onSelectPicture: () => {},
};

export default PictureGallery;
