import { makeApiClient } from '@app/endpoints';
import { getApiConfig } from '../selectors/config';
import { refreshAccessToken } from './authentication';

const sendHTTPRequest = requestConfig => async (dispatch, getState) => {
  const apiConfig = getApiConfig(getState());
  const accessToken = await dispatch(refreshAccessToken());

  const configuration = {
    baseURL: apiConfig.baseUrl,
    ...requestConfig,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...requestConfig.headers,
    },
  };

  dispatch({ type: '@@HTTP_REQUEST', ...configuration });

  const client = makeApiClient();
  const httpResponse = await client.request(configuration);

  return httpResponse;
};

export default sendHTTPRequest;
