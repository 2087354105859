import React from 'react';
import logo from './logo.svg';

const Logo = () => (
  <div>
    <img style={{ width: '228px' }} src={logo} alt="logo" />
  </div>
);

export default Logo;
