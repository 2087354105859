import * as React from 'react';
import * as PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRouterBasename, getRouterBaseUrlPattern } from '@app/store/selectors/config';

const mapStateToProps = state => ({
  basename: getRouterBasename(state),
  baseUrlPattern: getRouterBaseUrlPattern(state),
});

const ErrorComponent = ({ pattern }) => (
  <div>
    <h1>Invalid base URL</h1>
    <p>
      The URL must match the following pattern:
      <br />
      <code>{pattern}</code>
    </p>
  </div>
);

ErrorComponent.propTypes = {
  pattern: PropTypes.string.isRequired,
};

const withRouter = WrappedComponent => {
  const WithRouterProvider = ({ basename, baseUrlPattern }) => {
    if (!matchPath(document.location.href, { path: baseUrlPattern })) {
      return <ErrorComponent pattern={baseUrlPattern} />;
    }

    return (
      <BrowserRouter basename={basename}>
        <Route component={WrappedComponent} />
      </BrowserRouter>
    );
  };

  WithRouterProvider.propTypes = {
    basename: PropTypes.string, // eslint-disable-line react/require-default-props
    baseUrlPattern: PropTypes.string.isRequired,
  };

  return connect(
    mapStateToProps,
    null,
    null,
    { pure: false }
  )(WithRouterProvider);
};

export default withRouter;
