import { createReducer } from 'redux-starter-kit';
import moment from 'moment';
import * as towerApi from '@app/tower-api';
import { CLUB_LIST } from '@app/endpoints/clubApi';
import { SALE_LIST, ARTICLE_LIST, ARTICLE_WITH_BEHAVIOR } from '@app/endpoints/saleApi';
import { IDENTIFICATOR_LIST } from '@app/endpoints/accessControlApi';
import { TRANSACTIONS } from '@app/endpoints';
import { getFirst, getIsRecurrent } from 'app/helpers'; // eslint-disable-line import/no-extraneous-dependencies
import { getClub, makeGetOffer, makeGetProduct } from '../selectors/entities';
import {
  getSaleId,
  getTransactionId,
  getSale,
  getOffers,
  makeGetArticles,
  getSelectedOfferId,
  getSaleProcessContext,
  getTotalOnregistration,
} from '../selectors/saleProcess';

import { getCatalogOffersArray, getCatalog } from '../selectors/config';
import getSaleProcessStateMachine from './saleProcess/getSaleProcessStateMachine';
import { PREV, NEXT } from './saleProcess/actions';
import { getTemporaryContact } from './saleProcessUser';
import { fetchEntity, saveEntity, fetchEntityList, deleteEntity } from './entities';
import { triggerFetchGrootConfig } from './config';

export const initialState = {
  currentStep: 'offers',
  offersMode: 'monthly',
  offerMonthlyDuration: null,
  isCatalogFetched: false,
  selectedOffer: null,
  selectedOptions: [],
  selectedProducts: [],
  articleIds: [],
  saleId: null,
  transactionId: null,
  contactId: null,
  userInfos: {},
  navSteps: {
    '0': 'Je choisis ma formule',
    '1': 'Je renseigne mon contrat',
    '2': 'Je paie et récupère ma carte',
  },
  isProcessFinished: false,
  totalOnregistration: null,
  isLoadingArticles: false,
  isRecurrent: false,
};

// ------------------------------------
// Constants
// ------------------------------------

const RESET = 'saleProcess/RESET';

const FETCH_CATALOG_REQUEST = 'saleProcess/FETCH_CATALOG_REQUEST';
const FETCH_CATALOG_SUCCESS = 'saleProcess/FETCH_CATALOG_SUCCESS';
const FETCH_CATALOG_FAILURE = 'saleProcess/FETCH_CATALOG_FAILURE';

const SET_SALE = 'saleProcess/SET_SALE';
const SET_TRANSACTION = 'saleProcess/SET_TRANSACTION';
const SET_SELECTED_OFFER = 'saleProcess/SET_SELECTED_OFFER';
const SET_SELECTED_OPTION = 'saleProcess/SET_SELECTED_OPTION';
const RESET_SELECTED_OPTIONS = 'saleProcess/RESET_SELECTED_OPTIONS';
const SET_SELECTED_PRODUCT = 'saleProcess/SET_SELECTED_PRODUCT';
const SET_OFFERS_MODE = 'saleProcess/SET_OFFERS_MODE';
const SET_OFFER_DURATION = 'saleProcess/SET_OFFER_DURATION';
const SET_ARTICLE_IDS = 'saleProcess/SET_ARTICLE_IDS';
const SET_TOTAL_ON_REGISTRATION = 'saleProcess/SET_TOTAL_ON_REGISTRATION';
const SET_IS_RECURRENT = 'saleProcess/SET_IS_RECURRENT';

const SET_PROCESS_FINISH = 'saleProcess/SET_PROCESS_FINISH';

// ------------------------------------
// Action creators
// ------------------------------------

export const fetchCatalog = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_CATALOG_REQUEST });
  await dispatch(triggerFetchGrootConfig());

  const { subscriptionOptions, shop } = getCatalog(getState());
  const offers = getCatalogOffersArray(getState());
  const itemList = [].concat(offers, subscriptionOptions, shop);

  try {
    const getItemPromiseList = () => itemList.map(item => dispatch(fetchEntity(item.id)));
    await Promise.all(getItemPromiseList(), dispatch(fetchEntityList(CLUB_LIST)));
    dispatch({ type: FETCH_CATALOG_SUCCESS });
  } catch (error) {
    dispatch({ type: FETCH_CATALOG_FAILURE, error });
  }
};

export const reset = () => async (dispatch, getState) => {
  const state = getState();

  const sale = getSale(state);
  if (sale && sale.state === 'cart') {
    await dispatch(deleteEntity(sale['@id']));
  }
  await dispatch({ type: RESET });
};

export const setIsProcessFinished = isProcessFinished => ({
  type: SET_PROCESS_FINISH,
  payload: { isProcessFinished },
});

export const setTotalOnRegistration = totalOnregistration => ({
  type: SET_TOTAL_ON_REGISTRATION,
  payload: { totalOnregistration },
});

export const setTransaction = transactionId => ({
  type: SET_TRANSACTION,
  payload: {
    transactionId,
  },
});

export const setArticleIds = articleIds => ({
  type: SET_ARTICLE_IDS,
  payload: {
    articleIds,
  },
});

export const setIsRecurrent = isRecurrent => ({
  type: SET_IS_RECURRENT,
  payload: {
    isRecurrent,
  },
});

export const getProcessSale = () => async (dispatch, getState) => {
  const state = getState();

  let saleId = getSaleId(state);
  if (saleId) return saleId;

  const contactId = await dispatch(getTemporaryContact());
  const club = getClub(state);

  const sale = await dispatch(
    saveEntity(SALE_LIST, { data: { contactId, clubId: club['@id'], clubCode: club.code } })
  );
  saleId = sale.result.id;

  await dispatch({ type: SET_SALE, payload: { saleId } });
  return saleId;
};

export const getProcessTransaction = () => async (dispatch, getState) => {
  const state = getState();

  const sale = await dispatch(getProcessSale());

  let transactionId = getTransactionId(state);
  if (transactionId) return transactionId;

  const transaction = await dispatch(
    saveEntity(TRANSACTIONS, {
      data: {
        sale,
      },
    })
  );
  transactionId = transaction.result['@id'];

  await dispatch({ type: SET_TRANSACTION, payload: { transactionId } });
  return transactionId;
};

export const getArticleIds = () => async dispatch => {
  const saleId = await dispatch(getProcessSale());
  const articles = await dispatch(
    fetchEntityList(ARTICLE_LIST, {
      params: {
        sale: saleId,
      },
    })
  );

  dispatch(setArticleIds(articles.result.map(a => a.id)));
};

const calculTotalOnregistration = () => async (dispatch, getState) => {
  const saleId = await dispatch(getProcessSale());
  if (saleId) {
    await dispatch(fetchEntity(saleId));
    const state = getState();
    const sale = getSale(state);

    dispatch(setTotalOnRegistration(sale.totalTI));
  }
};

// User selects an offer. We have to update his sale
export const selectOffer = offerId => async (dispatch, getState) => {
  dispatch({
    type: 'LOADING_ARTICLES_REQUEST',
  });

  const state = getState();
  const club = getClub(state);

  await dispatch({
    type: SET_SELECTED_OFFER,
    payload: { offerId },
  });

  await dispatch({
    type: RESET_SELECTED_OPTIONS,
  });

  // We have to know if above dispatch selected or unselected the offer
  const selectedOffer = getSelectedOfferId(getState());

  /**
   * We should check if a sale exists, because all is related to the sale
   * So if it does not exist, we should create it
   */
  const saleId = await dispatch(getProcessSale());

  /**
   * Now that we have a sale, we should check if we have an offer in this sale
   *
   * To do so, we have to get all sale articles, and find if one of them have the offerId.
   */

  /**
   * WARNING ! We cannot have two offers in this case.
   * So if one offer was already in the cart, we should delete it
   */

  const allOffers = getOffers(state);

  const saleArticleList = await dispatch(
    fetchEntityList(ARTICLE_LIST, {
      params: {
        sale: saleId,
      },
    })
  );

  const saleArticleIds = saleArticleList.result.map(i => i.id);
  const saleArticles = makeGetArticles(saleArticleIds)(state);

  const offerArticleIdList = saleArticles
    .filter(article => article)
    .reduce((articles, saleArticle) => {
      if (allOffers.map(offer => offer['@id']).includes(saleArticle.offerId)) {
        articles.push(saleArticle['@id']);
      }
      return articles;
    }, []);

  if (offerArticleIdList.length > 0) {
    /**
     * Offer article is already existing. We should remove it
     */
    const getDeletePromiseList = async () =>
      Promise.all(offerArticleIdList.map(offerArticleId => dispatch(deleteEntity(offerArticleId))));
    await getDeletePromiseList();
  }

  if (selectedOffer) {
    /**
     * We don't have any offer in the sale, so we can create it
     */
    const offer = makeGetOffer(offerId)(state);

    const product = makeGetProduct(offer.product)(state);
    const productBehavior = getFirst(product.productBehaviors);
    const { behavior: behaviorId, ...behavior } = productBehavior; // on doit transformer la key behavior en behaviorID, sinon on a une 500 sur l'api
    const contactId = await dispatch(getTemporaryContact());

    const startDate = moment().format('YYYY-MM-DD');

    await dispatch(
      saveEntity(ARTICLE_WITH_BEHAVIOR, {
        data: {
          sale: saleId,
          offerName: offer.name,
          offerId,
          registrationFeeTI: offer.registrationFeeTI,
          productId: product['@id'],
          productName: product.name,
          productType: product.type,
          priceTI: offer.priceTI,
          priceCurrency: offer.priceCurrency,
          taxRate: offer.taxRate,
          clubId: club['@id'],
          repaymentSchedule: {
            ...offer.repaymentSchedule,
            startDate,
          },
          articleBehaviors: [
            {
              ...{
                ...behavior,
                behaviorId,
              },
              implementation: {
                contactId,
                startDate,
              },
            },
          ],
        },
      })
    );
    dispatch(setIsRecurrent(getIsRecurrent(offer)));
  }

  await dispatch(fetchEntity(saleId)); // update sale for contract

  await dispatch(getArticleIds());

  dispatch({
    type: 'LOADING_ARTICLES_SUCCESS',
  });
  dispatch(calculTotalOnregistration());
};

export const selectOption = optionId => async (dispatch, getState) => {
  dispatch({
    type: 'LOADING_ARTICLES_REQUEST',
  });

  const state = getState();
  const club = getClub(state);

  await dispatch({
    type: SET_SELECTED_OPTION,
    payload: {
      optionId,
    },
  });

  // We have to know if above dispatch selected or unselected the offer
  // const selectedOptions = getSelectedOptionIds(getState());

  const saleId = await dispatch(getProcessSale());

  // const allOptions = getOptions(state);

  const saleArticleList = await dispatch(
    fetchEntityList(ARTICLE_LIST, {
      params: {
        sale: saleId,
      },
    })
  );

  const saleArticleIds = saleArticleList.result.map(i => i.id);
  const saleArticles = makeGetArticles(saleArticleIds)(state);
  const allOffers = getOffers(state);

  // si optionId pas present dans saleArticleIds => create
  const subscriptionArticleIdList = saleArticles
    .filter(article => article)
    .reduce((articles, saleArticle) => {
      if (allOffers.map(offer => offer['@id']).includes(saleArticle.offerId)) {
        articles.push(saleArticle['@id']);
      }
      return articles;
    }, []);

  const optionArticle = saleArticles
    .filter(saleArticle => saleArticle)
    .find(saleArticle => saleArticle.offerId === optionId);
  // const optionIsInSale = saleArticles.map(saleArticle => saleArticle.productId).includes(optionId);

  if (!optionArticle) {
    const offer = makeGetOffer(optionId)(state);

    const contactId = await dispatch(getTemporaryContact());
    const product = makeGetProduct(offer.product)(state);
    const productBehavior = getFirst(product.productBehaviors);
    const { behavior: behaviorId, ...behavior } = productBehavior;
    const startDate = moment().format('YYYY-MM-DD');

    await dispatch(
      saveEntity(ARTICLE_WITH_BEHAVIOR, {
        data: {
          sale: saleId,
          offerName: offer.name,
          offerId: offer['@id'],
          productId: product['@id'],
          productName: product.name,
          productType: product.type,
          priceTI: offer.priceTI,
          priceCurrency: offer.priceCurrency,
          taxRate: offer.taxRate,
          clubId: club['@id'],
          parent: subscriptionArticleIdList[0],
          articleBehaviors: [
            {
              ...{
                ...behavior,
                behaviorId,
              },
              implementation: {
                startDate,
                ...(product.productType === 'subscription_option'
                  ? { subscriptionArticleId: subscriptionArticleIdList[0] }
                  : { contactId }),
              },
            },
          ],
        },
      })
    );
  } else {
    await dispatch(deleteEntity(optionArticle['@id']));
  }
  await dispatch(getArticleIds());
  dispatch(calculTotalOnregistration());

  dispatch({
    type: 'LOADING_ARTICLES_SUCCESS',
  });
};

export const selectProduct = productId => async (dispatch, getState) => {
  dispatch({
    type: 'LOADING_ARTICLES_REQUEST',
  });

  const state = getState();
  const club = getClub(state);

  await dispatch({
    type: SET_SELECTED_PRODUCT,
    payload: {
      productId,
    },
  });

  // We have to know if above dispatch selected or unselected the offer
  // const selectedOptions = getSelectedOptionIds(getState());

  const saleId = await dispatch(getProcessSale());

  // const allOptions = getOptions(state);

  const saleArticleList = await dispatch(
    fetchEntityList(ARTICLE_LIST, {
      params: {
        sale: saleId,
      },
    })
  );

  const saleArticleIds = saleArticleList.result.map(i => i.id);
  const saleArticles = makeGetArticles(saleArticleIds)(state);

  const optionArticle = saleArticles
    .filter(saleArticle => saleArticle)
    .find(saleArticle => saleArticle.offerId === productId);
  // const optionIsInSale = saleArticles.map(saleArticle => saleArticle.productId).includes(productId);

  if (!optionArticle) {
    const offer = makeGetOffer(productId)(state);
    const product = makeGetProduct(offer.product)(state);

    await dispatch(
      saveEntity(ARTICLE_WITH_BEHAVIOR, {
        data: {
          sale: saleId,
          offerName: offer.name,
          offerId: offer['@id'],
          productId: product['@id'],
          productName: product.name,
          productType: product.type,
          priceTI: offer.totalPriceTI,
          priceCurrency: offer.priceCurrency,
          taxRate: offer.taxRate,
          clubId: club['@id'],
        },
      })
    );
  } else {
    await dispatch(deleteEntity(optionArticle['@id']));
  }
  await dispatch(getArticleIds());
  dispatch(calculTotalOnregistration());

  dispatch({
    type: 'LOADING_ARTICLES_SUCCESS',
  });
};

export const deleteArticle = articleId => async (dispatch, getState) => {
  // await dispatch(getArticleIds());
  dispatch({
    type: 'LOADING_ARTICLES_REQUEST',
  });

  const state = getState();
  const { selectedOptions, selectedProducts } = state.saleProcess;
  const [article] = makeGetArticles([articleId])(state);

  const optionId = selectedOptions.find(optionOfferId => optionOfferId === article.offerId);
  const productId = selectedProducts.find(productOfferId => productOfferId === article.offerId);

  if (optionId) {
    await dispatch(selectOption(optionId));
  } else if (productId) {
    await dispatch(selectProduct(productId));
  } else await dispatch(deleteEntity(articleId));
  dispatch(calculTotalOnregistration());

  dispatch({
    type: 'LOADING_ARTICLES_SUCCESS',
  });
};

export const requestPayment = () => async (dispatch, getState) => {
  const transaction = await dispatch(getProcessTransaction());
  const TransactionNumber = transaction.match(/\d+/g)[0]; // en attendnt d'avoir un vrai transaction number
  const AmountToPay = getTotalOnregistration(getState());
  towerApi.requestPayment({ AmountToPay, TransactionNumber });
};

export const distributeCard = () => async dispatch => {
  const { data: cardData } = await towerApi.distributeCard();

  if (cardData && cardData.CardUID && cardData.CardUID !== 'None') {
    const contactId = await dispatch(getTemporaryContact());
    await dispatch(
      saveEntity(IDENTIFICATOR_LIST, {
        data: {
          number: cardData.CardUID,
          targetId: contactId,
          isActivated: true,
          type: 'badge',
        },
      })
    );
  }
  return cardData;
};

export const switchOffersMode = offersMode => ({
  type: SET_OFFERS_MODE,
  payload: {
    offersMode,
  },
});

export const setOfferDuration = offerMonthlyDuration => ({
  type: SET_OFFER_DURATION,
  payload: {
    offerMonthlyDuration,
  },
});

export const gotoPrevStep = (params = {}) => (dispatch, getState) =>
  dispatch({
    type: PREV,
    payload: {
      params,
      context: getSaleProcessContext(getState()),
    },
  });

export const gotoNextStep = (params = {}) => (dispatch, getState) =>
  dispatch({
    type: NEXT,
    payload: {
      params,
      context: getSaleProcessContext(getState()),
    },
  });

// ------------------------------------
// Handlers
// ------------------------------------

const handleReset = () => initialState;

const handleOfferSelected = (state, action) => ({
  ...state,
  selectedOffer: state.selectedOffer === action.payload.offerId ? null : action.payload.offerId,
});

const handleOptionSelect = (state, { payload: { optionId } }) => {
  const optionIndex = state.selectedOptions.indexOf(optionId);

  if (optionIndex > -1) {
    state.selectedOptions.splice(optionIndex, 1);
  } else {
    state.selectedOptions.push(optionId);
  }
};

const handleResetSelectedOptions = state => {
  const nextState = { ...state };
  nextState.selectedOptions = [];

  return nextState;
};

const handleProductSelect = (state, { payload: { productId } }) => {
  const productIndex = state.selectedProducts.indexOf(productId);

  if (productIndex > -1) {
    state.selectedProducts.splice(productIndex, 1);
  } else {
    state.selectedProducts.push(productId);
  }
};

export const handleGotoNextStep = (state, { payload: { params, context } }) => {
  const sm = getSaleProcessStateMachine(state.currentStep, context);

  const trans = sm.transition(state.currentStep, { type: NEXT, params });

  const nextStep = trans.value;

  return { ...state, currentStep: nextStep };
};

export const handleGotoPrevStep = (state, { payload: { params, context } }) => {
  const sm = getSaleProcessStateMachine(state.currentStep, context);

  const trans = sm.transition(state.currentStep, { type: PREV, params });

  const nextStep = trans.value;

  return { ...state, currentStep: nextStep };
};

const handleSetTransaction = (state, { payload: { transactionId } }) => ({
  ...state,
  transactionId,
});

const handleSetSale = (state, { payload: { saleId } }) => ({ ...state, saleId });

const handleSetOffersMode = (state, { payload: { offersMode } }) => ({ ...state, offersMode });
const handleSetOfferDuration = (state, { payload: { offerMonthlyDuration } }) => ({
  ...state,
  offerMonthlyDuration,
});

const handleSetArticleIds = (state, { payload: { articleIds } }) => ({ ...state, articleIds });

const handleSetIsProcessFinished = (state, { payload: { isProcessFinished } }) => ({
  ...state,
  isProcessFinished,
});

const handleSetTotalOnRegistration = (state, { payload: { totalOnregistration } }) => ({
  ...state,
  totalOnregistration,
});

const handleLoadingArticlesRequest = state => ({
  ...state,
  isLoadingArticles: true,
});

const handleLoadingArticlesSuccess = state => ({
  ...state,
  isLoadingArticles: false,
});

const handleSetIsRecurrent = (state, { payload: { isRecurrent } }) => ({
  ...state,
  isRecurrent,
});

export default createReducer(initialState, {
  [RESET]: handleReset,
  [SET_SALE]: handleSetSale,
  [SET_TRANSACTION]: handleSetTransaction,
  [SET_OFFERS_MODE]: handleSetOffersMode,
  [SET_OFFER_DURATION]: handleSetOfferDuration,
  [SET_SELECTED_OFFER]: handleOfferSelected,
  [SET_SELECTED_OPTION]: handleOptionSelect,
  [RESET_SELECTED_OPTIONS]: handleResetSelectedOptions,
  [SET_SELECTED_PRODUCT]: handleProductSelect,
  [SET_ARTICLE_IDS]: handleSetArticleIds,
  [SET_PROCESS_FINISH]: handleSetIsProcessFinished,
  [SET_TOTAL_ON_REGISTRATION]: handleSetTotalOnRegistration,
  [SET_IS_RECURRENT]: handleSetIsRecurrent,
  [NEXT]: handleGotoNextStep,
  [PREV]: handleGotoPrevStep,
  LOADING_ARTICLES_REQUEST: handleLoadingArticlesRequest,
  LOADING_ARTICLES_SUCCESS: handleLoadingArticlesSuccess,
});
