export const compose = (...fns) => fns.reduce((f, g) => (...args) => f(g(...args)));

export const iso8601ToObject = iso8601 => {
  const extract = thing => (thing ? Number(thing[0][0]) : undefined);

  return {
    years: extract(iso8601.match(/\d+Y/)),
    months: extract(iso8601.match(/\d+M/)),
    days: extract(iso8601.match(/\d+D/)),
  };
};

export const getFirst = (array = []) => array[0];
export const getLast = (array = []) => array[array.length - 1];
export const extractOffset = offset => {
  if (!offset) return null;
  const extractedOffset = offset.match(/\d+M/);
  return extractedOffset && parseInt(getFirst(extractedOffset), 10);
};

export const getOccurenceDuration = occurence => extractOffset(occurence.offset) + occurence.loop;

export const getDuration = articleOrOffer => {
  if (articleOrOffer.articleBehaviors && articleOrOffer.articleBehaviors.length > 0) {
    return extractOffset(getFirst(articleOrOffer.articleBehaviors).configuration.engagementPeriod);
  }

  if (
    articleOrOffer.product &&
    articleOrOffer.product.productBehaviors &&
    articleOrOffer.product.productBehaviors.length > 0
  ) {
    return extractOffset(
      getFirst(articleOrOffer.product.productBehaviors).configuration.engagementPeriod
    );
  }

  return 0;
};

export const getIsRecurrent = offer => {
  if (!offer.repaymentSchedule) {
    return false;
  }

  return (
    (offer.repaymentSchedule.occurrences && offer.repaymentSchedule.occurrences.length > 0) ||
    (offer.repaymentSchedule.recurrences && offer.repaymentSchedule.recurrences.length > 0) ||
    false
  );
};

export const getOfferInfos = offer => {
  let infos = {};

  const isRecurrent = getIsRecurrent(offer);

  if (isRecurrent) {
    const firstOccurrence = getFirst(offer.repaymentSchedule.occurrences);
    const lastOccurence = getLast(offer.repaymentSchedule.occurrences);
    const recurrence = getFirst(offer.repaymentSchedule.recurrences);

    const regularPrice = recurrence || lastOccurence;
    const hasPromo = firstOccurrence.priceTI !== regularPrice.priceTI;

    infos = {
      hasPromo,
      price: regularPrice.priceTI,
      duration: getDuration(offer),
      ...(hasPromo
        ? {
            promoPrice: firstOccurrence.priceTI,
            promoDuration: {
              ...iso8601ToObject(firstOccurrence.offset),
              months: iso8601ToObject(firstOccurrence.offset).months + firstOccurrence.loop,
            },
          }
        : {}),
    };
  } else {
    infos = {
      hasPromo: false,
      price: offer.priceTI,
      duration: getDuration(offer),
    };
  }

  return {
    ...infos,
    registrationFeeTI: offer.registrationFeeTI,
  };
};

export function debounce(func, wait, immediate) {
  let timeout;
  return (...args) => {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
