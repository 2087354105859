import { createSelector } from 'redux-starter-kit';
import { schema, denormalize } from 'normalizr';
import { getClubId } from './config';

export const getEntities = createSelector(['entities']);

export const getClub = createSelector(
  [getEntities, getClubId],
  (entities, clubId) => {
    const entitySchema = new schema.Entity('clubs');

    return denormalize(clubId, entitySchema, entities);
  }
);

export const getMotivationList = createSelector(
  [getEntities],
  entities => Object.values(entities.motivations)
);

export const getGoalList = createSelector(
  [getEntities],
  entities => Object.values(entities.goals)
);

export const getArticleList = createSelector(
  [getEntities],
  entities => Object.values(entities.articles)
);

export const makeGetOffer = offerId =>
  createSelector(
    [getEntities],
    entities => {
      const entitySchema = new schema.Entity('offers', {
        product: new schema.Entity('products'),
      });

      return denormalize(offerId, entitySchema, entities);
    }
  );

export const makeGetProduct = productId =>
  createSelector(
    [getEntities],
    entities => {
      const entitySchema = new schema.Entity('products');

      return denormalize(productId, entitySchema, entities);
    }
  );

export const makeGetContact = contactId =>
  createSelector(
    [getEntities],
    entities => {
      const entitySchema = new schema.Entity('contacts');

      return denormalize(contactId, entitySchema, entities);
    }
  );
