import { Component, createElement } from 'react';
import PropTypes from 'prop-types';

const registerSocketListener = (eventName, handler) => WrappedComponent => {
  class SocketEventConnector extends Component {
    constructor(props, context) {
      super(props, context);

      this.eventName =
        typeof eventName === 'function' ? eventName(this.getChildProps()) : eventName;

      this.eventHandler = (...data) => {
        const handleEvent = handler(this.getChildProps());

        return handleEvent(...data);
      };
    }

    componentDidMount() {
      const { socket } = this.context;

      socket.on(this.eventName, this.eventHandler);
    }

    componentWillUnmount() {
      const { socket } = this.context;

      socket.off(this.eventName, this.eventHandler);
    }

    getChildProps() {
      return {
        ...this.props,
      };
    }

    render() {
      return createElement(WrappedComponent, this.getChildProps());
    }
  }

  SocketEventConnector.contextTypes = {
    socket: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  };

  return SocketEventConnector;
};

export default registerSocketListener;
