import * as React from 'react';
import { Grid } from '@stadline/react-ui-components';
import { compose, withState } from 'recompose'; // eslint-disable-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchEntity as fetchEntityFn } from '@app/store/reducers/entities';
import { getSaleId } from '@app/store/selectors/saleProcess';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  requestPayment as requestPaymentFn,
  reset as resetFn,
  gotoNextStep as gotoNextStepFn,
} from '@app/store/reducers/saleProcess';
import { getHostId } from '@app/store/selectors/device';
import SaleLayout from '../../components/SaleLayout';
import ShoppingBag from '../../components/ShoppingBag';
import registerSocketListener from '../../decorators/registerSocketListener';
import PaymentFailedModal from '../../components/PaymentFailedModal';
import backgroundUrl from './payment.png';

const InstructionsWrapper = styled('div')`
  background-image: url(${backgroundUrl});
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  color: #fff;
`;

class Payment extends React.Component {
  async componentDidMount() {
    const { requestPayment } = this.props;
    requestPayment();
  }

  retryTransaction = () => {
    const { requestPayment, setIsPaymentFailed } = this.props;
    setIsPaymentFailed(false);
    requestPayment();
  };

  cancelTransaction = async () => {
    const { reset, history } = this.props;
    await reset();
    history.push('/');
  };

  render() {
    const { isPaymentFailed } = this.props;
    return (
      <SaleLayout title="Procéder au paiement" withPrevButton={false} withNextButton={false}>
        <Grid container spacing={40} style={{ margin: 0, width: '100%', display: 'flex', flex: 1 }}>
          <Grid item xs={4}>
            <InstructionsWrapper />
          </Grid>
          <Grid item xs={6}>
            <ShoppingBag padding={100} withoutBorder />
          </Grid>
        </Grid>
        <PaymentFailedModal
          isOpen={!!isPaymentFailed}
          title={isPaymentFailed && isPaymentFailed.title}
          message={isPaymentFailed && isPaymentFailed.message}
          onRetry={this.retryTransaction}
          onCancel={this.cancelTransaction}
        />
      </SaleLayout>
    );
  }
}

Payment.propTypes = {
  requestPayment: PropTypes.func.isRequired,
  setIsPaymentFailed: PropTypes.func.isRequired,
  isPaymentFailed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  hostId: getHostId(state),
  saleId: getSaleId(state),
});

const mapActionCreator = {
  requestPayment: requestPaymentFn,
  reset: resetFn,
  gotoNextStep: gotoNextStepFn,
  fetchEntity: fetchEntityFn,
};

const getError = status => {
  switch (status) {
    case 'Cancel':
      return {
        title: 'Abandon de la transaction',
        message: 'Souhaites-tu vraiment annuler et quitter la transaction ?',
      };
    case 'Transaction Canceled':
      return {
        title: 'Erreur lors de la transaction',
        message: "Un incident de paiementà eu lieu, la transaction n'a pu aboutir.",
      };
    default:
      return {
        title: 'Erreur lors de la transaction',
        message:
          "Désolé, nous rencontrons des difficultés pour traiter ton paiement. Tu n'as pas été facturé pour cette transaction",
      };
  }
};

const handleReceipt = ({
  setIsPaymentFailed,
  gotoNextStep,
  fetchEntity,
  saleId,
}) => async receipt => {
  const { status } = receipt;

  if (status === 'Transaction Accepted') {
    await fetchEntity(saleId);
    gotoNextStep();
  } else {
    setIsPaymentFailed(getError(status));
  }
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapActionCreator
  ),
  withState('isPaymentFailed', 'setIsPaymentFailed', false),
  registerSocketListener(({ hostId }) => `terminal.${hostId}.receipt`, handleReceipt)
)(Payment);
