import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Grid, Modal, Typography, Icon } from '@stadline/react-ui-components';
import { reset as resetFn } from '@app/store/reducers/saleProcess';
import { resetError as resetErrorFn } from '@app/store/actions/hasError';
import Layout from './Layout';
import { compose } from '../helpers';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    hasReduxError: PropTypes.bool.isRequired,
  };

  state = {
    hasRenderError: false,
  };

  static getDerivedStateFromError() {
    return { hasRenderError: true };
  }

  handleQuit = async () => {
    const { reset, history, resetError } = this.props;
    await reset();
    await resetError();
    history.push('/');
  };

  componentDidCatch(error, info) {
    console.group('An error occured');
    console.error(error);
    console.info(info);
    console.groupEnd();
  }

  render() {
    const { hasRenderError } = this.state;
    const { children, hasReduxError } = this.props;

    return hasRenderError || hasReduxError ? (
      <Layout>
        <Modal maxWidth="md" open onClose={this.handleQuit}>
          <div style={{ padding: '50px' }}>
            <Grid spacing={40} container>
              <Grid item container xs={12} justify="center" alignItems="center">
                <Icon
                  name="error"
                  color="error"
                  style={{ fontSize: '60px', marginRight: '10px' }}
                />
                <Typography variant="h1">Oups !</Typography>
              </Grid>
              <Grid item container xs={12} justify="center" alignItems="center">
                <Typography variant="h2">
                  Une erreur est survenue durant l’inscription. Si ce problème persiste, nous te
                  conseillons de te rendre à l’accueil du club.
                </Typography>
              </Grid>
              <Grid item container justify="center">
                <Button size="large" variant="contained" color="primary" onClick={this.handleQuit}>
                  Quitter
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </Layout>
    ) : (
      children
    );
  }
}

const mapStateToProps = state => ({ hasReduxError: state.hasError });

const mapActionCreators = {
  reset: resetFn,
  resetError: resetErrorFn,
};

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapActionCreators
  )
);

export default enhance(ErrorBoundary);
