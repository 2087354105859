import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectOption as selectOptionFn } from '@app/store/reducers/saleProcess';
import { OptionCard } from '@app/components';
import { getOptions } from '@app/store/selectors/saleProcess';
import { monetize } from '../../helpers/i18n';
import SaleLayout from '../../components/SaleLayout';
import withSaleProcess from '../../providers/withSaleProcess';

const Options = ({ saleProcess, selectOption, options }) => (
  <SaleLayout withScroll withShoppingBag title="Tes options">
    <div style={{ display: 'flex', paddingLeft: 'calc(100vw / 12)' }}>
      {options.map(option => (
        <OptionCard
          key={option['@id']}
          picture={option.picture}
          name={option.product.name}
          description={option.product.description}
          price={`${monetize(option.priceTI)} / mois`}
          selected={saleProcess.selectedOptions.includes(option['@id'])}
          onClick={() => selectOption(option['@id'])}
        />
      ))}
    </div>
  </SaleLayout>
);

Options.propTypes = {
  saleProcess: PropTypes.shape({
    selectOption: PropTypes.string,
  }).isRequired,
  selectOption: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  options: getOptions(state),
});

const mapActionCreators = {
  selectOption: selectOptionFn,
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(withSaleProcess(Options));
