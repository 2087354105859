import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getClub } from '@app/store/selectors/entities';
import {
  getSaleId,
  getSaleContract,
  getNeedMandate,
  getAcceptTerms,
  getAcceptMandate,
  getSignature,
  getValidTerms,
} from '@app/store/selectors/saleProcess';
import {
  fetchFile as fetchFileFn,
  saveFile as saveFileFn,
  saveEntity as saveEntityFn,
} from '@app/store/reducers/entities';
import {
  setAcceptTerms as setAcceptTermsFn,
  setAcceptMandate as setAcceptMandateFn,
  setSignature as setSignatureFn,
} from '@app/store/reducers/saleProcessContract';
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Sketchfield,
  Button,
} from '@stadline/react-ui-components';
import { CustomCheckbox } from '@app/components';
import { b64toBlob } from '../../helpers/base64';
import { compose } from '../../helpers';
import SaleLayout from '../../components/SaleLayout';
import ContractViewer from '../../components/ContractViewer';

class SignContract extends React.Component {
  state = {
    contract: null,
  };

  async componentDidMount() {
    const { fetchFile, saleId } = this.props;
    const contract = await fetchFile(`${saleId}/contract`);
    this.setState({ contract });
  }

  onChangeAcceptTerms = event => {
    const { setAcceptTerms } = this.props;
    return setAcceptTerms(event.target.checked);
  };

  onChangeAcceptMandate = event => {
    const { setAcceptMandate } = this.props;
    return setAcceptMandate(event.target.checked);
  };

  onSignatureChange = value => {
    const { setSignature } = this.props;
    setSignature(value);
  };

  onSignatureClear = () => {
    const { setSignature } = this.props;
    setSignature(null);
  };

  saveSignature = async () => {
    const { saleId, saveFile, saveEntity, signature } = this.props;
    const savedSignature = await saveFile('contract_signatures', {
      data: b64toBlob(signature, 'image/png'),
    });
    await saveEntity(saleId, {
      data: {
        contractSignatureId: savedSignature.result['@id'],
      },
    });
  };

  retryAction = () => {
    const { signature } = this.props;
    return signature ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={this.onSignatureClear}
          style={{ marginLeft: '-16px' }}
        >
          Recommencer
        </Button>
      </div>
    ) : null;
  };

  render() {
    const { contract } = this.state;
    const { club, needMandate, acceptTerms, acceptMandate, signature, validTerms } = this.props;
    return (
      <SaleLayout
        extraActions={[this.retryAction()]}
        withNextButton={signature && validTerms}
        withShoppingBag
        title="Signature du contrat"
        onNext={this.saveSignature}
        nextButtonLabel="Procéder au paiement"
      >
        <Grid container spacing={40}>
          <Grid container item xs={7} direction="column">
            <Grid item style={{ backgroundColor: '#fff', padding: '5px' }}>
              <ContractViewer file={contract} />
            </Grid>
            <Grid item style={{ backgroundColor: '#fff', padding: '20px' }}>
              <FormGroup row>
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={<CustomCheckbox />}
                  checked={acceptTerms}
                  onChange={this.onChangeAcceptTerms}
                  label="Je reconnais avoir pris connaissance des conditions générales et les accepte"
                />
              </FormGroup>
              {needMandate && (
                <FormGroup row>
                  <FormControlLabel
                    style={{ margin: 0 }}
                    control={<CustomCheckbox />}
                    checked={acceptMandate}
                    onChange={this.onChangeAcceptMandate}
                    label={`Je certifie être titulaire du compte et donne mandat à ${
                      club ? club.name : '-'
                    } pour prélever mes factures d'abonnement`}
                  />
                </FormGroup>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <Grid item container spacing={40} direction="column" style={{ flexWrap: 'nowrap' }}>
              <Grid item>
                <Sketchfield
                  value={signature}
                  placeholder="Signez en utilisant votre doigt"
                  width="100%"
                  height="400px"
                  onChange={this.onSignatureChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SaleLayout>
    );
  }
}

const mapStateToProps = state => ({
  saleId: getSaleId(state),
  contract: getSaleContract(state),
  club: getClub(state),
  needMandate: getNeedMandate(state),
  acceptTerms: getAcceptTerms(state),
  acceptMandate: getAcceptMandate(state),
  signature: getSignature(state),
  validTerms: getValidTerms(state),
});

const mapActionCreators = {
  fetchFile: fetchFileFn,
  saveFile: saveFileFn,
  saveEntity: saveEntityFn,
  setAcceptTerms: setAcceptTermsFn,
  setAcceptMandate: setAcceptMandateFn,
  setSignature: setSignatureFn,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapActionCreators
  )
);

SignContract.propTypes = {
  club: PropTypes.shape({
    name: PropTypes.string,
  }),
  needMandate: PropTypes.bool.isRequired,
  acceptTerms: PropTypes.bool.isRequired,
  validTerms: PropTypes.bool.isRequired,
  acceptMandate: PropTypes.bool.isRequired,
  setAcceptTerms: PropTypes.func.isRequired,
  setAcceptMandate: PropTypes.func.isRequired,
  setSignature: PropTypes.func.isRequired,
  signature: PropTypes.string,
};

SignContract.defaultProps = {
  club: {
    name: 'votre club',
  },
  signature: null,
};

export default enhance(SignContract);
