import * as React from 'react';
import * as PropTypes from 'prop-types';

import { StateMachineRouterConsumer } from './StateMachineRouterProvider';
import StateMachineRoute from './StateMachineRoute';

const renderOrThrow = (routes, value, children) => {
  if (routes.includes(value)) return children;

  throw new Error(`Unknown route \`${value}\`. Be sure to create this route`);
};

const StateMachineSwitch = ({ children }) => {
  const availableRoutePaths = React.Children.map(children, child => child.props.value);

  return (
    <StateMachineRouterConsumer>
      {value => renderOrThrow(availableRoutePaths, value, children)}
    </StateMachineRouterConsumer>
  );
};

const componentShape = PropTypes.shape({
  type: PropTypes.oneOf([StateMachineRoute]),
});

StateMachineSwitch.propTypes = {
  children: PropTypes.oneOfType([
    componentShape,
    PropTypes.oneOf([false]),
    PropTypes.arrayOf(PropTypes.oneOfType([componentShape, PropTypes.oneOf([false])])),
  ]).isRequired,
};

export default StateMachineSwitch;
