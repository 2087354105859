import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@stadline/react-ui-components';

class DaySelector extends React.Component {
  constructor(props) {
    super(props);
    const { daysCount } = this.props;
    this.pool = new Array(31).fill().map((item, i) => ({
      id: i + 1,
      label: i + 1,
    }));
    this.state = {
      days: this.pool.slice(0, daysCount),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { daysCount } = this.props;
    if (daysCount !== nextProps.daysCount) {
      this.setState({
        days: this.pool.slice(0, nextProps.daysCount),
      });
    }
  }

  render() {
    const { required, value, onChange, daysCount } = this.props;
    const { days } = this.state;
    return (
      <SelectField
        key={daysCount}
        required={required}
        name="day"
        label="Jour"
        options={days}
        onChange={onChange}
        value={value}
      />
    );
  }
}

DaySelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  daysCount: PropTypes.number,
};

DaySelector.defaultProps = {
  value: null,
  onChange: () => {},
  required: false,
  daysCount: 31,
};

export default DaySelector;
