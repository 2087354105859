import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SelectField } from '@stadline/react-ui-components';

class MonthSelector extends React.Component {
  constructor(props) {
    super(props);
    const months = moment.months().map((month, i) => ({
      id: i + 1,
      label: month,
    }));
    this.state = {
      months,
    };
  }

  render() {
    const { required, value, onChange } = this.props;
    const { months } = this.state;
    return (
      <SelectField
        required={required}
        name="month"
        label="Mois"
        options={months}
        onChange={onChange}
        value={value}
      />
    );
  }
}

MonthSelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

MonthSelector.defaultProps = {
  value: null,
  onChange: () => {},
  required: false,
};

export default MonthSelector;
