import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@stadline/react-ui-components';
import moment from 'moment';
import DaySelector from './DaySelector';
import YearSelector from './YearSelector';
import MonthSelector from './MonthSelector';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    const { value } = props;
    const date = moment(value);
    if (!value || !date.isValid()) {
      this.state = {
        month: '',
        day: '',
        year: '',
      };
    } else
      this.state = {
        month: date.month() + 1,
        day: date.date(),
        year: date.year(),
      };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    const date = moment(value);
    if (!value || !date.isValid()) {
      this.setState({
        month: '',
        day: '',
        year: '',
      });
    } else
      this.setState({
        month: date.month() + 1,
        day: date.date(),
        year: date.year(),
      });
  }

  onInputChange = ({ value, name }) => {
    this.setState(
      {
        [name]: value,
      },
      this.onDateChanged
    );
  };

  getDaysInMonth = () => {
    const { month, year } = this.state;
    if (month && year) {
      const firstDayOfMonth = moment(`${year}-${month}-1`);
      if (!firstDayOfMonth.isValid()) {
        throw new Error('invalid date');
      } else {
        return firstDayOfMonth.daysInMonth();
      }
    }
    return 31;
  };

  onDateChanged = () => {
    const { month, day, year } = this.state;
    const { onChange } = this.props;
    if (month && day && year) {
      let newDay = day;
      const daysInMonth = this.getDaysInMonth();
      if (daysInMonth < day) {
        newDay = daysInMonth;
      }
      const newDate = `${year}-${month}-${newDay}`;
      if (moment(newDate).isValid()) {
        onChange(newDate); // warning : we need to add '1' to month
      }
    }
  };

  render() {
    const { minDate, maxDate, label, required } = this.props;
    const { month, day, year } = this.state;
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography>{label}</Typography>
        </Grid>
        <Grid container item spacing={40}>
          <Grid item xs={4}>
            <DaySelector
              value={day}
              required={required}
              onChange={this.onInputChange}
              daysCount={this.getDaysInMonth()}
            />
          </Grid>
          <Grid item xs={4}>
            <MonthSelector required={required} onChange={this.onInputChange} value={month} />
          </Grid>
          <Grid item xs={4}>
            <YearSelector
              required={required}
              minDate={minDate}
              maxDate={maxDate}
              onChange={this.onInputChange}
              value={year}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

DatePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  required: PropTypes.bool,
};

DatePicker.defaultProps = {
  minDate: '1900-01-01',
  maxDate: new Date(),
  label: '',
  onChange: () => {},
  required: false,
};

export default DatePicker;
