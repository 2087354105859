import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { TextField, Grid, ToggleButtonGroup, ToggleButton } from '@stadline/react-ui-components';
import { FieldError, DatePicker } from '@app/components';
import { withErrors } from '../helpers';
import { GRID_SPACING } from '../../../consts';
import { uuidv4 } from '../../../helpers';
import UserFormPart from './UserFormPart';

const PersonalInfosForm = ({ values, errors, handleChange, handleBlur, touched, showErrors }) => (
  <div style={{ display: 'inline-block' }}>
    <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
      <UserFormPart>
        <Grid container spacing={GRID_SPACING}>
          <Grid item xs={12}>
            <ToggleButtonGroup exclusive value={values.gender} onChange={handleChange('gender')}>
              <ToggleButton value="female">Mme</ToggleButton>
              <ToggleButton value="male">M.</ToggleButton>
            </ToggleButtonGroup>
            <FieldError {...withErrors(touched, errors, 'gender', showErrors)} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Nom"
              value={values.familyName}
              onBlur={handleBlur('familyName')}
              onChange={handleChange('familyName')}
              inputProps={{
                autoComplete: uuidv4(),
                maxlength: 50,
              }}
              {...withErrors(touched, errors, 'familyName', showErrors)}
            />
          </Grid>
        </Grid>
      </UserFormPart>
      <UserFormPart>
        <Grid container spacing={GRID_SPACING}>
          <Grid item xs={12}>
            <TextField
              required
              label="Prénom"
              value={values.givenName}
              onBlur={handleBlur('givenName')}
              onChange={handleChange('givenName')}
              inputProps={{
                autoComplete: uuidv4(),
                maxlength: 50,
              }}
              {...withErrors(touched, errors, 'givenName', showErrors)}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              required
              value={values.birthDate}
              onBlur={handleBlur('birthDate')}
              onChange={handleChange('birthDate')}
              label="Date de naissance"
              minDate={moment()
                .subtract(100, 'years')
                .format('YYYY-MM-DD')}
              maxDate={moment()
                .subtract(16, 'years')
                .format('YYYY-MM-DD')}
            />
            <FieldError {...withErrors(touched, errors, 'birthDate', showErrors)} />
          </Grid>
        </Grid>
      </UserFormPart>
    </div>
  </div>
);

PersonalInfosForm.propTypes = {
  values: PropTypes.shape({
    gender: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    birthDate: PropTypes.string,
  }).isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
};

export default PersonalInfosForm;
