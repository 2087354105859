const flattenGroupOffers = items =>
  items.reduce((offers, { id: itemId, ids: itemIds, ...data }) => {
    if (Array.isArray(itemIds)) {
      return [...offers, ...itemIds.map(id => ({ ...data, id }))];
    }

    return [...offers, { ...data, id: itemId }];
  }, []);

export const flattenOffers = ({ monthly = [], spot = [] }) => [
  ...flattenGroupOffers(monthly),
  ...flattenGroupOffers(spot),
];
