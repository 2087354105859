import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, Grid } from '@stadline/react-ui-components';

const ConfirmDeleteOfferModal = ({ isOpen, onCancel, onConfirm }) => (
  <Modal maxWidth="md" open={isOpen} onClose={onCancel}>
    <div style={{ padding: '50px' }}>
      <Grid spacing={40} container>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h1">Souhaites-tu changer de formule ?</Typography>
        </Grid>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h3">
            La suppression de ta formule entrainera la réinitialisation du panier ainsi qu&apos;un
            retour à l&apos;écran de sélection des formules
          </Typography>
        </Grid>
        <Grid item container xs={2} />
        <Grid item container xs={4} justify="flex-end">
          <Button fullWidth variant="outlined" color="secondary" size="medium" onClick={onCancel}>
            Annuler
          </Button>
        </Grid>
        <Grid item container xs={4} justify="flex-start">
          <Button fullWidth variant="contained" color="primary" size="medium" onClick={onConfirm}>
            Oui
          </Button>
        </Grid>
        <Grid item container xs={2} />
      </Grid>
    </div>
  </Modal>
);

ConfirmDeleteOfferModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDeleteOfferModal;
