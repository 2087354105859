import React from 'react';
import PropTypes from 'prop-types';
import { getIdleTimeout, getClientToken } from '@app/store/selectors/config';
import { Grid, Typography } from '@stadline/react-ui-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IdleTimer } from '@app/components';
import { selectors } from '@app/store';
import Layout from '../../components/Layout';
import Icon from './touch.png';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.idleTimer = React.createRef();
  }

  touchHandler = () => {
    const { history } = this.props;
    history.push('/sale-process');
  };

  render() {
    const { idleTimeout, clientToken } = this.props;

    return (
      <Layout>
        <IdleTimer
          ref={this.idleTimer}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={idleTimeout} // every minute
        />
        <Grid container>
          <Grid
            item
            xs={8}
            style={{
              backgroundColor: '#ccc',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <video
              preload="auto"
              autoPlay
              loop
              muted
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            >
              <source src={`/resources/${clientToken}/home_video.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
          <Grid
            align="center"
            item
            xs={4}
            style={{ padding: '20px 70px' }}
            onClick={this.touchHandler}
          >
            <Grid
              style={{ height: '100%' }}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant="h1">Bienvenue dans ton club Fitness Park !</Typography>
              <div style={{ margin: '100px 0' }}>
                <img src={Icon} alt="touch" />
              </div>
              <Typography variant="h2" style={{ opacity: 0.6 }}>
                Démarre ton expérience Fitness Park en touchant l&apos;écran
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

Home.propTypes = {
  idleTimeout: PropTypes.number.isRequired,
  clientToken: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isReady: selectors.getIsAppReady(state),
  idleTimeout: getIdleTimeout(state),
  clientToken: getClientToken(state),
});

Home.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps)(Home));
