import { createSelector } from 'redux-starter-kit';
import { schema, denormalize } from 'normalizr';
import ENTITY_SCHEMA from '@app/endpoints';
import getSaleProcessStateMachine from '../reducers/saleProcess/getSaleProcessStateMachine';
import saleProcessMachineStates from '../reducers/saleProcess/saleProcessMachine';
import { NEXT, PREV, CANCEL } from '../reducers/saleProcess/actions';
import { getEntities, getArticleList } from './entities';
import {
  getCatalogOfferIds,
  getCatalogOptionIds,
  getCatalogOptions,
  getCatalogProductIds,
  getCatalogProducts,
} from './config';

export const getOffers = createSelector(
  [getCatalogOfferIds, getEntities],
  (offerIds, entities) => {
    const entitySchema = new schema.Entity('offers', {
      product: new schema.Entity('products'),
    });
    return denormalize(offerIds, [entitySchema], entities).filter(i => !!i);
  }
);

export const getSaleProcessCurrentStep = createSelector(['saleProcess.currentStep']);

export const getSaleProcessNavSteps = createSelector(['saleProcess.navSteps']);

export const getIsProcessFinished = createSelector(['saleProcess.isProcessFinished']);

export const getIsCatalogFetched = createSelector(
  [getCatalogOfferIds, getEntities],
  (catalogOfferIds, entities) =>
    !catalogOfferIds.map(offerId => entities.offers[offerId]).includes(undefined)
);

export const getTotalOnregistration = createSelector(['saleProcess.totalOnregistration']);

export const getOfferMonthlyDuration = createSelector(['saleProcess.offerMonthlyDuration']);
export const getSelectedOfferId = createSelector(['saleProcess.selectedOffer']);
export const getSelectedOptionIds = createSelector(['saleProcess.selectedOptions']);
export const getSelectedOffer = createSelector(
  [getSelectedOfferId, getEntities],
  (offerId, entities) => {
    const entitySchema = new schema.Entity('offers', {
      product: new schema.Entity('products'),
    });
    return denormalize(offerId, entitySchema, entities);
  } // entities.offers[offerId]
);

export const getOptions = createSelector(
  [getCatalogOptionIds, getCatalogOptions, getEntities],
  (optionIds, catalogOptions, entities) => {
    const entitySchema = new schema.Entity('offers', { product: new schema.Entity('products') });
    const findPicture = item => {
      const id = item['@id'];
      const option = catalogOptions.find(opt => opt.id === id);
      if (option) return option.picture;
      return null;
    };
    const optionsWithoutPictures = denormalize(optionIds, [entitySchema], entities).filter(
      i => !!i
    );
    const optionsWithPictures = optionsWithoutPictures.map(optionWithoutPicture => ({
      ...optionWithoutPicture,
      picture: findPicture(optionWithoutPicture),
    }));
    return optionsWithPictures;
  }
);

export const getProducts = createSelector(
  [getCatalogProductIds, getCatalogProducts, getEntities],
  (productIds, catalogProducts, entities) => {
    const entitySchema = new schema.Entity('offers', { product: new schema.Entity('products') });
    const findPicture = item => {
      const id = item['@id'];
      const product = catalogProducts.find(i => i.id === id);
      if (product) return product.picture;
      return null;
    };

    const productsWithoutPicture = denormalize(productIds, [entitySchema], entities).filter(
      i => !!i
    );
    const productsWithPictures = productsWithoutPicture.map(product => ({
      ...product,
      picture: findPicture(product),
    }));

    return productsWithPictures;
  }
);

export const getSaleId = createSelector(['saleProcess.saleId']);
export const getTransactionId = createSelector(['saleProcess.transactionId']);

export const getSaleArticleIds = createSelector(['saleProcess.articleIds']);

export const getSale = createSelector(
  [getSaleId, getEntities],
  (saleId, entities) => {
    const entitySchema = new schema.Entity('sales');

    return denormalize(saleId, entitySchema, entities);
  }
);

export const getIsLoadingArticles = createSelector(['saleProcess.isLoadingArticles']);

export const getSaleHasContract = createSelector(
  [getSale],
  sale => sale && sale.hasContract
);

export const getSaleContract = createSelector(
  [getSale, getEntities],
  (sale, entities) => {
    if (!sale || !sale.hasContract) return null;
    const entitySchema = new schema.Entity('files');
    return denormalize(`${sale['@id']}/contract`, entitySchema, entities);
  }
);

export const getSaleArticles = createSelector(
  [getSaleId, getArticleList],
  (saleId, articles) => articles.filter(article => article.sale === saleId)
);

export const makeGetArticles = articleIds =>
  createSelector(
    [getArticleList],
    articleList =>
      articleIds.map(articleId => articleList.find(article => article['@id'] === articleId))
  );

export const createGetOffer = offerId =>
  createSelector(
    [getOffers],
    offers => offers.find(offer => offer['@id'] === offerId)
  );

export const getSaleProcessActiveNavStep = createSelector(
  [getSaleProcessCurrentStep],
  step => saleProcessMachineStates[step].navStep
);

const isRecurrent = article => {
  const { occurrences = null, recurrences = null } = article.repaymentSchedule;
  const occurrencesSize = occurrences ? occurrences.length : 0;
  const recurrencesSize = recurrences ? recurrences.length : 0;

  return occurrencesSize + recurrencesSize !== 0;
};

export const getNeedMandate = createSelector(
  [getSaleArticles],
  articles => articles.some(article => isRecurrent(article))
);

export const getAcceptTerms = createSelector(['saleProcessContract.acceptTerms']);
export const getAcceptMandate = createSelector(['saleProcessContract.acceptMandate']);
export const getSignature = createSelector(['saleProcessContract.signature']);

export const getValidTerms = createSelector(
  ['saleProcessContract', getNeedMandate],
  ({ acceptTerms, acceptMandate }, needMandate) =>
    acceptTerms && (needMandate ? acceptMandate : true)
);

// user
export const getContactId = createSelector(['saleProcessUser.contactId']);
export const getSalePicture = createSelector(['saleProcessUser.picture']);
export const getSalePictureList = createSelector(['saleProcessUser.pictureList']);
export const getSaleUserInfos = createSelector(['saleProcessUser.userInfos']);
export const getSaleUserFormValid = createSelector(['saleProcessUser.isUserFormValid']);
export const getSaleMandateFormValid = createSelector(['saleProcessUser.isMandateFormValid']);
export const getSkipMandate = createSelector(['saleProcessUser.skipMandate']);
export const getUserInfos = createSelector(['saleProcessUser.userInfos']);
export const getMandateInfos = createSelector(['saleProcessUser.mandateInfos']);

// optins
/* OPTINS */

export const getOptinIds = createSelector(['saleProcessUser.optinIds']);

export const getOptins = createSelector(
  [getOptinIds, getEntities],
  (optinIds, entities) => denormalize(optinIds, [ENTITY_SCHEMA], entities).filter(i => !!i)
);

export const getGroupedOptins = createSelector(
  [getOptins],
  optins => {
    const result = optins.reduce((acc, optin) => {
      if (!acc[optin.type]) acc[optin.type] = [];
      acc[optin.type].push(optin);
      return acc;
    }, {});
    return result;
  }
);

export const getOptinStatus = createSelector(
  [getOptins],
  optins => {
    const status = {};
    if (!optins) return status;
    optins.forEach(optin => {
      if (status[optin.type] === undefined) status[optin.type] = optin.isAuthorized;
      // if (status[optin.type] !== optin.isAuthorized) status[optin.type] = 'mixed';
    });
    return status;
  }
);

export const getSaleProcessContext = createSelector(
  [
    'entities',
    'saleProcess',
    getSaleHasContract,
    getSignature,
    getValidTerms,
    getSaleUserFormValid,
    getSkipMandate,
    getSaleMandateFormValid,
  ],
  (
    entities,
    saleProcess,
    hasSaleContract,
    signature,
    validTerms,
    isUserFormValid,
    skipMandate,
    isMandateFormValid
  ) => ({
    saleId: saleProcess.saleId,
    isRecurrent: saleProcess.isRecurrent,
    isOfferSelected: !!saleProcess.selectedOffer,
    isUserFormValid,
    hasSaleContract,
    isContractValid: signature && validTerms,
    isOfferRecurrent: saleProcess.isRecurrent,
    skipMandate,
    isMandateFormValid,
  })
);

// state machine

export const getStateMachine = createSelector(
  [getSaleProcessCurrentStep, getSaleProcessContext],
  (currentStep, context) => getSaleProcessStateMachine(currentStep, context)
);

export const hasNextStep = createSelector(
  [getSaleProcessCurrentStep, getStateMachine],
  (currentStep, stateMachine) => stateMachine.states[currentStep].events.includes(NEXT)
);

export const hasPrevStep = createSelector(
  [getSaleProcessCurrentStep, getStateMachine],
  (currentStep, stateMachine) => stateMachine.states[currentStep].events.includes(PREV)
);

export const hasCancelAction = createSelector(
  [getSaleProcessCurrentStep, getStateMachine],
  (currentStep, stateMachine) => stateMachine.states[currentStep].events.includes(CANCEL)
);
