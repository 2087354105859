import { createReducer } from 'redux-starter-kit';
import * as towerApi from '@app/tower-api';

/**
 * @typedef {Object.<string, Collection>} Entities
 * @typedef {Object.<string, Entity>} Collection
 * @typedef {Object} Entity
 */

const initialState = {
  hostId: null,
};

const SET_DEVICE_STATE = 'device/SET_DEVICE_STATE';

// ------------------------------------
// Actions
// ------------------------------------
export const setDeviceState = hostId => ({
  type: SET_DEVICE_STATE,
  payload: { hostId },
});

export const fetchDeviceState = () => async dispatch => {
  try {
    const {
      data: { HostId: hostId },
    } = await towerApi.getDeviceInfos();
    dispatch(setDeviceState(hostId));
  } catch (error) {
    // skip
  }
};

const handleSetDeviceState = (state, action) => ({ ...state, ...action.payload });

// ------------------------------------
// Reducer
// ------------------------------------
export default createReducer(initialState, {
  [SET_DEVICE_STATE]: handleSetDeviceState,
});
