import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SelectField } from '@stadline/react-ui-components';
import { getGoalList } from '@app/store/selectors/entities';

const GoalSelector = ({ goals, onChange, value, onBlur, required, name }) => (
  <SelectField
    name={name}
    required={required}
    label="Objectif"
    options={goals.map(goal => ({
      id: goal['@id'],
      label: goal.name,
    }))}
    onBlur={onBlur}
    onChange={onChange}
    value={value || ''}
  />
);

const mapStateToProps = state => ({
  goals: getGoalList(state),
});

GoalSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  goals: PropTypes.array,
  required: PropTypes.bool,
  name: PropTypes.string,
};

GoalSelector.defaultProps = {
  value: null,
  goals: [],
  required: false,
  name: '',
};

const enhance = connect(mapStateToProps);

export default enhance(GoalSelector);
