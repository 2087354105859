import { schema } from 'normalizr';

export const CONTACT_LIST = '/:clientToken/contacts';
export const TEMP_CONTACT_LIST = '/:clientToken/temp_contacts';

const TEMP_CONTACT_SCHEMA = new schema.Entity('tempContacts', {}, { idAttribute: '@id' });
const CONTACT_SCHEMA = new schema.Entity('contacts', {}, { idAttribute: '@id' });

export default {
  'http://schema.org/Person': CONTACT_SCHEMA,
  TempContact: TEMP_CONTACT_SCHEMA,
};
