import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, Grid, Icon } from '@stadline/react-ui-components';
import styled from 'styled-components';

const DataProtectionParagraph = styled('div')`
  margin-top: 24px;
  h3 {
    margin-bottom: 16px;
  }
`;

const DataProtectionRegulationModal = ({ isOpen, onClose }) => (
  <Modal maxWidth="lg" open={isOpen}>
    <Button onClick={onClose} style={{ alignSelf: 'flex-end' }}>
      <Icon name="close" />
      <Typography>Fermer</Typography>
    </Button>
    <div style={{ padding: '50px' }}>
      <Grid spacing={40} container>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Typography variant="h1">
            POLITIQUE DE CONFIDENTIALITE ET DE PROTECTION DES DONNEES A CARACTERE PERSONNEL
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography>
            La SAS MOV&apos;IN, propriétaire de la marque Fitness Park, ainsi que chacune des
            entités du Groupe Moving et chacun de ses licenciés de marque, attachent une importance
            particulière à la protection de vos données. <br /> <br />
            Les dispositions nécessaires ont été prises afin d&apos;assurer un niveau de sécurité
            maximal de vos données conformément à la loi n°78-17 du 6 janvier 1978 dite « Loi
            informatique et libertés » et au Règlement Général sur la Protection des Données du 27
            avril 2016, applicable à partir du 25 mai 2018. <br /> <br />
            La présente Politique de Protection des Données à Caractère Personnel énonce les
            principes directeurs de la protection de vos données lorsque vous entrez en relation
            avec l&apos;un des clubs Fitness Park du groupe Moving, lorsque vous vous connectez au
            site Internet Fitnesspark.fr ou lorsque vous téléchargez l&apos;application
            Myfitnesspark. <br />
            <br />
            Néanmoins, certains clubs sont exploités par des licenciés de marque, c&apos;est à dire
            des personnes n&apos;ayant pas de lien capitalistique avec la SAS MOV&apos;IN mais
            bénéficiant du droit d&apos;utiliser la marque Fitness Park. La présente politique de
            protection des données personnelles ne s&apos;applique pas aux licenciés ayant leurs
            propres sites Internet ou leurs propres applications mobiles. Pour savoir comment ils
            utilisent les informations de leurs clients, veuillez consulter leurs propres politiques
            de confidentialité.
          </Typography>
          <DataProtectionParagraph>
            <Typography variant="h3">
              1. QUELLES SONT LES DONNÉES PERSONNELLES QUE NOUS COLLECTONS ?
            </Typography>

            <Typography>
              Lorsque vous vous inscrivez dans l&apos;un de nos clubs, nous collectons et traitons
              notamment vos nom, prénom, adresse postale, adresse email, numéro de téléphone,
              coordonnées bancaires, date et heure d&apos;entrée et de sortie du club, demande de
              suspension d&apos;abonnement en particulier pour des raisons professionnelles ou pour
              cause de santé.
            </Typography>
          </DataProtectionParagraph>

          <DataProtectionParagraph>
            <Typography variant="h3">
              2. A QUEL MOMENT COLLECTONS-NOUS VOS DONNÉES PERSONNELLES ?
            </Typography>

            <Typography>
              Nous collectons les informations que vous nous fournissez volontairement notamment
              lorsque :
              <ul>
                <li>Vous vous inscrivez dans l&apos;un de nos clubs.</li>
                <li>
                  Vous effectuez une demande de pré-inscription ou de renseignement sur notre site
                  Internet Fitnesspark.fr
                </li>
                <li>Vous téléchargez l&apos;application MyFitnesspark.</li>
                <li>
                  Vous souhaitez être recontacté par un commercial ou pour une séance de coaching.
                </li>
                <li>
                  Vous réglez un abonnement ou une séance dans l&apos;un de nos clubs ou sur notre
                  site Internet.
                </li>
                <li>Vous réservez un cours collectif ou une séance de coaching.</li>
              </ul>
              Lorsque vous remplissez des champs, le caractère obligatoire de l&apos;information qui
              vous est demandée est signalé par un astérisque car ces informations sont nécessaires
              afin de remplir nos obligations vis-à-vis de vous, à savoir vous fournir une
              prestation de service ou vous permettre l&apos;achat d&apos;un produit. Si ces
              informations obligatoires ne nous sont pas communiquées, nous ne pourrons pas vous
              fournir ce que vous attendez.
              <br /> <br />
              Certaines informations, notamment techniques (adresse IP de votre ordinateur) ou
              concernant la consultation du site ainsi que ses fonctionnalités, sont collectées
              automatiquement du fait de vos actions sur notre site par des cookies. Pour plus
              d&apos;information, vous pouvez consulter notre politique en matière de cookies ici et
              modifier le paramétrage de vos cookies.
            </Typography>
          </DataProtectionParagraph>

          <DataProtectionParagraph>
            <Typography variant="h3">
              3. COMMENT UTILISERONS-NOUS VOS DONNÉES PERSONNELLES ?
            </Typography>

            <Typography>
              Nous utiliserons vos données personnelles dans le cadre de l&apos;exécution du contrat
              qui nous lie, pour un ou plusieurs des objectifs suivants :
              <ul>
                <li>Pour l&apos;exécution du contrat qui nous lie avec vous.</li>
                <li>
                  Pour effectuer des opérations relatives à la gestion de nos relations commerciales
                  : contrats, abonnements, factures, comptabilité, gestion de votre compte client,
                  suivi de notre relation client, gestion des réclamations, impayés et du
                  contentieux.
                </li>
                <li>
                  Pour l&apos;amélioration de votre expérience utilisateur sur notre site et pour
                  bénéficier des fonctionnalités et services du site.
                </li>
                <li>Pour vous faire participer à des opérations promotionnelles.</li>
                <li>Pour l&apos;élaboration de statistiques commerciales.</li>
                <li>
                  Pour l&apos;analyse et l&apos;établissement de statistiques relatives à la
                  consultation et à l&apos;utilisation du site (nombre de pages vues, nombre de
                  visites et activité) de nos services et des publicités affichées sur le site, nous
                  utilisons Google Analytics.
                </li>
                <li>
                  Pour l&apos;échange de nos fichiers de clients et prospects avec nos partenaires.
                </li>
                <li>
                  Pour l&apos;envoi d&apos;informations personnalisées sur nos offres et nos
                  services.
                </li>
                <li>
                  Pour la gestion des demandes de droit d&apos;accès, de rectification et
                  d&apos;opposition.
                </li>
                <li>Pour le respect de nos obligations légales et règlementaires.</li>
              </ul>
            </Typography>
          </DataProtectionParagraph>

          <DataProtectionParagraph>
            <Typography variant="h3">
              4. QUI SONT LES DESTINATAIRES DE VOS DONNÉES PERSONNELLES ?
            </Typography>

            <Typography>
              Chacun des clubs Fitness Park qu&apos;il soit licencié de marque ou succursale est
              destinataire de vos données. Leurs partenaires respectifs sont également
              destinataires. <br /> <br />
              Il s&apos;agit en premier lieu de la société HEITZ SYSTEM, propriétaire du logiciel de
              gestion de données. Il s&apos;agit également des prestataires de services avec
              lesquels chaque club travaille pour la gestion du service client, des abonnements et
              pour les finalités précédemment mentionnées, et ce uniquement dans la limite
              nécessaire à l&apos;accomplissement des tâches qui leur sont confiées. <br /> <br />
              Nous demandons aux prestataires de toujours agir en conformité avec les lois
              applicables en matière de protection de données personnelles et d&apos;accorder une
              attention particulière à la confidentialité et à la sécurité de ces données.
              <br /> <br />
              Vos données personnelles pourront être communiquées en application d&apos;une loi,
              d&apos;un règlement, d&apos;une décision d&apos;une autorité réglementaire ou
              judiciaire et enfin, si nécessaire pour Fitness Park, afin de préserver ses droits et
              intérêts.
              <br /> <br />
            </Typography>
          </DataProtectionParagraph>

          <DataProtectionParagraph>
            <Typography variant="h3">5. OÙ SONT STOCKÉES VOS DONNÉES PERSONNELLES ?</Typography>

            <Typography>
              Vos données personnelles sont stockées dans les bases de données de chaque club, en
              partenariat avec la société HEITZ SYSTEM et sur support papier. Chaque club a mis en
              place des mesures internes visant à la protection des données collectées.
            </Typography>
          </DataProtectionParagraph>

          <DataProtectionParagraph>
            <Typography variant="h3">
              6. VOS DONNÉES PERSONNELLES SERONT-ELLES TRANSFÉRÉES HORS DE L&apos;UNION EUROPÉENNE ?
            </Typography>

            <Typography>
              Non. Vos données personnelles ne seront pas transférées hors de l&apos;Union
              européenne.
            </Typography>
          </DataProtectionParagraph>

          <DataProtectionParagraph>
            <Typography variant="h3">
              7. QUELS SONT VOS DROITS CONCERNANT VOS DONNÉES PERSONNELLES ?
            </Typography>

            <Typography>
              Vous disposez d&apos;un droit d&apos;accès, de rectification et d&apos;effacement des
              données personnelles vous concernant, ainsi que d&apos;un droit à la limitation du
              traitement et à la portabilité de vos données. Vous disposez également du droit
              d&apos;opposition pour motif légitime au traitement de vos données personnelles ainsi
              que du droit d&apos;opposition au traitement de vos données à des fins de prospection.
              <br /> <br />
              Vous disposez enfin du droit d&apos;introduire une réclamation auprès de la CNIL.
              <br /> <br />
              Vous pouvez exercer ces droits à tout moment en vous adressant à notre service client
              par courriel : dpo@groupemoving.fr, en précisant votre référence client.
              <br /> <br />
              Une réponse vous sera alors adressée dans un délai d&apos;un à trois mois suivant la
              réception de la demande.
            </Typography>
          </DataProtectionParagraph>

          <DataProtectionParagraph>
            <Typography variant="h3">
              8. QUELLE EST LA DURÉE DE CONSERVATION DE VOS DONNÉES PERSONNELLES ?
            </Typography>

            <Typography>
              Vos données personnelles sont conservées pendant une durée qui n&apos;excède pas la
              durée nécessaire aux finalités pour lesquelles elles ont été collectées.
              <br /> <br />
              Ainsi, vos données seront conservées pendant la durée de notre relation contractuelle
              et en archivage pendant une durée de quatre (4) ans suivant la fin de ladite relation
              contractuelle, lorsqu&apos;elles présentent un intérêt administratif, notamment pour
              l&apos;établissement de la preuve d&apos;un droit ou d&apos;un contrat.
            </Typography>
          </DataProtectionParagraph>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Button variant="outlined" color="secondary" size="medium" onClick={onClose}>
            Fermer
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
);

DataProtectionRegulationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

DataProtectionRegulationModal.defaultProps = {
  isOpen: false,
};
export default DataProtectionRegulationModal;
