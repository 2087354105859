import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout as LayoutBase } from '@app/components';
import { reset as resetFn } from '@app/store/reducers/saleProcess';
import { getClub } from '@app/store/selectors/entities';
import { compose } from '../helpers';

const Layout = ({ club, children, ...props }) => (
  <LayoutBase club={club} {...props}>
    {children}
  </LayoutBase>
);

const mapActionCreators = {
  reset: resetFn,
};

const mapStateToProps = state => ({
  club: getClub(state),
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapActionCreators
  )
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  reset: PropTypes.func.isRequired,
  club: PropTypes.shape({
    name: PropTypes.string,
  }),
};

Layout.defaultProps = {
  club: {
    name: 'Votre club',
  },
};

export default enhance(Layout);
