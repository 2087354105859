import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { switchOffersMode as switchOffersModeFn } from '@app/store/reducers/saleProcess';
import { getCatalogOffers } from '@app/store/selectors/config';
import { getIsCatalogFetched, getSelectedOfferId } from '@app/store/selectors/saleProcess';
import { Loader } from '@app/components';
import { Grid, ToggleButtonGroup, ToggleButton } from '@stadline/react-ui-components';
import Offer from './Offer';
import withSaleProcess from '../../providers/withSaleProcess';
import SaleLayout from '../../components/SaleLayout';

const Offers = ({ isReady, saleProcess, switchOffersMode, offers, selectedOfferId }) => {
  const handeswitchOffersMode = mode => () => switchOffersMode(mode);

  const actions = [
    <div style={{ textAlign: 'center' }}>
      <div style={{ paddingBottom: '16px' }}>Méthodes de paiement</div>
      <ToggleButtonGroup exclusive selected value={saleProcess.offersMode}>
        <ToggleButton
          value="monthly"
          onClick={handeswitchOffersMode('monthly')}
          style={{ width: '216px', height: '60px' }}
        >
          Prélèvement
        </ToggleButton>
        <ToggleButton
          value="spot"
          onClick={handeswitchOffersMode('spot')}
          style={{ width: '216px', height: '60px' }}
        >
          Comptant
        </ToggleButton>
      </ToggleButtonGroup>
    </div>,
  ];

  let selectedOffers;

  if (saleProcess.offersMode === 'monthly') {
    selectedOffers = offers.monthly;
  }
  if (saleProcess.offersMode === 'spot') {
    selectedOffers = offers.spot;
  }

  return (
    <SaleLayout
      extraActions={actions}
      title="Tes formules"
      withShoppingBag
      withNextButton={Boolean(selectedOfferId)}
    >
      <Grid container justify="center" spacing={40} style={{ margin: 0, width: '100%' }}>
        <Grid item xs={4} />

        {isReady ? (
          (selectedOffers || []).map((offer, offerIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={offerIndex} item xs={4} style={{ paddingTop: 0 }}>
              <Offer catalogOffer={offer} />
            </Grid>
          ))
        ) : (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              flex: 1,
            }}
          >
            <Loader color="secondary" />
          </Grid>
        )}
      </Grid>
    </SaleLayout>
  );
};

Offers.propTypes = {
  saleProcess: PropTypes.shape({
    selectOffer: PropTypes.string,
  }).isRequired,
  switchOffersMode: PropTypes.func.isRequired,
  offers: PropTypes.object.isRequired,
  selectedOfferId: PropTypes.string,
  isReady: PropTypes.bool.isRequired,
};

Offers.defaultProps = {
  selectedOfferId: null,
};

const mapStateToProps = state => ({
  offers: getCatalogOffers(state),
  selectedOfferId: getSelectedOfferId(state),
  isReady: getIsCatalogFetched(state),
});

const mapActionCreators = {
  switchOffersMode: switchOffersModeFn,
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(withSaleProcess(Offers));
