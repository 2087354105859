import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, Grid, Icon } from '@stadline/react-ui-components';

const ResetSaleModal = ({ isOpen, onConfirm, onCancel }) => (
  <Modal maxWidth="md" open={isOpen} onClose={onCancel}>
    <div style={{ padding: '50px' }}>
      <Grid spacing={40} container>
        <Grid item container xs={12} justify="center" alignItems="center">
          <Icon name="error" color="warning" style={{ fontSize: 70, marginRight: '16px' }} />
          <Typography variant="h1">Souhaites-tu vraiment quitter ?</Typography>
        </Grid>
        <Grid item container xs={6} justify="flex-end">
          <Button variant="outlined" color="secondary" size="medium" onClick={onConfirm}>
            Oui
          </Button>
        </Grid>
        <Grid item container xs={6}>
          <Button variant="contained" color="primary" size="medium" onClick={onCancel}>
            Non
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
);

ResetSaleModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

ResetSaleModal.defaultProps = {
  isOpen: false,
  onConfirm: () => {},
  onCancel: () => {},
};
export default ResetSaleModal;
