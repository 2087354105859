import { createSelector } from 'redux-starter-kit';
/**
 * Returns config state
 *
 * @param {object} state
 * @returns {object}
 */
export const getDevice = createSelector(['device']);

/**
 * Returns Socket.IO config
 *
 * @param {object} state
 * @returns {object}
 */
export const getHostId = createSelector(
  [getDevice],
  device => device.hostId
);
