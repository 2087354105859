import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Stepper, Button, Typography, Icon } from '@stadline/react-ui-components';

// je vois pas comment faire autrement pour surcharger le stepper
const Step = styled(Stepper.StepLabel)`
  svg {
    ${props =>
      props.active &&
      `
      circle {
        fill: ${props.theme.palette.secondary.main};
      }
    `}
    path {
      fill: ${props => props.theme.palette.secondary.main};
    }
    text {
      fill: ${props => props.theme.palette.text.white};
    }
  }
`;

const SaleLayout = ({
  children,
  steps,
  activeStep,
  title,
  extraActions,
  prevAction,
  nextAction,
  onNext,
  onPrev,
  withPrevButton,
  withNextButton,
  nextButtonLabel,
  withStepper,
  withNav,
}) => (
  <div
    style={{
      width: '100vw',
      padding: '32px',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {withStepper && (
      <Grid container spacing={40} style={{ marginBottom: '30px' }}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Stepper activeStep={activeStep}>
            {Object.keys(steps).map(step => (
              <Stepper.Step key={step}>
                <Step>{steps[step]}</Step>
              </Stepper.Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    )}
    {withNav ? (
      <Grid container spacing={40} item alignItems="flex-start" style={{ marginBottom: '30px' }}>
        <Grid item xs={1}>
          {prevAction && withPrevButton && (
            <Button
              style={{ width: '114px', height: '56px', minHeight: 0 }}
              onClick={async () => {
                if (onPrev) {
                  await onPrev();
                }
                prevAction();
              }}
              size="medium"
              variant="outlined"
              color="secondary"
            >
              <Icon name="arrow_back" />
            </Button>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ flex: '1' }} color="secondary" variant="h2">
            {title}
          </Typography>
        </Grid>
        <Grid item container xs={4} justify="center">
          {extraActions}
        </Grid>
        <Grid item container justify="flex-end" xs={4}>
          {nextAction && withNextButton && (
            <Button
              style={{ minWidth: '241px', height: '60px', minHeight: 0, padding: '8px 16px' }}
              onClick={async () => {
                if (onNext) {
                  await onNext();
                }
                nextAction();
              }}
              size="large"
              variant="contained"
              color="primary"
            >
              <span>{nextButtonLabel}</span>
              <Icon name="arrow_forward" style={{ marginLeft: '8px' }} />
            </Button>
          )}
        </Grid>
      </Grid>
    ) : null}
    <Grid container style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
      {children}
    </Grid>
  </div>
);

SaleLayout.propTypes = {
  children: PropTypes.node.isRequired,
  extraActions: PropTypes.arrayOf(PropTypes.node),
  prevAction: PropTypes.func,
  nextAction: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  steps: PropTypes.object,
  activeStep: PropTypes.number,
  withPrevButton: PropTypes.bool,
  withNextButton: PropTypes.bool,
  nextButtonLabel: PropTypes.string,
  onNext: PropTypes.func, // method to call before next navigation
  onPrev: PropTypes.func, // method to call before prev navigation
  withNav: PropTypes.bool,
  withStepper: PropTypes.bool,
};

const noop = () => {};

SaleLayout.defaultProps = {
  extraActions: [],
  prevAction: noop,
  nextAction: noop,
  title: '',
  steps: {},
  activeStep: 0,
  onNext: null,
  onPrev: null,
  withPrevButton: true,
  withNextButton: true,
  nextButtonLabel: 'Etape suivante',
  withNav: true,
  withStepper: true,
};

export default SaleLayout;
