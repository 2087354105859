import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectProduct as selectProductFn } from '@app/store/reducers/saleProcess';
import { OptionCard } from '@app/components';
import { getProducts } from '@app/store/selectors/saleProcess';
import { monetize } from '../../helpers/i18n';
import SaleLayout from '../../components/SaleLayout';
import withSaleProcess from '../../providers/withSaleProcess';

const Products = ({ saleProcess, selectProduct, products }) => (
  <SaleLayout withScroll withShoppingBag title="Tes accessoires">
    <div style={{ display: 'flex', paddingLeft: 'calc(100vw / 12)' }}>
      {products.map(offer => (
        <OptionCard
          key={offer['@id']}
          picture={offer.picture}
          name={offer.product.name}
          description={offer.product.description}
          price={`${monetize(offer.priceTI)}`}
          selected={saleProcess.selectedProducts.includes(offer['@id'])}
          onClick={() => selectProduct(offer['@id'])}
        />
      ))}
    </div>
  </SaleLayout>
);

Products.propTypes = {
  saleProcess: PropTypes.shape({
    selectOption: PropTypes.string,
  }).isRequired,
  selectProduct: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  products: getProducts(state),
});

const mapActionCreators = {
  selectProduct: selectProductFn,
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(withSaleProcess(Products));
