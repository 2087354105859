import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid } from '@stadline/react-ui-components';
import { PhoneField } from '@app/components';
import { GRID_SPACING } from '../../../consts';
import { uuidv4 } from '../../../helpers';
import { withErrors } from '../helpers';
import UserFormPart from './UserFormPart';

const ContactForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  showErrors,
  setMail,
  checkEmail,
}) => (
  <UserFormPart>
    <Grid container spacing={GRID_SPACING}>
      <Grid item xs={12}>
        <TextField
          label="Email"
          required
          value={values.email}
          onBlur={handleBlur('email')}
          onChange={event => {
            setMail(event.target.value);
            checkEmail(event.target.value);
          }}
          inputProps={{
            autoComplete: uuidv4(),
            maxlength: 254,
          }}
          {...withErrors(touched, errors, 'email', showErrors)}
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneField
          label="Téléphone"
          required
          state={errors.mobile && touched.mobile ? 'error' : null}
          helperText={touched.email && errors.email}
          value={values.mobile}
          onBlur={handleBlur('mobile')}
          onChange={handleChange('mobile')}
          inputProps={{
            autoComplete: uuidv4(),
          }}
          {...withErrors(touched, errors, 'mobile', showErrors)}
        />
      </Grid>
    </Grid>
  </UserFormPart>
);

ContactForm.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string,
    mobile: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  setMail: PropTypes.func.isRequired,
  checkEmail: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  showErrors: PropTypes.bool.isRequired,
};

export default ContactForm;
