import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getHostId } from '@app/store/selectors/device';
import { makeGetOffer } from '@app/store/selectors/entities';
import {
  distributeCard as distributeCardFn,
  reset as resetFn,
  setIsProcessFinished as setIsProcessFinishedFn,
} from '@app/store/reducers/saleProcess';
import { getIsProcessFinished, getSelectedOfferId } from '@app/store/selectors/saleProcess';
import { Grid } from '@stadline/react-ui-components';
import withSaleProcess from '../../providers/withSaleProcess';
import SaleLayout from '../../components/SaleLayout';
import DispenserCardLoading from '../../components/DispenserCardLoading';
import DispenserCardOk from '../../components/DispenserCardOk';
import DispenserCardError from '../../components/DispenserCardError';
import { getOfferInfos, getIsRecurrent, compose } from '../../helpers';

import registerSocketListener from '../../decorators/registerSocketListener';
import backgroundUrl from './dispenser.png';

const InstructionsWrapper = styled('div')`
  background-image: url(${backgroundUrl});
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  color: #fff;
`;

class Confirm extends React.Component {
  static propTypes = {
    distributeCard: PropTypes.func.isRequired,
    offer: PropTypes.object.isRequired,
    isProcessFinished: PropTypes.bool.isRequired,
  };

  state = {
    loading: true,
    error: false,
  };

  async componentDidMount() {
    const { distributeCard } = this.props;
    let newState = { loading: false };
    const { CardUID } = await distributeCard();
    if (CardUID === 'None') {
      newState = { ...newState, error: true };
    }

    this.setState({ ...newState });
  }

  goHome = async () => {
    const { reset, history } = this.props;
    await reset();
    history.push('/');
  };

  render() {
    const { isProcessFinished, offer } = this.props;
    const { error, loading } = this.state;
    const isRecurrent = getIsRecurrent(offer);
    const subscriptionInfos = getOfferInfos(offer);

    return (
      <SaleLayout title="" withNav={false}>
        <Grid container spacing={40} style={{ paddingLeft: '32px', margin: 0 }}>
          <Grid item xs={4} style={{ height: '750px' }}>
            <InstructionsWrapper />
          </Grid>

          <Grid item container xs={6} alignItems="center" style={{ height: '750px' }}>
            <Grid
              container
              spacing={32}
              direction="column"
              align="center"
              style={{
                backgroundColor: '#fff',
                maxHeight: '560px',
                margin: 0,
                padding: '32px 0',
                border: '1px solid rgb(216, 216, 216)',
              }}
            >
              {loading ? (
                <DispenserCardLoading />
              ) : (
                <>
                  {error ? (
                    <DispenserCardError onClick={this.goHome} />
                  ) : (
                    <DispenserCardOk
                      onClick={this.goHome}
                      offer={offer}
                      isRecurrent={isRecurrent}
                      subscriptionInfos={subscriptionInfos}
                      error={error}
                      isProcessFinished={isProcessFinished}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </SaleLayout>
    );
  }
}

const mapStateToProps = state => ({
  isProcessFinished: getIsProcessFinished(state),
  offer: makeGetOffer(getSelectedOfferId(state))(state),
  hostId: getHostId(state),
});

const mapActionCreators = {
  distributeCard: distributeCardFn,
  reset: resetFn,
  setIsProcessFinished: setIsProcessFinishedFn,
};

const handleCardRemoved = ({ setIsProcessFinished }) => receipt => {
  const { status } = receipt;
  if (status === 'CardRemoved') {
    setIsProcessFinished(true);
  }
};

export default compose(
  withRouter,
  withSaleProcess,
  connect(
    mapStateToProps,
    mapActionCreators
  ),
  registerSocketListener(({ hostId }) => `terminal.${hostId}.dispenser`, handleCardRemoved)
)(Confirm);
