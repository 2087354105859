import './double-click-blocker.css';

let syncRequests = 0;
document.documentElement.dataset.syncRequests = syncRequests; // eslint-disable-line no-restricted-properties

const incrementSyncRequest = request => {
  if (!['GET', 'HEAD'].includes(request.method.toUpperCase())) {
    syncRequests += 1;
    document.documentElement.dataset.syncRequests = syncRequests; // eslint-disable-line no-restricted-properties
  }

  return request;
};

const decrementSyncRequest = response => {
  if (!['GET', 'HEAD'].includes(response.config.method.toUpperCase())) {
    setTimeout(() => {
      syncRequests -= 1;
      document.documentElement.dataset.syncRequests = syncRequests; // eslint-disable-line no-restricted-properties
    }, 200);
  }

  return response;
};

export const interceptRequests = client => {
  client.interceptors.request.use(incrementSyncRequest);
};

export const interceptResponses = client => {
  client.interceptors.response.use(decrementSyncRequest, decrementSyncRequest);
};
