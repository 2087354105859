import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Grid, Typography } from '@stadline/react-ui-components';

const AlertMessageWrapper = styled(Grid)`
  border: 2px solid ${props => props.theme.palette.secondary.main};
  background-color: ${props => props.theme.palette.grey[200]};
  color: ${props => props.theme.palette.secondary.main};
  margin-bottom: 20px;
`;

const AlertMessage = ({ message, icon }) => (
  <AlertMessageWrapper container justify="center" alignItems="center">
    <Grid style={{ padding: '20px' }} item container alignItems="center" justify="center" xs={2}>
      <Icon style={{ fontSize: '50px' }} color="secondary" name={icon} />
    </Grid>
    <Grid style={{ padding: '20px' }} item container alignItems="center" justify="center" xs={10}>
      <Typography>{message}</Typography>
    </Grid>
  </AlertMessageWrapper>
);

AlertMessage.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
};

AlertMessage.defaultProps = {
  icon: 'warning',
  message: '',
};

export default AlertMessage;
