import React, { Component } from 'react';
import styled from 'styled-components';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';

const WebcamWrapper = styled('div')`
  border: 5px solid ${props => props.theme.palette.grey[300]};
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

class WebcamPictureField extends Component {
  state = {
    countdown: 0,
    isWaiting: false,
    intervalId: null,
  };

  setRef = webcam => {
    this.webcam = webcam;
  };

  getScreenshot = () => {
    const { onChange } = this.props;
    const data = this.webcam.getScreenshot();

    const canv = document.createElement('canvas');
    const ctx = canv.getContext('2d');
    const img = new Image();
    img.addEventListener('load', () => {
      canv.width = 400;
      canv.height = 350;
      ctx.drawImage(img, 100, 0, 400, 350, 0, 0, 400, 350);
      onChange(canv.toDataURL('image/jpeg'));
    });
    img.src = data;
  };

  takePicture = () => {
    const { isWaiting } = this.state;
    if (isWaiting) return;
    const { delay } = this.props;
    // create interval for countdown
    const intervalId = setInterval(this.timer, 100);
    this.setState(prevState => ({
      ...prevState,
      isWaiting: true,
      countdown: delay,
      intervalId,
    }));
  };

  timer = () => {
    const { countdown, intervalId } = this.state;
    if (countdown <= 0) {
      clearInterval(intervalId);
      this.setState(prevState => ({
        ...prevState,
        isWaiting: false,
        countdown: 0,
        intervalId: null,
      }));
      this.getScreenshot();
    } else this.setState(prevState => ({ ...prevState, countdown: prevState.countdown - 100 }));
  };

  render() {
    const { value, delay, CountdownComponent, readOnly, ...otherProps } = this.props;
    const { countdown, isWaiting } = this.state;
    const width = otherProps.width - 10;
    const height = otherProps.height - 10;
    const webcamProps = {
      videoConstraints: {
        width,
        height,
        facingMode: 'user',
      },
      ...otherProps,
      width,
      height,
    };
    return (
      <WebcamWrapper width={width} height={height}>
        {!readOnly && (
          <Webcam
            screenshotFormat="image/jpeg"
            {...webcamProps}
            ref={this.setRef}
            style={{ objectFit: 'cover', transform: 'scale(1.2)', transformOrigin: 'top' }}
          />
        )}
        {value && (
          <img
            alt="screenshot"
            src={value}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: '4',
            }}
          />
        )}
        {isWaiting && CountdownComponent && countdown > 0 && (
          <CountdownComponent>{Math.ceil(countdown / 1000)}</CountdownComponent>
        )}
      </WebcamWrapper>
    );
  }
}

WebcamPictureField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  delay: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  audio: PropTypes.bool,
  screenshotQuality: PropTypes.number,
  CountdownComponent: PropTypes.node,
  readOnly: PropTypes.bool,
};

WebcamPictureField.defaultProps = {
  onChange: () => {},
  value: null,
  delay: 3000,
  width: 640,
  height: 480,
  audio: false,
  screenshotQuality: 1,
  CountdownComponent: null,
  readOnly: false,
};

export default WebcamPictureField;
