import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography } from '@stadline/react-ui-components';
import React from 'react';
import Card from './Card';

const SelectableCard = styled(Card)`
  border-color: ${props => (props.selected ? props.theme.palette.primary.main : 'transparent')};
  border-width: 3px;
  border-style: solid;
  width: 100%;
`;

const OptionCardWrapper = styled('div')`
  display: inline-flex;
  flex: 0 0 calc(100vw / 6);
  margin: 0 20px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const OptionCard = ({ selected, price, picture, description, onClick, name, key }) => (
  <OptionCardWrapper>
    <SelectableCard key={key} selected={selected} onClick={onClick}>
      <Card.cardMedia image={picture} style={{ backgroundSize: 'contain' }} />
      <Card.cardContent>
        <Typography variant="h2">{name}</Typography>
        <Typography variant="h3" gutterBottom>
          {price}
        </Typography>
        <Typography>{description}</Typography>
      </Card.cardContent>
    </SelectableCard>
  </OptionCardWrapper>
);

OptionCard.propTypes = {
  selected: PropTypes.bool,
  price: PropTypes.string,
  picture: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
  key: PropTypes.string,
};

OptionCard.defaultProps = {
  selected: false,
  price: '',
  picture: '',
  description: '',
  onClick: () => {},
  name: '',
  key: '',
};

export default OptionCard;
