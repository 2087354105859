import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@stadline/react-ui-components';
import { getGoalList, getMotivationList } from '@app/store/selectors/entities';
import { MotivationSelector, GoalSelector } from '../fields';
import { GRID_SPACING } from '../../../consts';
import UserFormPart from './UserFormPart';

const ProfileForm = ({ values, handleChange, handleBlur }) => (
  <UserFormPart>
    <Grid container spacing={GRID_SPACING}>
      <Grid item xs={12}>
        <GoalSelector
          name="goal"
          onBlur={handleBlur('goal')}
          onChange={event => handleChange({ target: event })}
          value={values.goal || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <MotivationSelector
          name="motivation"
          onBlur={handleBlur('motivation')}
          onChange={event => handleChange({ target: event })}
          value={values.motivation || ''}
        />
      </Grid>
    </Grid>
  </UserFormPart>
);

const mapStateToProps = state => ({
  goals: getGoalList(state),
  motivations: getMotivationList(state),
});

ProfileForm.propTypes = {
  values: PropTypes.shape({
    motivation: PropTypes.string,
    goal: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

const enhance = connect(mapStateToProps);

export default enhance(ProfileForm);
