import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, Icon } from '@stadline/react-ui-components';

const DispenserCardError = ({ onClick }) => (
  <>
    <Grid item container justify="center" alignItems="center">
      <Icon name="cancel" style={{ color: 'red', fontSize: 70, marginRight: '16px' }} />
      <Typography variant="h1">Erreur dans la génération de ta carte</Typography>
    </Grid>
    <Grid item>
      <Typography>
        Nous ne sommes pas en mesure de te délivrer ta carte club. <br />
        Nous t&apos;invitons à te rendre à l&apos;accueil afin de compléter ta souscription
      </Typography>
    </Grid>
    <Grid item style={{ marginTop: '16px' }}>
      <Button
        onClick={onClick}
        size="large"
        color="primary"
        style={{ padding: '26px 105px' }}
        variant="contained"
      >
        <Typography variant="h3">Quitter</Typography>
      </Button>
    </Grid>
  </>
);

DispenserCardError.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DispenserCardError;
