import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomCheckbox } from '@app/components';
import { getClub } from '@app/store/selectors/entities';
import {
  fetchOptins as fetchOptinsFn,
  changeOptin as changeOptinFn,
} from '@app/store/reducers/saleProcessUser';
import { Grid, FormGroup, FormControlLabel, Typography } from '@stadline/react-ui-components';
import SaleLayout from '../../components/SaleLayout';
import DataProtectionRegulationModal from '../../components/DataProtectionRegulationModal';

class Optins extends React.Component {
  state = {
    isOpen: false,
    optins: {},
  };

  async componentDidMount() {
    const { fetchOptins } = this.props;
    const optins = await fetchOptins();
    this.setState({ ...optins });
  }

  onChangeOptin = (event, type) => {
    const { optins } = this.state;
    this.setState({ optins: { ...optins, [type]: event.target.checked } });
  };

  submitOptions = () => {
    const { changeOptin } = this.props;
    const { optins } = this.state;

    Object.entries(optins).forEach(([type, value]) => {
      changeOptin(type, value);
    });
  };

  openModel = () => this.setState({ isOpen: true });

  closeModel = () => this.setState({ isOpen: false });

  toggleModal = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { club } = this.props;
    const { optins: optinStatus, isOpen } = this.state;

    return optinStatus ? (
      <SaleLayout withShoppingBag title="Tes informations personnelles" onNext={this.submitOptions}>
        <div style={{ display: 'inline-block', paddingLeft: 'calc(100vw/12)' }}>
          <Grid item container direction="column" style={{ position: 'relative', height: '100%' }}>
            <Typography style={{ marginBottom: '40px' }}>Et si on restait en contact ?</Typography>
            <FormGroup row style={{ marginBottom: '40px' }}>
              <FormControlLabel
                control={<CustomCheckbox />}
                checked={optinStatus.news}
                onChange={event => this.onChangeOptin(event, 'news')}
                label={
                  <>
                    <Typography variant="h3" gutterBottom>
                      Actualités Fitness Park
                    </Typography>
                    <Typography variant="h4">
                      Je m&apos;abonne aux actualités de {club.name} pour être au courant des
                      événements, animations et offres spéciales de mon club.
                    </Typography>
                  </>
                }
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={<CustomCheckbox />}
                checked={optinStatus.partners}
                onChange={event => this.onChangeOptin(event, 'partners')}
                label={
                  <>
                    <Typography variant="h3" gutterBottom>
                      Informations Partenaires
                    </Typography>
                    <Typography variant="h4">
                      Je m&apos;abonne aux actualités des partenaires de {club.name} pour recevoir
                      des informations et des offres exclusives.
                    </Typography>
                  </>
                }
              />
            </FormGroup>
            <Typography
              gutterBottom
              style={{ position: 'absolute', bottom: '24px' }}
              onClick={this.openModel}
            >
              En cliquant sur ETAPE SUIVANTE, vous avez lu et accepté la{' '}
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Politique de protection des données personnelles Fitness Park
              </span>
            </Typography>
          </Grid>
        </div>
        <DataProtectionRegulationModal isOpen={isOpen} onClose={this.closeModel} />
      </SaleLayout>
    ) : null;
  }
}

const mapStateToProps = state => ({
  club: getClub(state),
});

const mapActionCreators = {
  fetchOptins: fetchOptinsFn,
  changeOptin: changeOptinFn,
};

Optins.propTypes = {
  fetchOptins: PropTypes.func.isRequired,
  changeOptin: PropTypes.func.isRequired,
  club: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(Optins);
