import { PREV, NEXT, CANCEL } from './actions';

export default {
  offers: {
    on: {
      [CANCEL]: undefined,
      [PREV]: undefined,
      [NEXT]: [
        {
          target: 'options',
          cond: ctx => ctx.isOfferSelected,
        },
      ],
    },
    navStep: 0,
  },
  options: {
    on: {
      [CANCEL]: undefined,
      [PREV]: 'offers',
      [NEXT]: 'products',
    },
    navStep: 0,
  },
  products: {
    on: {
      [CANCEL]: undefined,
      [PREV]: 'options',
      [NEXT]: 'summary',
    },
    navStep: 0,
  },
  summary: {
    on: {
      [CANCEL]: undefined,
      [PREV]: 'products',
      [NEXT]: 'userInfos',
    },
    navStep: 0,
  },
  userInfos: {
    on: {
      [CANCEL]: undefined,
      [PREV]: 'summary',
      [NEXT]: [
        {
          target: 'optins',
          cond: ctx => ctx.isUserFormValid,
        },
      ],
    },
    navStep: 1,
  },
  optins: {
    on: {
      [CANCEL]: undefined,
      [PREV]: 'userInfos',
      [NEXT]: 'picture',
    },
    navStep: 1,
  },
  picture: {
    on: {
      [CANCEL]: undefined,
      [PREV]: 'optins',
      [NEXT]: [
        {
          target: 'mandateChoice',
          cond: ctx => ctx.isRecurrent,
        },
        {
          target: 'signContract',
          cond: ctx => ctx.hasSaleContract,
        },
        {
          target: 'payment',
        },
      ],
    },
    navStep: 1,
  },
  mandateChoice: {
    on: {
      [CANCEL]: undefined,
      [PREV]: 'picture',
      [NEXT]: [
        {
          target: 'bankDetails',
          cond: ctx => !ctx.skipMandate,
        },
        {
          target: 'signContract',
          cond: ctx => ctx.skipMandate,
        },
      ],
    },
    navStep: 2,
  },
  bankDetails: {
    on: {
      [CANCEL]: undefined,
      [PREV]: 'mandateChoice',
      [NEXT]: [
        {
          target: 'signContract',
          cond: ctx => ctx.hasSaleContract && ctx.isMandateFormValid,
        },
        {
          target: 'payment',
        },
      ],
    },
    navStep: 2,
  },
  signContract: {
    on: {
      [CANCEL]: undefined,
      [PREV]: [{ target: 'mandateChoice', cond: ctx => ctx.isRecurrent }, { target: 'picture' }],
      [NEXT]: [
        {
          target: 'payment',
          cond: ctx => ctx.isContractValid,
        },
      ],
    },
    navStep: 2,
  },
  payment: {
    on: {
      [CANCEL]: undefined,
      [PREV]: undefined,
      [NEXT]: 'confirm',
    },
    navStep: 2,
  },
  confirm: {
    on: {
      [CANCEL]: undefined,
      [PREV]: undefined,
      [NEXT]: undefined,
    },
    navStep: 2,
  },
};
