import { createReducer } from 'redux-starter-kit';
import sendHTTPRequest from './_sendHTTPRequest';
import { getResolvedUri } from '../selectors/config';

const initialState = {
  ready: true,
};

// ------------------------------------
// Actions
// ------------------------------------

export const prepare = () => ({
  type: 'app/prepare',
});

export const getClubList = () => async (dispatch, getState) => {
  dispatch({ type: 'app/FETCH_CLUB_LIST' });

  const response = await dispatch(
    sendHTTPRequest({
      url: getResolvedUri(getState(), { uri: '/:clientToken/clubs' }),
    })
  );

  dispatch({ type: 'app/FETCH_CLUB_LIST_SUCCESS', payload: response.data });
};

// ------------------------------------
// Handlers
// ------------------------------------

const handlePrepare = state => ({ ...state, ready: false });
const handleFetchSuccess = (state, action) => ({
  ...state,
  clubs: action.payload,
});

// ------------------------------------
// Reducer
// ------------------------------------

export default createReducer(initialState, {
  'app/prepare': handlePrepare,
  'app/FETCH_CLUB_LIST_SUCCESS': handleFetchSuccess,
});
