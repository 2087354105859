import * as React from 'react';
import { Grid, Button, Typography } from '@stadline/react-ui-components';
import { compose, withState, withHandlers } from 'recompose'; // eslint-disable-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import { AlertMessage } from '@app/components';
import PropTypes from 'prop-types';
import { setSkipMandate as setSkipMandateFn } from '@app/store/reducers/saleProcessUser';
import { gotoNextStep as gotoNextStepFn } from '@app/store/reducers/saleProcess';
import SaleLayout from '../../components/SaleLayout';
import ManateBypassModal from '../../components/ManateBypassModal';

const MandateChoice = ({ onModalOpen, isOpen, onSkipMandate, goToMandateForm }) => (
  <SaleLayout title="Tes informations personnelles" withNextButton={false}>
    <Grid container spacing={40}>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <AlertMessage
          message="Tu as choisi un abonnement à prélèvement mensuel. Un relevé d'identité bancaire (RIB/IBAN) te sera demandé pour valider ton inscription.
          Tu ne les as pas sous la main ?
          Aucun souci, tu pourras les apporter sous 7 jours à l'accueil de ton club pour finaliser ton inscription.
          "
        />
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          spacing={32}
          style={{ backgroundColor: '#fff', width: '100%', margin: 0, padding: '32px' }}
        >
          <Grid item xs={12}>
            <Typography variant="h3">Tu as séléctionné une offre avec prélèvement.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              onClick={goToMandateForm}
              color="primary"
              style={{ width: '525px' }}
            >
              Remplir mon mandat de prélèvement
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              onClick={onModalOpen}
              color="primary"
              style={{ width: '525px' }}
            >
              Je n&apos;ai pas mes informations bancaires sur moi
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} />
    </Grid>
    <ManateBypassModal isOpen={isOpen} onSkipMandate={onSkipMandate} />
  </SaleLayout>
);

MandateChoice.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSkipMandate: PropTypes.func.isRequired,
  goToMandateForm: PropTypes.func.isRequired,
};

const mapActionCreator = {
  setSkipMandate: setSkipMandateFn,
  gotoNextStep: gotoNextStepFn,
};

const onModalOpen = ({ setIsOpen }) => () => setIsOpen(true);

const goToMandateForm = ({ setSkipMandate, gotoNextStep }) => () => {
  setSkipMandate(false);
  gotoNextStep();
};

const onSkipMandate = ({ setSkipMandate, gotoNextStep }) => () => {
  setSkipMandate(true);
  gotoNextStep();
};

export default compose(
  connect(
    null,
    mapActionCreator
  ),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({ onModalOpen, onSkipMandate, goToMandateForm })
)(MandateChoice);
