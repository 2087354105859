import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Typography } from '@stadline/react-ui-components';

const ShoppingLineWrapper = styled(Grid)`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ShoppingBagLine = ({ title, price, items }) => (
  <ShoppingLineWrapper container item>
    {price ? (
      <>
        <Grid item xs={6}>
          <Typography>&middot; {title}</Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Typography>{price}</Typography>
        </Grid>
      </>
    ) : (
      <Grid item xs={12}>
        <Typography>&middot; {title}</Typography>
      </Grid>
    )}
    {items.map(item => (
      <Fragment key={item.title}>
        <Grid item xs={6}>
          <Typography>{item.title}</Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Typography>{item.infos}</Typography>
        </Grid>
      </Fragment>
    ))}
  </ShoppingLineWrapper>
);

ShoppingBagLine.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  price: PropTypes.string,
};

ShoppingBagLine.defaultProps = {
  items: [],
  title: '',
  price: '',
};

export default ShoppingBagLine;
